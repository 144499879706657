import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon
} from 'react-share';

const ShareModal = ({ toggle, modal, title, shareUrl }) => {

  return (
    <>
      <ModalBody className="text-center rounded-top pb-2">
        <div>
          <h1>Share this page</h1>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>

          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>

          <LinkedinShareButton url={shareUrl} title={title}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
        </div>
      </ModalBody>
    </>
  );
};

export default ShareModal;
