import { useState } from "react";
import cardImg from "../../assets/img/card-image.jpg";
import { BASE_URL } from "../../services/config";
import Thumbnails from "../../assets/img/Thumbnails.png";
import StayDetail from "../Stay/StayDetail";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Thumbnails01 from "../../assets/img/Thumbnails01.png";
import Thumbnails02 from "../../assets/img/Thumbnails02.png";
import Thumbnails03 from "../../assets/img/Thumbnails03.png";
import starRatings from "../../assets/img/star-ratings.png";
import shareIc from "../../assets/img/share-ic.png";
import datePng from "../../assets/img/date.png";
import likeIc from "../../assets/img/like-ic.png";
// import cardImg from '../../assets/img/card-image.jpg'
import clockImg from "../../assets/img/clock.png";
import hotTubImg from "../../assets/img/hot-tub.png";
import springIc from "../../assets/img/spring-ic.png";
import summerIc from "../../assets/img/summer-ic.png";
import flightIc from "../../assets/img/flight-ic.png";
import trainIc from "../../assets/img/train-ic.png";
import vehichalIc from "../../assets/img/vehichal-ic.png";
import forestImg from "../../assets/img/forest.png";
import treeIc from "../../assets/img/tree-ic.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Modal } from "reactstrap";
import BookingModal from "../Booking/BookingModal";
import ExperienceDetail from "../Booking/ExperienceDetail";
import ConveyenceDetails from "../Booking/ConveyenceDetails";

const PropertyDetailPage = ({
  selectedPropertyDetail,
  setShowPropertyDetail,
}) => {
  const [showStayDetail, setShowStayDetail] = useState(false);
  const [stayDetail, setStayDetail] = useState("");
  const [modal, setModal] = useState(false);
  const [bookingType, setBookingType] = useState('')
  const [showExperienceDetail, setShowExpericenceDetail] = useState(false)
  const [showProperty, setShowProperty] = useState(true)
  const [experienceData, setExperienceData] = useState('')
  const [showConveyence, setShowConveyence] = useState(false)
  const [conveyenceData, setConveyenceData] = useState('')

  const toggle = () => setModal(!modal);

  const onClickStay = (value) => {
    console.log("value", value);
    setShowStayDetail(true);
    setStayDetail(value);
    setShowProperty(false)
  };

  const onClickExperience = (value) => {
    console.log("onClickExperience", value);
    setExperienceData(value)
    setShowProperty(false)
    setShowExpericenceDetail(true)
  };

  const onClickConveyence = (value) => {
    console.log("onClickConveyence", value);
  
    setConveyenceData(value)
    setShowProperty(false)
    setShowConveyence(true)
  };
  const callBackProperty = () => {
    setShowPropertyDetail(false);
    setShowProperty(true)
  };

  const addAddons = (addonData) => {
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    console.log('fffdasfas', booking.addonBooking)
    if (existBooking) {
      if (booking.addonBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter(booking => booking._id === id);
        };
        const addonBookingIdToFind = addonData?._id;
        const foundAddonBooking = findAddonBookingById(booking?.addonBooking, addonBookingIdToFind);
        if (foundAddonBooking.length > 0) {
          console.log('found stay booking', booking)
          for (let i of booking.addonBooking) {
            if (i._id === addonBookingIdToFind) {
              console.log('iiiiidddddss', i)
              i.addonCount = foundAddonBooking.length + i.addonCount
            }
          }
        } else {
          console.log('no found stay booking', foundAddonBooking)
          let bookingData = {
            _id: addonData._id,

          }
          // stayDetail.stayCount = 1
          // booking.addonsBooking.push(addonData);
          booking.addonBooking = [addonData]
        }
        localStorage.setItem('stayBooking', JSON.stringify(booking));
      } else {
        console.log('not exist booking', booking);
        addonData.addonCount = 1
        let bookingData = {
          _id: addonData._id,

        }
        let obj = {
          addonBooking: [addonData],
          // stayCount: 1
        };
        booking.addonBooking = [addonData]
        localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }
  }
  return (
    <>
      {showProperty === true ? (
        <>
          {/* <div className="search-bar d-flex p-4"> 
              {selectedPropertyDetail?.propertyName}     
          </div>
          <div className="experiences p-4">
            <h3 className="mb-4">Stay</h3>
            <div className="experience-cards d-flex">
              {selectedPropertyDetail?.stayTypeDetail.map((item, index) => {
                return (
                  <>
                    <div
                      className="experience-card card shadow-sm"
                      key={index}
                    >
                      <img
                        src={cardImg}
                        className="card-img-top"
                        alt="Experience"
                      />
                      <div className="card-body" onClick={()=>onClickStay(item)}>
                        <h4 className="card-title">{item?.stayType}</h4>
                        <p className="card-text">₹ 2000 - 4000</p>
                        <p className="card-text">3 Nights 4 Days</p>
                        <p className="card-text">4.8 ★</p>
                        <button className="btn btn-warning">
                          Limited Seats
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>


          <div className="experiences p-4">
            <h3 className="mb-4">experiences</h3>
            <div className="experience-cards d-flex">
              {selectedPropertyDetail?.experience.map((item, index) => {
                return (
                  <>
                    <div
                      className="experience-card card shadow-sm"
                      key={index}
                    >
                      <img
                        src={cardImg}
                        className="card-img-top"
                        alt="Experience"
                      />
                      <div className="card-body">
                        <h4 className="card-title">{item?.experienceName}</h4>
                        <p className="card-text">₹ 2000 - 4000</p>
                        <p className="card-text">3 Nights 4 Days</p>
                        <p className="card-text">4.8 ★</p>
                        <button className="btn btn-warning">
                          Limited Seats
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>

          <div className="experiences p-4">
            <h3 className="mb-4">amenities</h3>
            <div className="d-flex justify-content-between">
              {selectedPropertyDetail?.amenities.map((item, index) => {
                return (
                <div key={index}><img src={`${BASE_URL}/${item.amenitiesImg}`} alt="" className="img-fluid" /> <span className=''> {item.amenitiesName}</span>{"  "}</div>
                );
              })}
            </div>
          </div>
          
          <div className="experiences p-4">
            <h3 className="mb-4">add on’s</h3>
            <div className="experience-cards d-flex">
              {selectedPropertyDetail?.addons.map((item, index) => {
                return (
                  <>
                    <div
                      className="experience-card card shadow-sm"
                      key={index}
                    >
                      <div className="card-body">
                        <h4 className="card-title">{item?.addonTypeName}</h4>
                        <p className="card-text">{item?.serviceName}</p>
                        <p className="card-text">{item?.regularPrice || 0}</p>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>  */}
          <div className="property-detaills-page">
            <section className="pd01">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="pd01_top_wrapper d-flex justify-content-start align-items-center gap-1">
                      <Swiper
                        className="mySwiper"
                        slidesPerView={3}
                        spaceBetween={10}
                      >
                        <SwiperSlide className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="pd01_top_thumbnail">
                            <img
                              src={Thumbnails}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </div>
                        </SwiperSlide>
                        <SwiperSlide>
                          <div className="pd01_top_thumbnail">
                            <img
                              src={Thumbnails}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </div>
                        </SwiperSlide>
                      </Swiper>
                    </div>

                    <div className="pd01_bottom_wrapper d-flex justify-content-start align-items-end gap-md-4 gap-2">
                      <div className="pd01_bottom_thumbnails01">
                        <div className="pd01_bottom_thumbnail01_inner">
                          <img
                            src={Thumbnails01}
                            alt=""
                            className="img-fluid"
                          />
                        </div>

                        <div className="pd01_bottom_thumbnail01_inner">
                          <img
                            src={Thumbnails02}
                            alt=""
                            className="img-fluid"
                          />
                        </div>

                        <div className="pd01_bottom_thumbnail01_inner">
                          <img
                            src={Thumbnails03}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>

                      <div className="pd-bottom_rtl flex-fill">
                        <div className="pd01_bottom_content">
                          <div className="breadcrumb_wrapper">
                            <nav
                              style={{ "--bs-breadcrumb-divider": "'>'" }}
                              ariaLabel="breadcrumb"
                            >
                              <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                  <a href="#">Camps in</a>
                                </li>
                                <li className="breadcrumb-item">
                                  <a href="#">State</a>
                                </li>
                                <li className="breadcrumb-item">
                                  <a href="#">District</a>
                                </li>
                                <li className="breadcrumb-item">
                                  <a href="#">Locality</a>
                                </li>
                              </ol>
                            </nav>
                          </div>

                          <div className="pd01_content">
                            <h4>Great himalayan four season adventure camp</h4>

                            <div className="d-flex justify-content-between align-items-center">
                              <div className="pd01_content_ltr d-flex justify-content-between align-items-center">
                                <div className="rating_txt d-flex justify-content-center align-items-center">
                                  4.8
                                </div>
                                <div className="ratings01">
                                  <img
                                    src={starRatings}
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <br />
                                  <span>36 reviews</span>
                                </div>
                                <img
                                  src="img/Badges.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </div>
                              <div className="pd01_content_rtl">
                                <button className="btn_cutom_atn border-0 bg-transparent">
                                  <img src={shareIc} alt="" />
                                  Share
                                </button>
                                <button className="btn_cutom_atn border-0 bg-transparent">
                                  <img src={likeIc} alt="" />
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pd02">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="title">happening experinces</div>
                    <div className="subtitle">
                      book upcoming events in Great Himalayan Outdoors - four
                      season camps
                    </div>
                  </div>
                  <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                    {/* <div className="col">
                      <div className="experience_card card-shadow">
                        <div className="experience_card_image position-relative ">
                          <img
                            src={cardImg}
                            className="img-fluid experienceImg01"
                            alt=""
                          />
                          <div className="experience_card_image_overlay">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="price_tag">₹ 2000 - 4000</div>
                              <div className="stay_duration">
                                <img src={clockImg} /> 3 Nights 4 Days
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="experience_card_content">
                          <div className="d-flex justify-content-between flex-1">
                            <h4>Hike to mulkarkha lake Via chandratal</h4>
                            <h5>
                              <img src={shareIc} /> 4.8
                            </h5>
                          </div>
                          <div className="dashedBorder" />
                          <div className="d-flex justify-content-between flex-1">
                            <div className="list-date">
                              <h6>
                                <img src={datePng} />
                                <span>Fri 8th Apr to Sun 10th Apr</span>
                              </h6>
                            </div>
                            <button className="btn btn-transperent">
                              limited seats
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col">
                      <div className="experience_card card-shadow">
                        <div className="experience_card_image position-relative ">
                          <img
                            src={cardImg}
                            className="img-fluid experienceImg01"
                            alt=""
                          />
                          <div className="experience_card_image_overlay">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="price_tag">₹ 2000 - 4000</div>
                              <div className="stay_duration">
                                <img src={clockImg} /> 3 Nights 4 Days
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="experience_card_content">
                          <div className="d-flex justify-content-between flex-1">
                            <h4>Hike to mulkarkha lake Via chandratal</h4>
                            <h5>
                              <img src={starRatings} /> 4.8
                            </h5>
                          </div>
                          <div className="dashedBorder" />
                          <div className="d-flex justify-content-between flex-1">
                            <div className="list-date">
                              <h6>
                                <img src={datePng} />
                                <span>Fri 8th Apr to Sun 10th Apr</span>
                              </h6>
                            </div>
                            <button className="btn btn-transperent">
                              limited seats
                            </button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                     {selectedPropertyDetail?.experience.map((item, index) => {
                    return (
                      <>
                      <div className="col" key={index} onClick={()=>onClickExperience(item)}>
                      <div className="experience_card card-shadow">
                        <div className="experience_card_image position-relative ">
                          <img
                            src={cardImg}
                            className="img-fluid experienceImg01"
                            alt=""
                          />
                          <div className="experience_card_image_overlay">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="price_tag">₹ 2000 - 4000</div>
                              <div className="stay_duration">
                                <img src={clockImg} /> 3 Nights 4 Days
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="experience_card_content">
                          <div className="d-flex justify-content-between flex-1">
                            <h4>{item?.experienceName}</h4>
                            <h5>
                              <img src={starRatings} /> 4.8
                            </h5>
                          </div>
                          <div className="dashedBorder" />
                          <div className="d-flex justify-content-between flex-1">
                            <div className="list-date">
                              <h6>
                                <img src={datePng} />
                                <span>Fri 8th Apr to Sun 10th Apr</span>
                              </h6>
                            </div>
                            <button className="btn btn-transperent">
                              limited seats
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                      </>
                    );
                  })}
                  </div>
                </div>
              </div>
            </section>

            <section className="pd03">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-md-3">
                    <div className="title">stays</div>
                    <div className="subtitle">
                      book your preferred accommodation at Great Himalayan
                      Outdoors - four season camps{" "}
                    </div>
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                  {selectedPropertyDetail?.stayTypeDetail.map((item, index) => {
                    return (
                      <>
                        <div className="col">
                          <div
                            className="stays_card_wrapper"
                            onClick={() => onClickStay(item)}
                          >
                            <div className="stays_card_image">
                              <img
                                src={forestImg}
                                alt=""
                                className="img-fluid w-100 br16"
                              />
                            </div>
                            <div className="stays_card_content">
                              <div className="scc_top d-flex justify-content-between align-items-center gap-lg-3">
                                <div className="scc_top_left">
                                  <img src={treeIc} alt="" />
                                </div>
                                <div className="scc_top_right">
                                  <h6>{item?.stayType}</h6>
                                  <p>
                                    2000 <span>/ night</span>
                                  </p>
                                </div>
                              </div>
                              <small>HURRY ! Only 2 available</small>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </section>

            <section className="pd04">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="pd04_left_content_wrapper">
                      <div className="pd04_left_content">
                        <h6>
                          <span className="fw-normal">about</span> Great
                          Himalayan Outdoors - four season camps
                        </h6>
                        <p>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam in venenatis ante. Morbi egestas nisl nec
                          scelerisque consequat. Curabitur eu tellus placerat,
                          maximus tortor at, vulputate magna. Duis tincidunt
                          diam erat, sed vulputate tortor vestibulum eget. Nunc
                          cursus leo nec odio hendrerit, ut mollis urna semper.{" "}
                        </p>
                        <div className="bd-dashed" />
                        <div className="icwtxt">
                          <img src={hotTubImg} alt="" /> Hot Tub
                        </div>
                        <div className="icwtxt">
                          <img src={hotTubImg} alt="" /> Hot Tub
                        </div>
                        <div className="icwtxt">
                          <img src={hotTubImg} alt="" /> Hot Tub
                        </div>
                        <div className="bd-dashed" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12">
                    <div className="pd04_right_content_wrapper">
                      <div className="pd04_right_content">
                        <h5>best time to visit</h5>
                        <div className="icwtxt">
                          <img src={springIc} alt="" /> Spring
                        </div>
                        <div className="icwtxt">
                          <img src={summerIc} alt="" /> Summer
                        </div>
                        <div className="icwtxt">
                          <img src={springIc} alt="" /> Spring
                        </div>
                      </div>
                      <div className="pd04_right_content">
                        <h5>distance from</h5>
                        <div className="icwtxt">
                          <img src={flightIc} alt="" /> Bagdogra 80 km
                        </div>
                        <div className="icwtxt">
                          <img src={trainIc} alt="" /> New Jalpaiguri 40 km
                        </div>
                        <div className="icwtxt">
                          <img src={vehichalIc} alt="" /> Siliguri 30 km
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="pd05">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 mb-md-3">
                    <div className="title">addon's</div>
                    <div className="subtitle">
                      book your preferred accommodation at Great Himalayan
                      Outdoors - four season camps{" "}
                    </div>
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                  {selectedPropertyDetail?.addons.map((item, index) => {
                    return (
                      <>
                        <div
                          className="experience-card card shadow-sm"
                          key={index}
                          onClick={() => addAddons(item)}
                        >
                          <div className="card-body">
                            <h4 className="card-title">
                              {item?.addonTypeName}
                            </h4>
                            <p className="card-text">{item?.serviceName}</p>
                            <p className="card-text">
                              {item?.regularPrice || 0}
                            </p>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </section>

            <section className="pd02">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="title">conveyance</div>
                    <div className="subtitle">
                     
                    </div>
                  </div>
                  <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                     {selectedPropertyDetail?.conveyance.map((item, index) => {
                    return (
                      <>
                      <div className="col" key={index}>
                      <div className="experience_card card-shadow">
                        <div className="experience_card_image position-relative ">
                          <img
                            src={cardImg}
                            className="img-fluid experienceImg01"
                            alt=""
                            // onClick={()=>{
                            //   setBookingType('conveyance')
                            //   setModal(true)
                            // }}
                            onClick={()=>onClickConveyence(item)}
                          />
                          <div className="experience_card_image_overlay">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="price_tag">₹ 2000 - 4000</div>
                              {/* <div className="stay_duration">
                                <img src={clockImg} /> 3 Nights 4 Days
                              </div> */}
                            </div>
                          </div>
                        </div>
                        <div className="experience_card_content">
                          <div className="d-flex justify-content-between flex-1">
                            <h4>{item?.conveyanceTypeName}</h4>
                            <h5>
                              <img src={starRatings} /> 4.8
                            </h5>
                          </div>
                          <div className="dashedBorder" />
              
                        </div>
                      </div>
                    </div>
                      </>
                    );
                  })}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </>
      ) : (
        <>
        {showStayDetail === true &&<StayDetail
          stayDetail={stayDetail}
          selectedPropertyDetail={selectedPropertyDetail}
          setShowStayDetail={setShowStayDetail}
          callBackProperty={callBackProperty}
          setShowProperty={setShowProperty}
          />}
          {showExperienceDetail === true && <ExperienceDetail    selectedPropertyDetail={selectedPropertyDetail} 
          
          experienceData={experienceData}
          callBackProperty={callBackProperty}
          setShowProperty={setShowProperty}
          />}

        {showConveyence === true &&
          <ConveyenceDetails 
          
          selectedPropertyDetail={selectedPropertyDetail} 
          conveyenceData={conveyenceData}
          callBackProperty={callBackProperty}
          setShowProperty={setShowProperty}
          />
          // <div>Balwan</div>
        }
        
        </>
      )}
     <Modal isOpen={modal} toggle={toggle} size="sm" centered={true}>
       <BookingModal toggle={toggle} bookingType={bookingType} />
     </Modal>

    </>
  );
};

export default PropertyDetailPage;
