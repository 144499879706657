// import logoImg from '../../../src/assets/img/logo.png'
import logoImg from "../../assets/img/logo.png";
import userProfile from "../../assets/img/user-profile.png";
import backArrow from "../../assets/img/back-arrow-ic.png";
import downloadIc from "../../assets/img/download-ic.png";
import shareIc from "../../assets/img/share-ic-dark.png";
import confIc from "../../assets/img/confirmation.png";
import digitalIc from "../../assets/img/digitalIc.png";
import propertyIc from "../../assets/img/location-map.png";
import calenderIc from "../../assets/img/calender-ic.png";
import guests from "../../assets/img/guests-ic.png";
import guest from "../../assets/img/guest-ic.png";
import creditCardIc from "../../assets/img/credit-card-ic.png";

import AppIcon1 from "../../assets/images/AppIcon1.png";
import AppIcon2 from "../../assets/images/AppIcon2.png";
import AppIcon3 from "../../assets/images/AppIcon3.png";
// import  '../../assets/css/styleBooking.css'
import authServices from "../../services/auth.services";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formattedDateDDMMMYYYY } from "../../helpers/Helper";

import ShareIcon from '@mui/icons-material/Share';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import EventIcon from '@mui/icons-material/Event';
import GroupIcon from '@mui/icons-material/Group';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ReportIcon from '@mui/icons-material/Report';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { generatePDF } from "../../helpers/generatePDF";

const BookingConfirmation = () => {
  const [booking, setBooking] = useState("");
  const [bookingTotalAmt, setBookingTotalAmt] = useState(0)
  const { id } = useParams();
  const navigate = useNavigate();
  const bookingDetail = () => {
    authServices
      .bookingDetailByIdApi({ id: id })
      .then((result) => {
        console.log("result", result.data.data);
        if (result.status === 200) {
          // navigate('/booking-successful')
          setBooking(result.data.data);
          let resultData = result.data.data
          if (resultData) {
            let totalPrice = 0
            if (resultData?.stayBooking) {
              if (resultData?.stayBooking.length > 0) {
                for (let item of resultData?.stayBooking) {

                  if (item.weekendDays > 0) {
                    let p = item.perPerson === true ? item.adults * item.priceWeekend * item.weekendDays : item.priceWeekend * item.stayCount * item.weekendDays
                    totalPrice = totalPrice + p
                  }
                  if (item.regularDays > 0) {
                    let p = item.perPerson === true ? item.adults * item.priceRegular * item.regularDays : item.priceRegular * item.stayCount * item.regularDays
                    totalPrice = totalPrice + p
                  }
                }
              }
            }
            if (resultData?.experienceBooking) {
              if (resultData?.experienceBooking.length > 0) {
                for (let item of resultData?.experienceBooking) {
                  if (item.isWeekendPrice === true) {
                    let p = item.perPerson === true ? (item.adults + item.children) * item.priceWeekend : item.priceWeekend * item.experienceCount
                    totalPrice = totalPrice + p
                  } else {
                    let p = item.perPerson === true ? (item.adults + item.children) * item.priceRegular : item.priceRegular * item.experienceCount
                    totalPrice = totalPrice + p
                  }
                }
              }
            }
            if (resultData?.eventBooking) {
              if (resultData?.eventBooking.length > 0) {
                for (let item of resultData?.eventBooking) {
                  if (item.isWeekendPrice === true) {
                    let p = item.perPerson === true ? (item.adults + item.children) * item.priceWeekend : item.priceWeekend * item.experienceCount
                    totalPrice = totalPrice + p
                  } else {
                    let p = item.perPerson === true ? (item.adults + item.children) * item.priceRegular : item.priceRegular * item.experienceCount
                    totalPrice = totalPrice + p
                  }
                }
              }
            }
            if (resultData?.addonBooking) {
              if (resultData?.addonBooking.length > 0) {
                for (let item of resultData?.addonBooking) {
                  totalPrice = totalPrice + item.price * item.addonCount
                }
              }
            }
            console.log('totallllll', totalPrice)
            setBookingTotalAmt(totalPrice)
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    // localStorage.removeItem('propertyDetail')
    // localStorage.removeItem('experienceDetail')
    // localStorage.removeItem('conveyenceDetail')
    // localStorage.removeItem('stayBooking')
    // localStorage.removeItem('stayDetail')
    // localStorage.removeItem('stayBooking')
    bookingDetail();

  }, []);
  return (
    <>
      <main className="booking-confirmation-page">
        <section className="bcp02">
          <div className="container">
            <div className="bsl-top mt-5 mb-1">
              <div className="d-flex justify-content-between align-items-center flex-lg-nowrap flex-wrap">
                <button className="btn btn-back" onClick={() => navigate('/my-account')}>
                  {" "}
                  <img src={backArrow} /> Back to Trip
                </button>

                <div className="bcp02_rtl">
                  <button className="btn btn-dark btn-sm me-4" onClick={() => generatePDF("cardPDF")}>
                    {" "}
                    <FileDownloadOutlinedIcon /> Download
                  </button>
                  <button className="btn btn-outline-dark btn-sm">
                    {" "}
                    <ShareIcon /> Share
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bcp02" id="cardPDF">
          <div className="container">
            <div className="booking-details-body card p-3 pt-4 p-lg-5 br16">
              <div className="bdd-conf">
                <div className="row">
                  <div className="col-md-8">
                    <div className="bdd-conf_lft">
                      <div className="d-flex align-items-start gap-3">
                        <div className="bdd-conf_lft_img">
                          {booking.status === 0 && <ReportIcon className="text-warning" />}
                          {booking.status === 2 || booking.status === 4 || booking.status === 5 || booking.status === 6 ? <VerifiedIcon /> : ''}
                          {booking.status === 1 && <VerifiedIcon className="text-info" />}
                          {booking.status === 3 && <DoNotDisturbIcon className="text-danger" />}
                        </div>
                        <div className="bdd-conf_lft_txt">
                          {/* <h3 className="text-success">Confirmed</h3> */}
                          {booking.status === 0 &&
                            <h3 class="align-self-center text-warning">
                              Pending with host!
                            </h3>}
                          {booking.status === 2 || booking.status === 4 || booking.status === 5 || booking.status === 6 ? <h3 className="align-self-center text-success">
                            Confirmed
                          </h3> : ''
                          }

                          {booking.status === 1 && <h3 className="align-self-center text-info">
                            Partially confirmed
                          </h3>
                          }
                          {booking.status === 3 && <h3 className="align-self-center text-danger">
                            Rejected
                          </h3>
                          }
                          <p>
                            Memories made together last a lifetime. Get ready to{" "}
                            Explore &{" "}
                            experience the{" "}
                            <span className="text-uppercase">beautiful outdoors.</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="bdd-conf_rgt">
                      <div className="bdd-conf_rgt_card">
                        <p>Booking reference</p>
                        <h6>{booking._id}</h6>
                      </div>
                      <div className="bookingdate">
                        date of booking :{" "}
                        {formattedDateDDMMMYYYY(booking.createdAt)}{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="bdd-digital">
                <div className="d-md-flex d-block align-items-center gap-lg-4 gap-3">
                  <div className="bdd-digital_img">
                    <img src={digitalIc} />
                  </div>
                  <p>
                    We strongly recommend that you bring this booking
                    confirmation with you as a digital document when checking in
                    at the site. You can also print and submit a copy. All adult
                    campers must bring any of ID (AADHAAR, VOTER ID, PAN,
                    DRIVING LICENSE) and present at check-in.
                  </p>
                </div>
              </div>

              <hr />

              <div className="bdd-property row">

                <div className="col-lg-6 col-md-6 col-12">
                  <div className="bdd-property_lft pe-md-5 pe-0">
                    <h6>You are visiting</h6>
                    <h3> {booking?.visitingPlace}</h3>
                    <p>
                      {booking?.visitingPlaceAddress}
                    </p>
                    <h6 className="mt-lg-5 mt-4">Contact Person</h6>
                    <div className="d-flex align-content-center gap-4">
                      <p className="fw-bold m-0">{booking?.hostDetail?.name}</p>
                      <p>{booking?.hostDetail?.mobileNo}</p>
                    </div>

                  </div>
                </div>

                <div className="col-lg-6 col-md-6 col-12 text-md-end">
                  <div className="bdd-property_rgt">
                    <iframe frameborder="0" width="100%" height="100%" src={`https://www.google.com/maps/embed/v1/place?q=${booking?.lat},${booking?.long}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}></iframe>
                  </div>
                </div>

              </div>

              <hr />

              <div class="container">
                <div class="row">
                  <div class="col-lg-6 col-12">
                    <div class="ico-label mb-4 mt-4 text-gray">
                      <AccountBoxOutlinedIcon />
                      primary guest
                    </div>
                    <div class="d-flex flex-wrap align-items-center gap-4 userDet">
                      <h5>{booking?.primaryguestName}</h5>
                      <h6>{booking?.primaryguestPhone}</h6>
                      <h6>{booking?.primaryguestEmail}</h6>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="property-card-wrapper">
                <div className="container">
                  <div className="row">
                    {booking?.stayBooking && booking?.stayBooking.length > 0 && <div className="col-lg-3 col-md-6 col-12">
                      <h6>stays</h6>
                      <div className="stay-list">
                        {booking?.stayBooking && booking?.stayBooking.map((item, index) => {
                          return (
                            <div className="stay-list-item shadow-none mb-2" key={index}>
                              <div className="d-flex">
                                <img className="stay-list-item-img" src="https://picsum.photos/1024" />
                                <div className="stay-list-item-details">
                                  <small>{item?.stayType}</small>
                                  <h6 className="mt-1 mb-1">{item?.stayType}</h6>
                                  <small>{item?.adults} person | {formattedDateDDMMMYYYY(item?.checkInDate)} - {formattedDateDDMMMYYYY(item?.checkOutDate)}</small>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>}
                    {booking?.eventBooking && booking?.eventBooking.length > 0 && <div className="col-lg-3 col-md-6 col-12">
                      <h6>events</h6>
                      <div className="stay-list">
                        {booking?.eventBooking && booking?.eventBooking.map((item, index) => {
                          return (
                            <div className="stay-list-item mb-2" key={index}>
                              <div className="d-flex">
                                <img className="stay-list-item-img" src="https://picsum.photos/1024" />
                                <div className="stay-list-item-details">
                                  <small>{formattedDateDDMMMYYYY(item?.checkInDate)} </small>
                                  <h6 className="mt-1 mb-1">{item?.experienceName}</h6>
                                  <small>{item?.adults + item?.children} person</small>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>}
                    {booking?.experienceBooking && booking?.experienceBooking.length > 0 && <div className="col-lg-3 col-md-6 col-12">
                      <h6>experience</h6>
                      <div className="stay-list">
                        {booking?.experienceBooking && booking?.experienceBooking.map((item, index) => {
                          return (
                            <div className="stay-list-item mb-2" key={index}>
                              <div className="d-flex">
                                <img className="stay-list-item-img" src="https://picsum.photos/1024" />
                                <div className="stay-list-item-details">
                                  <small>{formattedDateDDMMMYYYY(item?.checkInDate)} </small>
                                  <h6 className="mt-1 mb-1">{item?.experienceName}</h6>
                                  <small>{item?.adults + item?.children} person</small>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>}
                    {booking?.addonBooking && booking?.addonBooking.length > 0 && <div className="col-lg-3 col-md-6 col-12">
                      <h6>Add ons</h6>
                      <div className="stay-list">
                        {booking?.addonBooking && booking?.addonBooking.map((item, index) => {
                          return (
                            <div className="stay-list-item shadow-none mb-2" key={index}>
                              <div className="stay-list-item-left d-flex">
                                <img className="stay-list-item-img" src="https://picsum.photos/1024" />
                                <div className="stay-list-item-details">
                                  <small>Addon</small>
                                  <h6 className="mt-1 mb-1">{item?.serviceName}</h6>
                                </div>
                              </div>
                            </div>
                          )
                        })}

                      </div>
                    </div>}
                  </div>
                </div>
              </div>

              <hr />

              <div className="alert alert-secondary d-flex justify-content-between mb-0" role="alert">
                <div><span><CreditCardIcon /></span> <span> Payment details</span></div> <span className="text-right">Transaction ID : 12345n435n435nj345nj</span>
              </div>

              <div className="property-price-lists">
                {booking?.stayBooking
                  ? booking?.stayBooking.map((item, index) => {
                    return (
                      <>
                        {item.regularDays > 0 && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {item.perPerson === true
                                    ? item.adults
                                    : item.stayCount}
                                  {item.perPerson === true ? "P" : "S"} x{" "}
                                  {item?.stayType} @{item?.priceRegular}/-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Stay"}{" "}
                                  x {item.regularDays}d
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)}  - {formattedDateDDMMMYYYY(item?.checkOutDate)}  | {item?.adults}P |{" "}
                                  {item.regularDays} Regular D
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? Number(item.adults) *
                                  item.priceRegular *
                                  item.regularDays
                                  : Number(item?.priceRegular) *
                                  Number(item?.stayCount) *
                                  item.regularDays}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.weekendDays > 0 && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {item.perPerson === true
                                    ? item.adults
                                    : item.stayCount}
                                  {item.perPerson === true ? "P" : "S"} x{" "}
                                  {item?.stayType} @{item?.priceWeekend}/-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Stay"}{" "}
                                  x {item.weekendDays}d
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)} - {formattedDateDDMMMYYYY(item?.checkOutDate)} | {item?.adults}P |{" "}
                                  {item.weekendDays} Weekend D
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? Number(item.adults) *
                                  item.priceWeekend *
                                  item.weekendDays
                                  : Number(item?.priceWeekend) *
                                  Number(item?.stayCount) *
                                  item.weekendDays}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                  : ""}
                {booking?.experienceBooking
                  ? booking?.experienceBooking.map((item, index) => {
                    return (
                      <>
                        {item.isWeekendPrice === false && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {" "}
                                  {item.perPerson === true
                                    ? item?.adults + item.children
                                    : item.experienceCount}
                                  {item.perPerson === true ? "P" : "Exp"} x{" "}
                                  {item?.experienceName} @{item?.priceRegular}
                                  /-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Exp"}
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)} |{" "}
                                  {item?.adults + item.children}P | Regular
                                  Price{ }
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? (item.adults + item.children) *
                                  item.priceRegular
                                  : Number(item?.priceRegular) *
                                  Number(item?.experienceCount)}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.isWeekendPrice === true && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {item.perPerson === true
                                    ? item?.adults + item.children
                                    : item.stayCexperienceType}
                                  {item.perPerson === true ? "P" : "Exp"} x {" "}
                                  {item?.experienceName} @{item?.priceWeekend}
                                  /-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Exp"}{" "}
                                  x {item.weekendDays}d
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)} |{" "}
                                  {item?.adults + item.children}P | Weekend
                                  Price
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? (item.adults + item.children) *
                                  item.priceWeekend
                                  : Number(item?.priceWeekend) *
                                  Number(item?.experienceCount)}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                  : ""}
                {booking?.eventBooking
                  ? booking?.eventBooking.map((item, index) => {
                    return (
                      <>
                        {item.isWeekendPrice === false && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {item.perPerson === true
                                    ? item?.adults + item.children
                                    : item.experienceCount}
                                  {item.perPerson === true ? "P" : "Event"} x{" "}
                                  {item?.experienceName} @
                                  {item?.priceRegular}/-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Event"}
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)} |{" "}
                                  {item?.adults + item.children}P | Regular
                                  Price{ }
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? (item.adults + item.children) *
                                  item.priceRegular
                                  : Number(item?.priceRegular) *
                                  Number(item?.experienceCount)}
                              </div>
                            </div>
                          </div>
                        )}
                        {item.isWeekendPrice === true && (
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>
                                  {item.perPerson === true
                                    ? item?.adults + item.children
                                    : item.experienceCount}
                                  {item.perPerson === true ? "P" : "Exp"} x{" "}
                                  {item?.experienceType} @{item?.priceWeekend}
                                  /-{" "}
                                  {item.perPerson === true
                                    ? "Per Person"
                                    : "Per Exp"}{" "}
                                  x {item.weekendDays}d
                                </h6>
                                <div className="ppl_info">
                                  {formattedDateDDMMMYYYY(item?.checkInDate)} |{" "}
                                  {item?.adults + item.children}P | Weekend
                                  Price
                                </div>
                              </div>

                              <div className="ppl-center">
                                {item.perPerson === true
                                  ? (item.adults + item.children) *
                                  item.priceWeekend
                                  : Number(item?.priceWeekend) *
                                  Number(item?.experienceCount)}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  })
                  : ""}


                {booking?.addonBooking ? booking?.addonBooking.map((item, index) => {
                  return (
                    <>
                      <div className="ppl_box">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="ppl-left">
                            <h6>{item.addonCount} x Addon@{item.price}/-</h6>
                            <div className="ppl_info">{formattedDateDDMMMYYYY(item?.checkInDate)}  | {item.serviceName}</div>
                          </div>

                          <div className="ppl-center">{item.isWeekendPrice === true ? item.price * item.addonCount : item.price * item.addonCount}</div>

                        </div>
                      </div>
                    </>
                  )
                }) : ''}

                <div className="ppl_box">
                  <div className="totalAmt d-flex justify-content-between align-items-center">
                    <div className="ppl-left">
                      <h6 className="fw-bold text-uppercase">Total Paid Amount</h6>
                    </div>
                    <div className="ppl-center">{booking.totalPaidAmount}</div>
                  </div>
                </div>

                <div className="ppl_box bg-danger px-3">
                  <div className="payableAmt d-flex justify-content-between align-items-center">
                    <div className="ppl-left">
                      <h6 className="fw-fw-semibold text-white">Amount Payable</h6>
                    </div>
                    <div className="ppl-center text-white">{bookingTotalAmt - booking.totalPaidAmount}</div>
                  </div>
                </div>
              </div>
                <hr />
              <div className="camp-rules row py-5">
                <div className="col-md-6 col-12">
                  <div className="camp-rules-card">
                    <h5>Camp Rules</h5>

                    <div className="camp-rules-card-inner border p-md-4 p-3">
                      <ul>
                        {booking?.campRules && booking?.campRules?.rule.map((i, index) => {
                          return (
                            <li key={index}>
                              {i}
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-12">
                  <div className="camp-rules-card mt-lg-0 mt-4">
                    <h5>Cancellation Policy</h5>

                    <div className="camp-rules-card-inner border p-md-4 p-3">
                      <ul>
                        {booking?.cancellationPolicy && booking?.cancellationPolicy?.policy.map((i, index) => {
                          return (
                            <li key={index}>
                              {i}
                            </li>
                          )
                        })}

                      </ul>
                    </div>
                  </div>
                </div>


              </div>

              <hr className="my-5" />

              <div className="Initiatives_furut_camps br16 bg-white border">
                <div className="lgTitle">
                  <h2 className="mb-3">Initiatives @ furut camps</h2>
                  <p>
                    Spending time in natural surroundings is more vital than
                    ever. And in these uncertain times, everyone of us, from
                    seasoned outdoor enthusiasts to families headed out to their
                    first camping, might use some pointers on how to be safe.
                    These suggestions provide a starting point for getting
                    outside in order to preserve your health and access to our
                    treasured natural places.
                  </p>
                </div>

                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mt-lg-4 mt-3">
                  <div className="col">
                    <div className="card_content br16 bg-white">
                      <img
                        src={AppIcon1}
                        alt=""
                        className="img-fluid card_content_ic"
                      />
                      <h4>Go hyper-local</h4>

                      <ol className="numberList ps-3">
                        <li>Respect local customs and tradition</li>
                        <li>Venture beyond tourist attractions</li>
                        <li>Try local foods and drink</li>
                        <li>Don't rely on stereotypes</li>
                        <li>Learn the language if possible</li>
                        <li>Explore the landscape</li>
                      </ol>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card_content br16 bg-white">
                      <img
                        src={AppIcon2}
                        alt=""
                        className="img-fluid card_content_ic"
                      />
                      <h4>Sustainably restore</h4>

                      <ol className="numberList ps-3">
                        <li>Build an inclusive outdoor.</li>
                        <li>Plan ahead.</li>
                        <li>Know before you go.</li>
                        <li>Play safe.</li>
                        <li>Maintain physical distancing.</li>
                        <li>leave no trace.</li>
                        <li>Respect nature and people.</li>
                        <li>Explore locally.</li>
                      </ol>
                    </div>
                  </div>

                  <div className="col">
                    <div className="card_content br16 bg-white">
                      <img
                        src={AppIcon3}
                        alt=""
                        className="img-fluid card_content_ic"
                      />
                      <h4>Leave only thanks take only memories</h4>

                      <ol className="numberList ps-3">
                        <li>Plan ahead and prepare.</li>
                        <li>Minimise & dispose your waste responsibly.</li>
                        <li>Avoid damaging live trees, plants, natural objects and cultural artefacts.</li>
                        <li>Respect wildlife.</li>
                        <li>Avoid alternating sites.</li>
                        <li>Be considerate of other visitors.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default BookingConfirmation;
