import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'; // If you need additional custom styles
import AppRoute from './routes/AppRoute';
import '../src/assets/css/style.css'

function App() {
  return (
    <div className="App">
    <AppRoute />
  </div>
  );
}

export default App;
