import { ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import eastImg from '../../assets/img/east1.png'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from "react";
import authServices from "../../services/auth.services";
import { useNavigate } from "react-router-dom";
import backIc from '../../assets/img/back-arrow-ic.png'

import eastMap from '../../assets/img/east.svg'
import westMap from '../../assets/img/west.svg'
import centerMap from '../../assets/img/center.svg'
import northeastMap from '../../assets/img/northeast.svg'
import southMap from '../../assets/img/south.svg'

const options = [
    { label: 'Apple', id: 1 },
    { label: 'Banana', id: 2 },
    { label: 'Cherry', id: 3 },
    { label: 'Date', id: 4 },
    { label: 'Elderberry', id: 5 }
];
const SearchModal = ({ toggle, setSearchResult, setIsSearch }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [selectedBadge, setSelectedBadge] = useState('')
    const [selectLoc, setSelectLoc] = useState('')
    const [propertyList, setPropertyList] = useState([])
    const [terrianListData, setTerrianListData] = useState([])
    const [searchType, setSearchType] = useState(0)   //// 1 = property, 2 = terrian, 3 = location
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            mobileNo: "",
        },

        onSubmit: async (values) => {
            const reqBody = {
                mobileNo: values.mobileNo,
            };
        },
    });

    const onClickSelect = (value) => {
        console.log("selected value", value)
        setSelectedBadge('')
        setSelectLoc('')
        setSearchType(1)
    }
    const onSelectBadge = (value) => {
        console.log("selected badge", value)
        setSelectedBadge(value)
        setValue(null)
        setInputValue('')
        setSelectLoc('')
        setSearchType(2)
    }

    const onSelectLoc = (value) => {
        console.log("selected location", value)
        setSelectLoc(value)
        setSelectedBadge('')
        setValue(null)
        setInputValue('')
        setSearchType(3)
    }


    const propertiesList = async () => {
        const result = await authServices.propertiesList()
        if (result.status === 200) {
            let resData = result.data.data
            for (let item of resData) {
                item['label'] = item.propertyFullLocation
            }
            setPropertyList(resData)

        }
    }

    const terrianList = async () => {
        const result = await authServices.terrianList()
        if (result.status === 200) {
            let resData = result.data.data

            setTerrianListData(resData)
        }
    }

    const onClickSearch = () => {
        console.log("search", value)
        const reqBody = {
            terrin: selectedBadge,
            property: inputValue,
            location: selectLoc,
        }
        console.log("reqBody", reqBody)
        authServices.searchPropertyApi(reqBody).then((res) => {
            console.log("res", res)
            setSearchResult(res.data.data)
            localStorage.setItem('propertySearchResult', JSON.stringify(res.data.data))
            setIsSearch(true)
            navigate(`/property-search?type=${searchType}&search=${searchType === 1 && inputValue || searchType === 2 && selectedBadge || searchType === 3 && selectLoc}`)
            toggle()
        }).catch((error) => {
            console.log("error", error)
        })
    }
    useEffect(() => {
        propertiesList();
        terrianList();
    }, [])
    return (
        <>
            <div className="container">
                <ModalHeader className="justify-content-end border-0">
                    <button className="border-0 bg-white" onClick={() => { setIsSearch(false); toggle() }}>X</button>

                </ModalHeader>
                <ModalBody className="search-modal pt-md-5 pt-3">
                    <div className="row g-3" >
                        <form className="">
                            <div className="col-12 mb-3">
                                    <h4>where do you want to be? <span className="h5 ms-auto d-inline-block">[kalimpong]</span></h4>
                                    {/* <h5>[kalimpong]</h5> */}
                                    
                                {/* <label htmlFor="user_type_id" className="form-label mb-0">
                            where do you want to be?
                        </label> */}
                            </div>

                            {/* <div className="cal-12 mb-3 position-relative">
                        <label htmlFor="name" className="formLabel">
                            search places
                        </label>
                        <input type="text" className="form-control" id="search"
                        />
                    </div> */}
                            <div className="cal-12 mb-3 position-relative">

                                <Autocomplete className="search_input"
                                    value={inputValue}
                                    onChange={(event, newValue) => {
                                        setSelectedBadge('')
                                        setValue(newValue);
                                    }}
                                    inputValue={inputValue}
                                    onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                        onClickSelect(newInputValue)
                                    }}
                                    options={propertyList}
                                    renderInput={(params) => (
                                        <TextField {...params} label="search places" variant="outlined" className="formLabel" />
                                    )}
                                />
                            </div>

                            <div className="d-flex mt-4 mb-4"><small>or</small> <hr className="dotted" /></div>

                            <div className="d-flex justify-content-around flex-wrap modalMap">

                                <div className="mb-3">
                                    <div className="cstm_select text-center" onClick={() => onSelectLoc('east')}>
                                        <img src={eastMap} alt="" />
                                        <h6>East India</h6>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="cstm_select text-center" onClick={() => onSelectLoc('west')}>
                                        <img src={westMap} alt="" />
                                        <h6>West India</h6>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="cstm_select text-center" onClick={() => onSelectLoc('central')}>
                                        <img src={centerMap} alt="" />
                                        <h6>Central India</h6>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="cstm_select text-center" onClick={() => onSelectLoc('north')}>
                                        <img src={northeastMap} alt="" />
                                        <h6>NorthEast India</h6>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="cstm_select text-center" onClick={() => onSelectLoc('north east')}>
                                        <img src={southMap} alt="" />
                                        <h6>South India</h6>
                                    </div>
                                </div>

                            </div>

                            <div className="d-flex mt-4 mb-4"><small>or</small> <hr className="dotted" /></div>

                            <div className="cal-12   mb-2">
                                {terrianListData.map((item) => {
                                    return (
                                        <> <span className={`badge ${selectedBadge === item.terrainName ? 'active' : ''} pointer`} onClick={() => onSelectBadge(item.terrainName)}><span>+</span> {item.terrainName}</span>{" "}</>
                                    );
                                })}
                                {/* <span className={`badge ${selectedBadge ==='test'?'bg-danger':'bg-light text-dark'} pointer`} onClick={()=>onSelectBadge('test')}>+ test</span>{" "}
                        <span className={`badge ${selectedBadge ==='due'?'bg-danger':'bg-light text-dark'} pointer`} onClick={()=>onSelectBadge('due')}>+ due</span> */}
                            </div>

                        </form>

                    </div>
                </ModalBody>
                <ModalFooter className="border-0">
                    <div className="d-flex align-items-center justify-content-between w-100">

                        {/* <button
                    type="button"
                    className="btn btn-transperent border-0"
                    onClick={() => {
                        setIsSearch(false);
                        toggle()
                    }}
                >
                    <img src={backIc} className="me-2" />
                    CANCEL
                </button>
                <button type="submit" className="btn submit-btn" onClick={onClickSearch}>SEARCH</button> */}
                        <button class="btn btn-outline-dark mt-2" onClick={() => { setIsSearch(false); toggle() }}>Back</button>
                        <button class="btn btn-danger mt-2" onClick={onClickSearch}>SEARCH</button>
                    </div>
                </ModalFooter>
            </div>

        </>
    )
}

export default SearchModal;