import HappeningExperiences from '../components/Experience/HappeningExperiences';
import Features from '../components/Comman/Features';
import HeroSec from '../components/Comman/HeroSection';



const Event = () => {
  return (
    <>
      <HeroSec />

      <HappeningExperiences data={'EVENT'}/>
      {/* <ExperiencesData /> */}
      {/* <Features /> */}
    </>
  )
}

export default Event