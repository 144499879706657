import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../services/config';

function Chat({ currentUser, receiverId }) {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.post(`${BASE_URL}/user/get-messages`, {
            "senderId": currentUser._id,
            "receiverId":receiverId
          });
        setMessages(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (receiverId) {
      fetchMessages();
    }
  }, [currentUser, receiverId]);
// }, []);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${BASE_URL}/user/messages`, {
        senderId: currentUser._id,
        receiverId,
        message,
      });
      setMessage('');
    const response = await axios.post(`${BASE_URL}/user/get-messages`, {
        "senderId": currentUser._id,
        "receiverId":receiverId
      });
      setMessages(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        {/* <h4 className="card-title">Chat</h4> */}
        <ul className="list-group mt-4">
          {messages.map((msg) => (
            <li key={msg._id} className="list-group-item">
              {/* <strong>{msg.sender.username}:</strong> {msg.message} */}
              <strong>{msg.sender.name}:</strong> {msg.message}
            </li>
          ))}
        </ul>
        <form onSubmit={handleSendMessage}>
          {/* <div className="mb-3">
            <label className="form-label">Receiver ID</label>
            <input
              type="text"
              className="form-control"
              value={receiverId}
              onChange={(e) => setReceiverId(e.target.value)}
              required
            />
          </div> */}
          <div className="mb-3">
            <label className="form-label">Message</label>
            <textarea
              className="form-control"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Send
          </button>
        </form>
  
      </div>
    </div>
  );
}

export default Chat;
