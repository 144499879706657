import {API, BASE_URL} from './config';

const authServices = {
    loginApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/user-login-by-mobile`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    resendOTPApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/resent-opt`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    
    userRegisterApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/user-register`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    otpVerifyApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/otp-verify`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    propertiesList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/list`);
            console.log('res', response)
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    terrianList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/property/terrian-list`);
            console.log('res', response)
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    searchPropertyApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/property/search-property`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    bookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },


    userLoginByEmailApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/user-login-by-email`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    bookingDetailByIdApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/trip-booking-list-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    createTransaction: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/create-transaction`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    experienceList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/list`);
            console.log('res', response)
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    experienceDetailById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/experience/exp-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    addExperienceBookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-experience-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    addTripBookingInCartApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-booking-in-cart`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    addExperienceBookingInTemporaryApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-experience-booking-in-temporary`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    getCartTripBookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-trip-by-userid`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    getAllCartTripBookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-trip-list-by-userid`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    checkExperienceAvalablity: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/check-exp-avalablity`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    eventList: async ()=>{
        try {
            const response = await API.get(`${BASE_URL}/experience/event-list`);
            console.log('res', response)
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    eventDetailById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/experience/event-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    checkStayAvalablity: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/check-stay-avalablity`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    checkEventAvailablity: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/check-event-availablity`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    addEventBookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-event-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    propertyDetailByIdApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/property/property-detail-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    stayDetailByIdApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/property/stay-detail-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    temporaryTripBookingByUserIdAndPropertyId: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-trip-by-userid-hostid`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    getTemporaryExperienceBookingByUserIdAndHostID: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-experience-temporary-booking-by-user-host`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    temporaryTripBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/temporary-booking-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    deleteTemporaryTripBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-temporary-trip-booking-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    temporaryExperienceBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-experience-booking-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    deleteExperienceTemporaryBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-experience-temporary-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    addEventBookingInTemporary: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/add-event-bookin-in-temporary`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    getTemporaryEventBookingByUserIdAndHostID: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-event-temporary-booking-by-user-host`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    getTemporaryEventBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-event-booking-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    deleteEventTemporaryBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-event-temporary-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    getAllTemporaryExperienceBookingApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/get-temporary-experience-booking-list-by-user`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    propertyListApi: async (reqBody)=>{
        try { 
            const response = await API.get(`${BASE_URL}/property/list`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    upComingBookingByUserID: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/upcoming-trip-booking-by-user`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    pastTripByUserID: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/past-trip-by-user`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    terrianListApi: async (reqBody)=>{
        try { 
            const response = await API.get(`${BASE_URL}/property/terrian-list`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    deleteTemporaryBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-temporary-trip-booking-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    deleteTemporaryExperienceBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-experience-temporary-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    deleteTemporaryEventBookingById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/delete-event-temporary-booking`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    eventTemporaryBookingByUserId: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/booking/event-temporary-booking-list-by-user`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    uniqueStayListApi: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/property/unique-stay-list`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    nearByListApi: async (reqBody)=>{
        try { 
            const response = await API.get(`${BASE_URL}/property/nearby-list`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    profileDetailById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/profile-detail-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    updateProfileDetailById: async (reqBody)=>{
        try { 
            const response = await API.post(`${BASE_URL}/auth/update-profile-detail`,reqBody);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },


    updateProfilePhoto: async(reqBody)=>{
        try {
            console.log('reqBody', reqBody)
            const response = await API.post(`${BASE_URL}/auth/update-profile-photo`,reqBody, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              });
            return response;
        } catch (error) {
            console.log("error", error)
            return error.response
        }
    },

    addGuestUser: async(reqBody)=>{
        try {
            console.log('reqBody', reqBody)
            const response = await API.post(`${BASE_URL}/auth/add-guest-user`,reqBody);
            return response;
        } catch (error) {
            console.log("error", error)
            return error.response
        }
    },

    propertyByTerrianId: async(reqBody)=>{
        try {
            console.log('reqBody', reqBody)
            const response = await API.post(`${BASE_URL}/property/property-list-by-terrian`,reqBody);
            return response;
        } catch (error) {
            console.log("error", error)
            return error.response
        }
    },

    stayTypesList: async (reqBody)=>{
        try { 
            const response = await API.get(`${BASE_URL}/property/stay-type-list`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
    eventListForHomePage: async ()=>{
        try { 
            const response = await API.get(`${BASE_URL}/experience/event-list-for-home-page`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },

    expListForHomePage: async ()=>{
        try { 
            const response = await API.get(`${BASE_URL}/experience/list-for-home-page`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    guestDetailByTripId: async(reqBody)=>{
        try {
            console.log('reqBody', reqBody)
            const response = await API.post(`${BASE_URL}/auth/guest-user-by-tripbooking`,reqBody);
            return response;
        } catch (error) {
            console.log("error", error)
            return error.response
        }
    },
    deleteGuestByID: async(reqBody)=>{
        try {
            console.log('reqBody', reqBody)
            const response = await API.post(`${BASE_URL}/auth/delete-guest-by-id`,reqBody);
            return response;
        } catch (error) {
            console.log("error", error)
            return error.response
        }
    },
    
    expTypeList: async ()=>{
        try { 
            const response = await API.get(`${BASE_URL}/experience/experience-type-list`);
            return response;
        } catch (error) {
            console.log('error', error)
            return error.response;
        }
    },
    
   
}
export default authServices