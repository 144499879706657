
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Thumbnails from "../../assets/img/Thumbnails.png";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Thumbnails01 from "../../assets/img/Thumbnails01.png";
import Thumbnails02 from "../../assets/img/Thumbnails02.png";
import Thumbnails03 from "../../assets/img/Thumbnails03.png";
import starRatings from "../../assets/img/star-ratings.png";
import shareIc from "../../assets/img/share-ic.png";
import likeIc from "../../assets/img/like-ic.png";
import highlights01 from "../../assets/img/highlights01.png"
import highlights02 from "../../assets/img/highlights02.png"
import highlights03 from "../../assets/img/highlights03.png"
import highlights04 from "../../assets/img/highlights04.png"
import highlights05 from "../../assets/img/highlights05.png"
import pointsIc from "../../assets/img/iCONS.png"


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Modal } from "reactstrap";
import BookingModal from "../Booking/BookingModal";
import StayBooking from '../Stay/StayBooking';

const ExperienceDetails = ({ selectedPropertyDetail,setShowProperty, experienceData,direction, ...args }) => {
    const [noOfGuest, setNoOfGuest] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showStayBooking, setShowStayBooking] = useState(false)
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [stayBookingData, setStayBookingData] = useState()
  
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const callBack = () => {
        setShowStayBooking(false)
        setShowProperty(true)
        // callBackProperty(false)
      }

const addExperience = (experience) => {
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    console.log('fffdasfas', booking.experienceBooking)
    if (existBooking) {
      if (booking?.experienceBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter(booking => booking._id === id);
        };
        const expBookingIdToFind = experience?._id;
        const foundExpBooking = findAddonBookingById(booking?.experienceBooking, expBookingIdToFind);
        if (foundExpBooking.length > 0) {
          console.log('found expericence booking', booking)
          for (let i of booking.experienceBooking) {
            if (i._id === expBookingIdToFind) {
              console.log('iiiiidddddss', i)
            //   i.experienceCount = foundExpBooking.length + i.experienceCount
                i.guest = noOfGuest
                i.startDate= startDate
            }
          }
        } else {
          console.log('no found expericence booking', foundExpBooking)
          
          let bookingData = {
            _id: experience._id,
            experienceCount:  1,
            experienceName: experience.experienceName,
            experienceType: experience.experienceType,
            maxPerson: experience.maxPerson,
            minPerson: experience.minPerson,
            perPerson: experience.perPerson === 1 ? true : false,
            price:  experience.priceRegular,
            // price: experience.perPerson === 1 ? experience.priceRegular * noOfGuest : experience.priceRegular,
            guest: noOfGuest,
            startDate: startDate


          }
          
          booking.experienceBooking = [bookingData]
        }
        localStorage.setItem('stayBooking', JSON.stringify(booking));
      } else {
        console.log('not exist booking', booking);
        let perPersonData = experience.perPerson === 1 ? true : false
        let bookingData = {
            _id: experience._id,
            experienceCount:  1,
            experienceName: experience.experienceName,
            experienceType: experience.experienceType,
            maxPerson: experience.maxPerson,
            minPerson: experience.minPerson,
            perPerson: experience.perPerson === 1 ? true : false,
            price:  experience.priceRegular,
            // price: experience.perPerson === 1 ? experience.priceRegular * noOfGuest : experience.priceRegular,
            guest: noOfGuest,
            startDate: startDate

        }
        let obj = {
            experienceBooking: [bookingData],
          // stayCount: 1
        };
        booking.experienceBooking = [bookingData]
        localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }
    setShowStayBooking(true)
  }
  
    return (

        <>  {showStayBooking === false ? <>
            <main className="experience-detaills-page">
                <section className="pd01">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="pd01_top_wrapper d-flex justify-content-start align-items-center gap-1">
                                    <Swiper
                                        className="mySwiper"
                                        slidesPerView={3}
                                        spaceBetween={10}
                                    >
                                        <SwiperSlide className="pd01_top_thumbnail">
                                            <img
                                                src={Thumbnails}
                                                alt=""
                                                className="img-fluid w-100"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="pd01_top_thumbnail">
                                                <img
                                                    src={Thumbnails}
                                                    alt=""
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="pd01_top_thumbnail">
                                                <img
                                                    src={Thumbnails}
                                                    alt=""
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>

                                <div className="pd01_bottom_wrapper d-flex justify-content-start align-items-end gap-md-4 gap-2">
                                    <div className="pd01_bottom_thumbnails01">
                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails01}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>

                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails02}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>

                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails03}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>

                                    <div className="pd-bottom_rtl flex-fill">
                                        <div className="pd01_bottom_content">
                                            <div className="breadcrumb_wrapper">
                                                <nav
                                                    style={{ "--bs-breadcrumb-divider": "'>'" }}
                                                    ariaLabel="breadcrumb"
                                                >
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a href="#">Camps in</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">State</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">District</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">Locality</a>
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>

                                            <div className="pd01_content">
                                                <h4>{experienceData?.experienceName} </h4>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="pd01_content_ltr d-flex justify-content-between align-items-center">
                                                        <div className="rating_txt d-flex justify-content-center align-items-center">
                                                            4.8
                                                        </div>
                                                        <div className="ratings01">
                                                            <img
                                                                src={starRatings}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <br />
                                                            <span>36 reviews</span>
                                                        </div>
                                                        <img
                                                            src="img/Badges.png"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="pd01_content_rtl">
                                                        <button className="btn_cutom_atn border-0 bg-transparent">
                                                            <img src={shareIc} alt="" />
                                                            Share
                                                        </button>
                                                        <button className="btn_cutom_atn border-0 bg-transparent">
                                                            <img src={likeIc} alt="" />
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="edp01">
                    <div className="container-fluid">
                        <div className="row row-cols-lg-2 row-cols-1">
                            <div className="col">
                                <div className="edp01_content_wrapper">
                                    <div className="highlights_sec d-flex justify-content-between align-items-center">
                                        <div className="highlights_card">
                                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                <img src={highlights01} />
                                            </div>
                                            <div className="highlights_card_content">
                                                <h6>furut verified</h6>
                                            </div>
                                        </div>

                                        <div className="highlights_card">
                                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                <img src={highlights02} />
                                            </div>
                                            <div className="highlights_card_content">
                                                <h6>Pick up & drop</h6>
                                            </div>
                                        </div>

                                        <div className="highlights_card">
                                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                <img src={highlights03} />
                                            </div>
                                            <div className="highlights_card_content">
                                                <h6>All meals included</h6>
                                            </div>
                                        </div>

                                        <div className="highlights_card">
                                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                <img src={highlights04} />
                                            </div>
                                            <div className="highlights_card_content">
                                                <h6>Cottages</h6>
                                            </div>
                                        </div>

                                        <div className="highlights_card">
                                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                <img src={highlights05} />
                                            </div>
                                            <div className="highlights_card_content">
                                                <h6>Local Guide</h6>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="epd01_points_wrapper">
                                        <div className="epd01_ponis d-flex align-items-start gap-2">
                                            <img src={pointsIc} />
                                            <h6> lorem ipsum dollar smitlorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smitlorem ipsum dollar smit</h6>
                                        </div>

                                        <div className="epd01_ponis d-flex align-items-start gap-2">
                                            <img src={pointsIc} />
                                            <h6> lorem ipsum dollar smitlorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smitlorem ipsum dollar smit</h6>
                                        </div>

                                        <div className="epd01_ponis d-flex align-items-start gap-2">
                                            <img src={pointsIc} />
                                            <h6> lorem ipsum dollar smitlorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smitlorem ipsum dollar smit</h6>
                                        </div>
                                    </div>

                                    {/* <div className=""></div> */}
                                </div>
                            </div>

                            <div className="col">
                                <div className="guest_card_box">
                                    <div className="guest_card_inner">
                                        <div className="guest_card_top d-flex justify-content-between align-items-center">
                                            <div className="gct_left">
                                                <div className="price_tag"><h6>2000/-</h6></div>
                                            </div>
                                            <div className="gct_right">
                                                <h6>min persons: 2</h6>
                                            </div>
                                        </div>
                                        <div className="guest_card_footer">
                                            <div className="guest_input">
                                                <div className="number_ofGuest_card position-relative">
                                                    <label>guests</label>
                                                    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                                                        <DropdownToggle className='btn dropdown-btn' caret>{noOfGuest > 0 ? noOfGuest : 'add no of guests'}</DropdownToggle>
                                                        <DropdownMenu {...args}>
                                                            <DropdownItem header>Guest</DropdownItem>

                                                            <div>
                                                                <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                                                    <div className='dropdown_list_left'>
                                                                        <h6>Guest</h6>
                                                                        <p>ages 13 & above</p>
                                                                    </div>

                                                                    <div className='dropdown_list_right'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                            <div className='decrease' onClick={() => { setNoOfGuest(noOfGuest-1) }}>-</div>
                                                                            <div className='numbers'>{noOfGuest > 9 ? noOfGuest : `0${noOfGuest}`}</div>
                                                                            <div className='decrease' onClick={() => { setNoOfGuest(noOfGuest + 1) }}>+</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* <div>
                                                                <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                                                    <div className='dropdown_list_left'>
                                                                        <h6>young</h6>
                                                                        <p>ages 02 - 12 </p>
                                                                    </div>

                                                                    <div className='dropdown_list_right'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='decrease' onClick={() => { setChildren(children-1) }}>-</div>
                                                                            <div className='numbers'>{children > 9 ? children : `0${children}`}</div>
                                                                            <div className='decrease' onClick={() => { setChildren(children + 1) }}>+</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            {/* <div>
                                                                <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                                                    <div className='dropdown_list_left'>
                                                                        <h6>Pet</h6>
                                                                        <p>ages below 02</p>
                                                                    </div>

                                                                    <div className='dropdown_list_right'>
                                                                        <div className='d-flex align-items-center justify-content-between'>
                                                                        <div className='decrease' onClick={() => { setPet(pet-1) }}>-</div>
                                                                            <div className='numbers'>{pet > 9 ? pet : `0${pet}`}</div>
                                                                            <div className='decrease' onClick={() => { setPet(pet + 1) }}>+</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            <div>
                                                                <button className='btn btn-done' onClick={toggle}>Done</button>
                                                            </div>
                                                        </DropdownMenu>
                                                    </Dropdown>
                                                </div>
                                            </div>

                                            <div className="guest_input mt-2">
                                                <input type="date" class="datepicker-input" />
                                            </div>

                                            <div className="guest_input mt-2">
                                            <button className='btn btn-done' onClick={()=>addExperience(experienceData)}>Book Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>
</>:<> 
<StayBooking  callBack={callBack}/>
</>}
        </>

    )

}


ExperienceDetails.propTypes = {
    direction: PropTypes.string,
};

export default ExperienceDetails;