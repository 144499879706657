import { Children, useEffect, useState } from "react";
import { BASE_URL } from "../../services/config";
import StayBooking from "./StayBooking";
import { calculateDayCount } from "../../helpers/Helper";


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Thumbnails from "../../assets/img/Thumbnails.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../assets/css/styleNew.css";
import shareIc from "../../assets/img/share-ic.png";
import likeIc from "../../assets/img/like-ic.png";
import badges from "../../assets/img/Badges.png"
import hotTub from "../../assets/img/hot-tub.png"

import amenities1 from "../../assets/img/amenities1.png"
import amenities2 from "../../assets/img/amenities2.png"
import amenities3 from "../../assets/img/amenities3.png"
import amenities4 from "../../assets/img/amenities4.png"
import amenities5 from "../../assets/img/amenities5.png"
import amenities6 from "../../assets/img/amenities6.png"
import amenities7 from "../../assets/img/amenities7.png"
import hostPoster from "../../assets/img/hostPoster.jpg"

import foodBeverage from "../../assets/img/food-beverage.png"

// const StayDetail = ({ stayDetail, selectedPropertyDetail, setShowStayDetail, callBackProperty, setShowProperty }) => {
const StayDetail = ({ stayDetail, selectedPropertyDetail, setShowStayDetail, callBackProperty,setShowProperty, direction, ...args }) => {
  const [stayDetailData, setStayDetailData] = useState(stayDetail);
  const [showStayBooking, setShowStayBooking] = useState(false)
  const [adults, setAduts] = useState(1)
  const [children, setChildren] = useState(0)
  const [pet, setPet] = useState(0)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [stayBookingData, setStayBookingData] = useState()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const formatDates = (date) => date ? date.format('MM/DD/YYYY') : '';
  const onSubmit = (e) => {
    e.preventDefault();
    console.log("refresh prevented");
    let stayTripDay = calculateDayCount(startDate, endDate)
    setShowStayBooking(true);
    let existBooking = localStorage.getItem('stayBooking');

    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log('boookkkkk', booking.stayBooking)
      console.log('stayDetailBo', stayDetail)
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const daysBetween = calculateDayCount(startDate, endDate);
      console.log('daysBetween', daysBetween)
      const stayBookingIdToFind = stayDetail._id;
      const foundStayBooking = findStayBookingById(booking.stayBooking, stayBookingIdToFind);
      if (foundStayBooking.length > 0) {
        console.log('found stay booking', booking)
        for (let i of booking.stayBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log('iiiiidddddss', i)
            i.stayCount = foundStayBooking.length + i.stayCount
            let countAdults = adults
            let countAvailable = stayDetail.countAvailable
            let countCapacity = stayDetail.countCapacity
            let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
           
            if(adults > countAvailable * countCapacity){
              alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
              i.stayCount = count
            }
            if(i.stayCount>countAvailable){
              i.stayCount = countAvailable
              alert(`This Stay Availeblty Only ${countAvailable}`)
            }
          }

        }
        booking.adults = adults
        booking.children = children
        booking.pet = pet
        booking.checkInDate = startDate
        booking.checkOutDate = endDate
        booking.propertyId = stayDetail.propertyId
        booking.hostId = selectedPropertyDetail.userId
      } else {
        console.log('no found stay booking', foundStayBooking)
        let countAdults = adults
        let countAvailable = stayDetail.countAvailable
        let countCapacity = stayDetail.countCapacity
        let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
        let stayPrice = stayDetail.perPerson === true ? stayDetail.priceRegular * adults : stayDetail.priceRegular
        let bookingData = {
          _id: stayDetail._id,
          propertyId: stayDetail.propertyId,
          stayTypeId: stayDetail.stayTypeId,
          stayType: stayDetail.stayType,
          perPerson: stayDetail.perPerson,
          // price: 1500,
          price: stayDetail.priceRegular,
          // price: stayPrice,
          stayCount: count,
          totalPrice: 0,
          adults : adults,
          children : children,
          pet : pet,
          checkInDate : startDate,
          checkOutDate : endDate,
          countAvailable: countAvailable,
          countCapacity: countCapacity,
          stayDay: stayTripDay === 0 ? 1 : stayTripDay
        }
        // stayDetail.stayCount = 1
        booking.stayBooking.push(bookingData);
        // booking.adults = adults
        // booking.children = children
        // booking.pet = pet
        booking.checkInDate = startDate
        booking.checkOutDate = endDate
        booking.propertyId = stayDetail.propertyId
        booking.hostId = selectedPropertyDetail.userId
        // booking.stayCount = 1
      }

      setStayBookingData(booking)
      localStorage.setItem('stayBooking', JSON.stringify(booking));
    } else {
      console.log('not exist booking');
      stayDetail.stayCount = 1
      let countAdults = adults
      let countAvailable = stayDetail.countAvailable
      let countCapacity = stayDetail.countCapacity
      let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
      let bookingData = {
        _id: stayDetail._id,
        propertyId: stayDetail.propertyId,
        stayTypeId: stayDetail.stayTypeId,
        stayType: stayDetail.stayType,
        perPerson: stayDetail.perPerson,
        // price: stayDetail.perPerson === true ? stayDetail.priceRegular * adults : stayDetail.priceRegular,: 
        price: stayDetail.priceRegular,
        stayCount: count,
        totalPrice: 0,
        adults : adults,
        children : children,
        pet : pet,
        checkInDate : startDate,
        checkOutDate : endDate,
        countAvailable: countAvailable,
        countCapacity: countCapacity,
        stayDay: stayTripDay === 0 ? 1 : stayTripDay
      }
      if(adults > countAvailable * countCapacity){
        alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
      }
      let obj = {
        propertyId: stayDetail.propertyId,
        hostId: selectedPropertyDetail.userId,
        adults: adults,
        children: children,
        pet: pet,
        checkInDate: startDate,
        checkOutDate: endDate,
        stayBooking: [bookingData],

        // stayCount: 1
      };
      setStayBookingData(obj)
      localStorage.setItem('stayBooking', JSON.stringify(obj));
    }
  };

  const callBack = () => {
    setShowStayBooking(false)
    setShowStayDetail(false)
    setShowProperty(true)
    // callBackProperty(false)
  }

  const handleDateChange = (event) => {
    console.log('dateee', event.target.value)
    setStartDate(event.target.value); // Update start date state
  };
  // const addBooking =()=>{
  //   localStorage.setItem('stayBooking', stayDetailData)
  // }
  const today = new Date().toISOString().split('T')[0];
  useEffect(() => {
    let existBooking = localStorage.getItem('stayBooking');
    if (existBooking) {
      let booking = JSON.parse(existBooking);
      console.log('iiiii', booking)
      setAduts(booking.adults)
      setChildren(booking.children)
      setPet(booking.pet)
      setStartDate(booking.checkInDate)
      setEndDate(booking.checkOutDate)

    }
  }, [showStayBooking])
  return (
    <>
      {showStayBooking === false ? <>
        <main className="stay-details-page">
          <section className="pd01">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="pd01_top_wrapper d-flex justify-content-start align-items-center gap-1">
                    <Swiper
                      className="mySwiper"
                      slidesPerView={3}
                      spaceBetween={10}
                    >
                      <SwiperSlide className="pd01_top_thumbnail">
                        <img
                          src={Thumbnails}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>

                  <div className="pd01_bottom_wrapper d-flex gap-md-4 gap-2">
                    <div className="pd-bottom_rtl">
                      <div className="pd01_bottom_content">
                        <div className="breadcrumb_wrapper">
                          <nav
                            style={{ "--bs-breadcrumb-divider": "'>'" }}
                            ariaLabel="breadcrumb"
                          >
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <a href="#">Camps in</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#">State</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#">District</a>
                              </li>
                              <li className="breadcrumb-item">
                                <a href="#">Locality</a>
                              </li>
                            </ol>
                          </nav>
                        </div>

                        <div className="pd01_content">
                          <h4 className="productTitle">  {stayDetail?.stayType}</h4>

                          <div className="d-flex align-items-center gap-lg-5 gap-3">
                            <div className="pd01_content_ltr">
                              <img src={badges} className="img-fluid" />
                            </div>
                            <div className="pd01_content_rtl">
                              <button className="btn_cutom_atn border-0 bg-transparent">
                                <img src={shareIc} alt="" />
                                Share
                              </button>
                              <button className="btn_cutom_atn border-0 bg-transparent">
                                <img src={likeIc} alt="" />
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sdp_about">
                        <p>Tree House at <span>Great Himalayan Outdoors - four season camps</span></p>

                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis ante. Morbi egestas nisl nec scelerisque consequat. Curabitur eu tellus placerat, maximus tortor at, vulputate magna. Duis tincidunt diam erat, sed vulputate tortor vestibulum eget. Nunc cursus leo nec odio hendrerit, ut mollis urna semper. </p>
                      </div>

                      <div className="sdp_highlights">
                        <p><img src={hotTub} /> <span>Hot Tub</span> </p>
                        <p><img src={hotTub} /> <span>Hot Tub</span> </p>
                        <p><img src={hotTub} /> <span>Hot Tub</span> </p>
                      </div>

                      <div className="sdp_amentities">
                        <div className="title">amenities</div>
                        <div className="subtitle">facilities available in Great Himalayan Outdoors - four season camps </div>

                        <div className="highlights_sec d-flex justify-content-between align-items-center bg-transparent px-0">
                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities1} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>wifi</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities2} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Restaurant</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities3} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Parking</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities4} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Fire ring</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities5} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Common toilet</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities6} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Outdoor shower</h6>
                            </div>
                          </div>

                          <div className="highlights_card">
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={amenities7} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>Fire logs</h6>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sdp_amentities">
                        <div className="title">food & beverages</div>
                        <div className="subtitle">food & beverages available in Great Himalayan Outdoors - four season camps </div>

                        <div className="d-flex justify-content-start">
                          <div className="sdp_amentities_card">
                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Breakfast (included)</h5></div>
                            </div>

                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Lunch (included)</h5></div>
                            </div>

                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Dinner (included)</h5></div>
                            </div>
                          </div>

                          <div className="sdp_amentities_card">
                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Welcome Drink (included)</h5></div>
                            </div>

                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Barbeque  (included)</h5></div>
                            </div>

                            <div className="amentities_card d-flex align-items-center">
                              <img src={foodBeverage} />
                              <div className="amentities_card_content"> <h5>Bamboo Chicken  (included)</h5></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="sdp_amentities">
                        <div className="title">hosted by</div>
                        <div className="subtitle">hosts of Great Himalayan Outdoors - four season camps </div>

                        <div className="d-flex align-items-end">
                          <div className="message_box_card">
                            <div className="message_box_card_header d-flex justify-content-between align-items-start">
                              <div className="message_box_owner d-flex justify-content-between align-items-center">
                                <div className="message_box_owner_img">
                                  <img src={hostPoster} className="img-fluid" />
                                </div>
                                <div className="message_box_owner_content d-flex flex-column">
                                  <h5>Surajit Das</h5>
                                  <p>Member since 2001</p>
                                </div>
                              </div>

                              <button className="btn btn-msg">Message</button>
                            </div>

                            <div className="message_box_card_footer d-flex flex-column">
                              <h6>moderate reply rate, can speak Hindi, English,  Bengali</h6>
                              <p>lorem ipsum dollar smit, lorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smit</p>
                            </div>

                          </div>

                          <div className="cohost_sec d-flex flex-column">
                            <div className="title mb-0">co host</div>

                            <div className="cohost_card d-flex align-items-center">
                              <div className="cohost_card_img">
                                <img src={hostPoster} className="img-fluid" />
                              </div>
                              <h5>Surajit Das</h5>
                            </div>

                            <div className="cohost_card d-flex align-items-center">
                              <div className="cohost_card_img">
                                <img src={hostPoster} className="img-fluid" />
                              </div>
                              <h5>Surajit Das</h5>
                            </div>
                          </div>
                        </div>


                      </div>

                    </div>

                    <div className="guest_card_box">
                      <div className="guest_card_inner">
                        <div className="guest_card_top d-flex justify-content-between align-items-center">
                          <div className="gct_left">
                            <div className="price_tag"><h6>2000/-</h6></div>
                          </div>
                          <div className="gct_right">
                            <h6>min persons: 2</h6>
                          </div>
                        </div>
                        <div className="guest_card_footer">
                          <div className="guest_input">
                            <div className="number_ofGuest_card position-relative">
                              <label>guests</label>
                              <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
                                <DropdownToggle className='btn dropdown-btn' caret>guest</DropdownToggle>
                                <DropdownMenu {...args}>
                                  <div>
                                    <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                      <div className='dropdown_list_left'>
                                        <h6>adults</h6>
                                        <p>ages 13 & above</p>
                                      </div>

                                      <div className='dropdown_list_right'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <div className='decrease' onClick={() => { setAduts(adults - 1) }}>-</div>
                                          <div className='numbers'>{adults > 9 ? adults : `0${adults}`}</div>
                                          <div className='decrease' onClick={() => { setAduts(adults + 1) }}>+</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                      <div className='dropdown_list_left'>
                                        <h6>children</h6>
                                        <p>ages 13 & above</p>
                                      </div>

                                      <div className='dropdown_list_right'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <div className='decrease' onClick={() => { setChildren(children - 1) }}>-</div>
                                          <div className='numbers'>{children > 9 ? children : `0${children}`}</div>
                                          <div className='decrease' onClick={() => { setChildren(children + 1) }}>+</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div>
                                    <div className='dropdown_list d-flex justify-content-between align-items-center'>
                                      <div className='dropdown_list_left'>
                                        <h6>pet</h6>
                                        <p>ages 13 & above</p>
                                      </div>

                                      <div className='dropdown_list_right'>
                                        <div className='d-flex align-items-center justify-content-between'>
                                          <div className='decrease' onClick={() => { setPet(pet - 1) }}>-</div>
                                          <div className='numbers'>{pet > 9 ? pet : `0${pet}`}</div>
                                          <div className='decrease' onClick={() => { setPet(pet + 1) }}>+</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>



                                  <div>
                                    <button className='btn btn-done' onClick={toggle}>Done</button>
                                  </div>
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                          </div>

                          <div className="guest_input mt-2">
                            {/* <input type="date" class="datepicker-input" />
                                                <input type="date" class="datepicker-input" /> */}


                            <div className="d-flex gap-2">

                              <div>
                                <label>Start Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={startDate}
                                  onChange={handleDateChange}
                                  min={today}
                                />
                              </div>

                              <div>
                                <label>End Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  value={endDate}
                                  onChange={(e) => setEndDate(e.target.value)}
                                  min={today}
                                />
                              </div>

                            </div>
                          </div>

                          <div className="guest_input mt-2">
                            <button className='btn btn-done' onClick={onSubmit}>Book Now</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="edp01">
            <div className="container-fluid">
              <div className="row">
                <div className="col">

                </div>
              </div>
            </div>
          </section>

        </main>
      </> :
        <StayBooking stayDetailData={stayDetailData} selectedPropertyDetail={selectedPropertyDetail} callBack={callBack} stayBookingData={stayBookingData} />
      }

      {/* {showStayBooking === false ? <>
        <div className="row g-3" >
          <div className="search-bar d-flex p-4">
            {stayDetail?.stayType}
          </div>
          <div className="search-bar d-flex justify-content-center p-4">
            <form className="" onSubmit={onSubmit}>

              <div className="mb-3 position-relative ">
                <label htmlFor="adults" className="formLabel">
                  adults
                </label>
                <input type="number" className="form-control" id="guests"
                  value={adults}
                  onChange={(e) => setAduts(e.target.value)}
                />
              </div>
              <div className="mb-3 position-relative">
                <label htmlFor="children" className="formLabel">
                  children
                </label>
                <input type="number" className="form-control" id="children"
                  value={children}
                  onChange={(e) => setChildren(e.target.value)}
                />
              </div>
              <div className="mb-3 position-relative">
                <label htmlFor="pet" className="formLabel">
                  pet
                </label>
                <input type="number" className="form-control" id="pet"
                  value={pet}
                  onChange={(e) => setPet(e.target.value)}
                />
              </div>
              <div className="mb-3 position-relative">
                <div htmlFor="when" className="formLabel">
                  when
                </div>
                <div>
                  <div className="row">
                    <div className="col-sm-6 col-lg-5 mb-3 mb-sm-0">
                      <input
                        type="date"
                        className="form-control"
                        value={startDate}
                        onChange={handleDateChange}
                        min={today}
                      />
                    </div>
                    <div className="col-sm-6 col-lg-5">
                      <input
                        type="date"
                        className="form-control"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        min={today}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-custom" >
                Booking
              </button>
            </form>

          </div>
          <div className="experiences p-4">
            <h3 className="mb-4">amenities</h3>
            <div className="d-flex justify-content-between">
              {selectedPropertyDetail?.amenities.map((item, index) => {
                return (
                  <><img src={`${BASE_URL}/${item.amenitiesImg}`} alt="" className="img-fluid" /> <span className=''> {item.amenitiesName}</span>{"  "}</>
                );
              })}
            </div>
          </div>
        </div>

      </> :
        <StayBooking stayDetailData={stayDetailData} selectedPropertyDetail={selectedPropertyDetail} callBack={callBack} stayBookingData={stayBookingData} />
      } */}
    </>
  )
}

export default StayDetail;