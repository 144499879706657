import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getLoggedData } from '../helpers/Helper';
import Chat from '../components/Messages/Chat';
import { useParams } from 'react-router-dom';
import authServices from '../services/auth.services';

function Messages() {
  const [currentUser, setCurrentUser] = useState(null);
  const [receiverId, setReceiverId] = useState('')
  const { id } = useParams();
  let loggedUser = getLoggedData()


  useEffect(()=>{
    setCurrentUser(loggedUser.user)
    authServices.bookingDetailByIdApi({ id: id }).then((result)=>{
        if(result.status===200){
            console.log('resultBooking', result.data.data)
            setReceiverId(result.data.data.hostId)
        }
    })
    // fetchMessages()
  },[])
  return (
    <div className="container">
      <h1 className="text-center my-4"></h1>
      {/* {currentUser && <SendMessage sendMessage={sendMessage} />}
      <MessageList messages={messages} /> */}
          {currentUser && <Chat currentUser={currentUser} receiverId={receiverId}/>}
    </div>
  );
}

export default Messages;
