import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { BASE_URL } from '../../services/config';
const OffcanvasModal = ({ toggle, isOpen, images, title }) => {
    // const [isOpen, setIsOpen] = useState(false);

    // const toggle = () => setIsOpen(!isOpen);
    return (
        <div >
            <Offcanvas className='offCanvas1' isOpen={isOpen} toggle={toggle} direction="top" scrollable>
                <OffcanvasHeader toggle={toggle}>
                    Glimpses of {title}
                </OffcanvasHeader>
                <OffcanvasBody>
                    <div class="gallery-container">
                        
                        {images && images.map((item, index) => {
                            return (
                                <div class="tile">
                                    <img
                                        src={item?.propImgName ? `${BASE_URL}/${item.propImgName}` : `${BASE_URL}/${item.expImgName}`}
                                        alt=""
                                    />

                                </div>
                            )
                        })}
                        {/* <div class="tile">
                            <img
                                src="https://via.placeholder.com/800x600/C72B41/9F2234"
                                alt=""
                            />
                        </div>
                        <div class="tile">
                            <img
                                src="https://via.placeholder.com/800x600/800834/66062A"
                                alt=""
                            />
                        </div>
                        <div class="tile">
                            <img
                                src="https://via.placeholder.com/800x600/530332/420228"
                                alt=""
                            />
                        </div>
                        <div class="tile">
                            <img
                                src="https://via.placeholder.com/800x600/2E122D/2A021A"
                                alt=""
                            />
                        </div> */}
                    </div>
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default OffcanvasModal;