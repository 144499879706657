import leftImg from '../../src/assets/img/right.png'
import rightImg from '../../src/assets/img/left.png'
import cardImg from '../../src/assets/img/card.png'
import aiIcon from '../../src/assets/images/App icon.png'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import HappeningExperiences from '../components/Experience/HappeningExperiences';
import ExperiencesData from '../components/Experience/ExperiencesData';
import Features from '../components/Comman/Features';
import { useEffect, useState } from 'react';
import authServices from '../services/auth.services';
import HeroSec from '../components/Comman/HeroSection';

const Experiences = () => {

  const [terrianList, setTerrianList] = useState([])
  useEffect(() => {
    authServices.terrianListApi().then((result) => {
      if (result.status === 200) {
        console.log(result.data.data)
        setTerrianList(result.data.data)
      }
    })
  }, [])
  return (
    <>
      <HeroSec />

      {/* <HappeningExperiences /> */}
      <div className="container">
        <div className="row">
          <div className="col-12 text-start">
            <div className="title">experience
            </div>
            <div className="subtitle">upcoming expericence near your
            </div>
          </div>
        </div>
        <ExperiencesData data={'EXPERIENCE'} />
      </div>

      {/* <Features /> */}
    </>
  )
}

export default Experiences