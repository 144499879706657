import { required, yup } from "./CommonValidation";
import * as Yup from 'yup';
export const AuthSchema = (type) => { 
    if (type === 'PHONE') {
        return yup({
            mobileNo: required("Please enter mobile no").min(10, 'Please enter valid mobile no'),
        });
    } else if(type ==='REGISTER'){
        return yup({
            name: required("Please enter center name").max(50, 'Must be exactly 5 character'),
            mobileNo:  required("Please enter mobile no").min(10, 'Please enter valid mobile no'),
            email: required("Please enter email").email('Please enter a valid email address.'),
        });
    }
};