
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Thumbnails from "../../assets/img/Thumbnails.png";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Thumbnails01 from "../../assets/img/Thumbnails01.png";
import Thumbnails02 from "../../assets/img/Thumbnails02.png";
import Thumbnails03 from "../../assets/img/Thumbnails03.png";
import starRatings from "../../assets/img/star-ratings.png";
import shareIc from "../../assets/img/share-ic.png";
import likeIc from "../../assets/img/like-ic.png";
import highlights01 from "../../assets/img/highlights01.png"
import highlights02 from "../../assets/img/highlights02.png"
import highlights03 from "../../assets/img/highlights03.png"
import highlights04 from "../../assets/img/highlights04.png"
import highlights05 from "../../assets/img/highlights05.png"
import pointsIc from "../../assets/img/iCONS.png"


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate, useParams } from 'react-router-dom';
import { calculateDayCount, checkWeekendOrRegular, convertDateToYYYYMMDD, formattedDateDDMMMYYYY, getDayName, getDaysCheckInAndCheckOutDates, getLoggedData } from '../../helpers/Helper';
import Login from '../../components/Authentication/Login';
import { toast } from 'react-toastify';
import authServices from '../../services/auth.services';
import moment from 'moment';

import Features from '../../components/Comman/Features';
import EventIcon from '@mui/icons-material/Event';
import SettingsIcon from '@mui/icons-material/Settings';
import VerifiedIcon from '@mui/icons-material/Verified';
import Brightness5OutlinedIcon from '@mui/icons-material/BrightnessHigh';
import BrightnessMediumOutlinedIcon from '@mui/icons-material/BrightnessMediumOutlined';
import Brightness3Icon from '@mui/icons-material/Brightness3';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import PinDropIcon from '@mui/icons-material/PinDrop';
import SportsGymnasticsOutlinedIcon from '@mui/icons-material/SportsGymnasticsOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import LocalAirportOutlinedIcon from '@mui/icons-material/LocalAirportOutlined';
import TramIcon from '@mui/icons-material/Tram';
import TrainIcon from '@mui/icons-material/Train';
import LocalTaxiIcon from '@mui/icons-material/LocalTaxi';
import OffcanvasModal from '../../components/Comman/OffcanvasModal';
import { BASE_URL } from '../../services/config';
import TimerIcon from '@mui/icons-material/Timer';
import DatePicker from "react-datepicker";
import HostedBy from '../../components/Comman/HostedBy';
import campRules from '../../assets/img/campus-rules.png'
import campRules1 from '../../assets/img/campus-rules-restrict.png'
import arrowWhite from '../../assets/img/arrow-light.png'


const EventDetail = ({ direction, ...args }) => {
    const [noOfGuest, setNoOfGuest] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [experienceData, setExperienceData] = useState([])
    const [modal, setModal] = useState(false);
    const [adults, setAdults] = useState(0)
    const [children, setChildren] = useState(0)
    const [temporaryBooking, setTemporaryBooking] = useState('')
    const [isOpenCanvas, setIsOpenCanvas] = useState(false);
    const [activeTab, setActiveTab] = useState('Itenery')
    const [bookingPopup, setPopup] = useState(false)
    const [selectedPropertyDetail, setSelectedPropertyDetail] = useState('')

    const toggleCanvas = () => setIsOpenCanvas(!isOpenCanvas);
    const toggleLogin = () => setModal(!modal);
    const navigate = useNavigate();
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const { id } = useParams()
    const onClickBookingBtn = () => setPopup(!bookingPopup)


    const checkExpAvalablity = (experience) => {
        if (startDate === null || startDate === '') {
            // alert('Please select first chekin and checkout date')
            toast.error("Please select first chekin date", { toastId: "login_fail", autoClose: 3000 });
            return false
        }
        if (Number(adults) < Number(experience.minPerson)) {
            toast.error(`Please enter min ${experience.minPerson} person`, { toastId: "login_fail", autoClose: 3000 });
            return false
        }
        authServices.checkEventAvailablity({ experienceId: experience?._id, adults: adults, children: children, checkInDate: startDate })
            .then((result) => {
                console.log('avalable booking', result)
                if (result.status === 200) {
                    addExperience(experience)
                }
                if (result.status === 404) {
                    toast.error("Not avalable any capicity", {
                        toastId: "booking_fail",
                        autoClose: 3000,
                    });
                    return false
                }
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    const addExperience = (experience) => {
        let loggedUser = getLoggedData()
        if (!loggedUser.isLoggedIn) {
            toggleLogin()
            return
        }
        let checkStartDateWeekendOrRegular = checkWeekendOrRegular(startDate)
        console.log('checkStartDateWeekendOrRegular', checkStartDateWeekendOrRegular)
        let existBooking = temporaryBooking
        let booking = existBooking
        const checkInCheckOutDays = getDaysCheckInAndCheckOutDates(startDate, endDate)
        console.log('expCheckInCheckOutDays', checkInCheckOutDays)
        let weekendDays = checkInCheckOutDays.weekendCount
        let regularDays = checkInCheckOutDays.regularCount
        if (existBooking) {
            if (booking?.eventBooking) {
                const findAddonBookingById = (array, id) => {
                    return array.filter(booking => booking.experienceId === id);
                };
                const expBookingIdToFind = experience?._id;
                const foundExpBooking = findAddonBookingById(booking?.eventBooking, expBookingIdToFind);
                if (foundExpBooking.length > 0) {
                    console.log('found expericence booking', booking)
                    for (let i of booking.eventBooking) {
                        if (i.experienceId === expBookingIdToFind) {
                            console.log('iiiiidddddss', i)
                            //   i.experienceCount = foundExpBooking.length + i.experienceCount
                            i.adults = adults
                            i.children = children
                            i.checkInDate = startDate
                            i.checkOutDate = experience?.endDate
                            i.weekendDays = weekendDays
                            i.regularDays = regularDays
                            i.isWeekendPrice = checkStartDateWeekendOrRegular === 'Weekend' ? true : false
                        }
                    }
                } else {
                    console.log('no found expericence booking', foundExpBooking)

                    let bookingData = {
                        experienceId: experience._id,
                        experienceCount: 1,
                        experienceName: experience.experienceName,
                        experienceType: experience.experienceType,
                        experienceImg: experience.expImg[0].expImgName,
                        maxPerson: Number(experience.maxPerson),
                        minPerson: Number(experience.minPerson),
                        perPerson: experience.perPerson === 1 ? true : false,
                        price: Number(experience.priceRegular),
                        // price: experience.perPerson === 1 ? experience.priceRegular * noOfGuest : experience.priceRegular,
                        adults: adults,
                        children: children,
                        checkInDate: startDate,
                        checkOutDate: experience?.endDate,
                        weekendDays: weekendDays,
                        regularDays: regularDays,
                        priceRegular: Number(experience.priceRegular),
                        priceWeekend: Number(experience.priceWeekend),
                        isWeekendPrice: checkStartDateWeekendOrRegular === 'Weekend' ? true : false
                    }

                    //   booking.eventBooking = [bookingData]
                    booking.eventBooking.push(bookingData)
                }
                // localStorage.setItem('stayBooking', JSON.stringify(booking));
                addBookingInCart(booking)
            } else {
                console.log('not exist booking', booking);
                let perPersonData = experience.perPerson === 1 ? true : false
                let bookingData = {
                    experienceId: experience._id,
                    experienceCount: 1,
                    experienceName: experience.experienceName,
                    experienceType: experience.experienceType,
                    experienceImg: experience.expImg[0].expImgName,
                    maxPerson: Number(experience.maxPerson),
                    minPerson: Number(experience.minPerson),
                    perPerson: experience.perPerson === 1 ? true : false,
                    price: Number(experience.priceRegular),
                    // price: experience.perPerson === 1 ? experience.priceRegular * noOfGuest : experience.priceRegular,
                    adults: adults,
                    children: children,
                    checkInDate: startDate,
                    checkOutDate: experience?.endDate,
                    weekendDays: weekendDays,
                    regularDays: regularDays,
                    priceRegular: Number(experience.priceRegular),
                    priceWeekend: Number(experience.priceWeekend),
                    isWeekendPrice: checkStartDateWeekendOrRegular === 'Weekend' ? true : false

                }
                let obj = {
                    eventBooking: [bookingData],
                    // stayCount: 1
                };
                booking.eventBooking = [bookingData]
                // localStorage.setItem('stayBooking', JSON.stringify(booking));
                addBookingInCart(booking)
            }
        } else {
            console.log('not exist booking');
            let bookingData = {
                hostId: experience.userId,
                experienceId: experience._id,
                experienceCount: 1,
                experienceName: experience.experienceName,
                experienceType: experience.experienceType,
                experienceImg: experience.expImg[0].expImgName,
                maxPerson: Number(experience.maxPerson),
                minPerson: Number(experience.minPerson),
                perPerson: experience.perPerson === 1 ? true : false,
                price: Number(experience.priceRegular),
                // price: experience.perPerson === 1 ? experience.priceRegular * noOfGuest : experience.priceRegular,
                adults: adults,
                children: children,
                checkInDate: startDate,
                checkOutDate: experience?.endDate,
                eventDays: null,
                priceRegular: Number(experience.priceRegular),
                priceWeekend: Number(experience.priceWeekend),
                isWeekendPrice: checkStartDateWeekendOrRegular === 'Weekend' ? true : false
            }
            let obj = {
                userId: loggedUser?.user?._id || '',
                hostId: experience.userId,
                adults: adults,
                children: children,
                checkInDate: startDate,
                propertyId: experience.propertyId,
                eventBooking: [bookingData],
                // stayCount: 1
            };
            console.log('bookingData', obj)
            addBookingInCart(obj)
        }
        // setShowStayBooking(true)
        // navigate('/booking')
    }
    const handleDateChange = (event) => {
        console.log('dateee', event.target.value)
        setStartDate(event.target.value); // Update start date state
    };

    const addBookingInCart = (data) => {
        console.log('dddda', data)
        let booking = data
        authServices.addTripBookingInCartApi({ "booking": booking }).then((response) => {
            console.log('booking added in cart', response.data.data)
            navigate(`/booking/${response.data.data._id}`)

        }).catch((error) => {
            console.log('error in cart', error)
        })

    }
    const today = new Date().toISOString().split('T')[0];
    useEffect(() => {


        authServices.eventDetailById({ id: id })
            .then((res) => {
                console.log('eventData', res.data)
                setExperienceData(res.data.data)
                let startD = moment(res.data.data.startDate).format('YYYY-MM-DD')
                console.log('res.data.data.startDate', startD)
                setStartDate(startD)
                if(adults === 0 ){
                    setAdults(Number(res.data.data.minPerson))
                }
                if (res?.data?.data?.propertyId) {
                    authServices.propertyDetailByIdApi({ id: res.data.data.propertyId }).then((result) => {
                        setSelectedPropertyDetail(result.data.data)
                    })
                }
                let loggedUser = getLoggedData()
                if (loggedUser?.isLoggedIn) {
                    authServices.temporaryTripBookingByUserIdAndPropertyId({ userId: loggedUser?.user?._id, propertyId: res.data.data.propertyId }).then((result) => {
                        if (result.status === 200) {
                            console.log('temporaryBooking', result.data.data)
                            let rs = result?.data?.data
                            if (rs?.booking) {
                                setTemporaryBooking(rs?.booking)
                                setAdults(rs?.booking.eventBooking[0].adults)
                                setChildren(rs?.booking.eventBooking[0].children)
                                setStartDate(rs?.booking.eventBooking[0].checkInDate)
                                setEndDate(rs?.booking.eventBooking[0].checkOutDate)
                                setChildren(rs?.booking.children)
                                //   setStartDate(rs?.booking.checkInDate)
                                //   setEndDate(rs?.booking.checkOutDate)
                            }
                        }
                    }).catch((error) => {
                        console.log('error', error)
                    })
                }
            }).catch((error) => {
                console.log('error', error)
            })
    }, [])
    return (
        <>
            <main className="experience-detaills-page">
                <section className="pd01">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-12">
                                <div className="pd01_top_wrapper">
                                    <Swiper
                                        className="mySwiper"
                                        slidesPerView={4}
                                        spaceBetween={1}
                                        loop="true"
                                        breakpoints={{
                                            // when window width is >= 640px
                                            320: {
                                                slidesPerView: 1,
                                            },
                                            // when window width is >= 768px
                                            640: {
                                                slidesPerView: 2,
                                            },
                                            800: {
                                                slidesPerView: 3,
                                            },
                                            1200: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                    >
                                        {experienceData?.expImg ? experienceData?.expImg.map((item, index) => {
                                            return (
                                                <SwiperSlide className="pd01_top_thumbnail" key={index} onClick={() => toggleCanvas()}>
                                                    <img
                                                        src={`${BASE_URL}/${item.expImgName}`}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </SwiperSlide>
                                            )
                                        }) : <>
                                            <SwiperSlide>
                                                <div className="pd01_top_thumbnail">
                                                    <img
                                                        src={Thumbnails}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="pd01_top_thumbnail">
                                                    <img
                                                        src={Thumbnails}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="pd01_top_thumbnail">
                                                    <img
                                                        src={Thumbnails}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="pd01_top_thumbnail">
                                                    <img
                                                        src={Thumbnails}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="pd01_top_thumbnail">
                                                    <img
                                                        src={Thumbnails}
                                                        alt=""
                                                        className="img-fluid"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        </>
                                        }
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="pd01_bottom_wrapper d-flex justify-content-start align-items-start">
                            <div className="pd01_bottom_thumbnails01">


                                <div className="pd01_bottom_thumbnail01_inner">
                                    <img
                                        src={experienceData?.expImg && experienceData?.expImg[0]?.expImgName ? `${BASE_URL}/${experienceData?.expImg[0]?.expImgName}` : Thumbnails01}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>

                                <div className="pd01_bottom_thumbnail01_inner">
                                    <img
                                        src={experienceData?.expImg && experienceData?.expImg[1]?.expImgName ? `${BASE_URL}/${experienceData?.expImg[1]?.expImgName}` : Thumbnails02}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>

                                <div className="pd01_bottom_thumbnail01_inner">
                                    <img
                                        src={experienceData?.expImg && experienceData?.expImg[2]?.expImgName ? `${BASE_URL}/${experienceData?.expImg[2]?.expImgName}` : Thumbnails03}
                                        alt=""
                                        className="img-fluid"
                                    />
                                </div>
                            </div>

                            <div className="pd-bottom_rtl flex-fill">
                                <div className="pd01_bottom_content">
                                    <div className="breadcrumb_wrapper">
                                        <nav
                                            style={{ "--bs-breadcrumb-divider": "'>'" }}
                                            ariaLabel="breadcrumb"
                                        >
                                            <ol className="breadcrumb">
                                                <li className="breadcrumb-item">
                                                    <a href="#" className="small">Camps in</a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <a href="#">{selectedPropertyDetail?.propertyLocated?.state || experienceData?.location?.state}</a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <a href="#">{selectedPropertyDetail?.propertyLocated?.city || experienceData?.location?.city}</a>
                                                </li>
                                                <li className="breadcrumb-item">
                                                    <a href="#">{selectedPropertyDetail?.propertyLocated?.fullAddress || experienceData?.location?.address}</a>
                                                </li>
                                            </ol>
                                        </nav>
                                    </div>

                                    <div className="pd01_content">
                                        <h4>{experienceData?.experienceName}</h4>

                                        {experienceData?.isExperience === false && <div className="d-lg-flex pd01_svg">
                                            <div className="d-flex align-items-center me-5">
                                                <TimerIcon className='text-info' />
                                                <div>
                                                    <small>duration</small>
                                                    <h6>{calculateDayCount(experienceData.startDate, experienceData.endDate)} Night {calculateDayCount(experienceData.startDate, experienceData.endDate) + 1} Days</h6>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <EventIcon />
                                                <div>
                                                    <small>date</small>
                                                    <h6>{formattedDateDDMMMYYYY(experienceData?.startDate)} To {formattedDateDDMMMYYYY(experienceData?.endDate)}</h6>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="edp02">
                    <div className="container mt-5">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-12">

                                <div className="card p-4">
                                    <div className="highlights_sec d-flex justify-content-start align-items-start bg-transparent overflow-x-scroll px-0">
                                        {experienceData?.includesData && experienceData?.includesData.map((item, index) => {
                                            return (

                                                <div className="highlights_card" key={index}>
                                                    <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                                        {item?.img ? <img src={`${BASE_URL}/${item?.img}`} /> : <SettingsIcon />}
                                                    </div>
                                                    <div className="highlights_card_content">
                                                        <h6>{item?.includesName}</h6>
                                                    </div>
                                                </div>

                                            )
                                        })}
                                    </div>


         
                                    <ul className="list-group list-group-flush mt-4">
                                        {experienceData?.includesData && experienceData?.includesData.map((item, index) => {
                                            return (
                                                item?.description &&
                                                <li
                                                    className="list-group-item d-flex align-items-center text-primary"
                                                >
                                                    <VerifiedIcon />
                                                    <p className="m-0">
                                                        {item?.description}
                                                    </p>
                                                </li>
                                            )
                                        })}

                                       
                                    </ul>
                                </div>

                                <div className="card p-4 mt-5 br16">
                                    <ul className="nav nav-tabs itnery-tab flex-nowrap overflow-x-scroll w-100">
                                        <li className={`nav-item ${activeTab === 'Itenery' && 'active'}`}>
                                        <a className="nav-link" onClick={() => setActiveTab('Itenery')}> Itenery {experienceData?.durationName === 'Short' && 'Short'}{experienceData?.durationName === 'Day-long' && 'DAY LONG'}{experienceData?.durationName === 'Multi-day' && `${experienceData?.expItinerary && experienceData?.expItinerary.length === 1 ? 0 : experienceData?.expItinerary.length - 1}N${experienceData?.expItinerary && experienceData?.expItinerary.length}D`}</a>
                                        </li>
                                        {experienceData?.transportGreetingData && experienceData?.transportGreetingData.length > 0 && <li className={`nav-item ${activeTab === 'Transfers' && 'active'}`}>
                                            <a className="nav-link" onClick={() => setActiveTab('Transfers')}>Transfers</a>
                                        </li>
                                        }
                                        {experienceData?.sleepData && experienceData?.sleepData.length > 0 && <li className={`nav-item ${activeTab === 'Sleep' && 'active'}`} >
                                            <a className="nav-link" onClick={() => setActiveTab('Sleep')}>Sleep</a>
                                        </li>
                                        }
                                        {experienceData?.foodData && experienceData?.foodData.length > 0 && <li className={`nav-item ${activeTab === 'Food' && 'active'}`} >
                                            <a className="nav-link" onClick={() => setActiveTab('Food')}>Food</a>
                                        </li>
                                        }
                                        {experienceData?.activityDayData && experienceData?.activityDayData.length > 0 && <li className={`nav-item ${activeTab === 'Activity' && 'active'}`} >
                                            <a className="nav-link" onClick={() => setActiveTab('Activity')}>Activity</a>
                                        </li>}
                                    </ul>
                                    <div className="tab-content">
                                        <div className={`tab-pane ${activeTab === 'Itenery' && 'active'}`} id="intenery">
                                            {experienceData?.expItinerary ?
                                                experienceData?.expItinerary.map((item, index) => {
                                                    return (
                                                        <>
                                                            <h4 className="ite-date">
                                                                {/* <small>Day</small> {item.day} <small>Monday | 31 Dec, 2024</small> */}
                                                                {/* <small>Day</small> {item.day} <small></small> */}
                                                                <small>Day</small> {item.day} <small>{getDayName(experienceData?.startDate, item.day).dayName} | {getDayName(experienceData?.startDate, item.day).date}</small>
                                                            </h4>

                                                            <div className='tod-block d-flex'>
                                                                <div className='position-relative'></div>
                                                                <div className='ite-details position-relative'>
                                                                <PinDropIcon />Meet & Greet at {item.data[0].meetGreetPlace}
                                                                </div>
                                                            </div>

                                                            {experienceData?.greetingsData.map((i, index) => {
                                                                return (
                                                                    Number(i.day) === item.day && <>
                                                                        {i.greeting === 'Morning' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness5OutlinedIcon className='text-danger' />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Morning' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>

                                                                                                {i.type === 'Transport' &&
                                                                                                    <p className="ms-4">
                                                                                                        <small> --- {i.distance}KM by {i.mode}</small>
                                                                                                    </p>
                                                                                                }

                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {i.greeting === 'Afternoon' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <BrightnessMediumOutlinedIcon className="text-warning" />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Afternoon' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>

                                                                                                {i.type === 'Transport' &&
                                                                                                    <p className="ms-4">
                                                                                                        <small> --- {i.distance}KM by {i.mode}</small>
                                                                                                    </p>
                                                                                                }

                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {i.greeting === 'Evening' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness3Icon className='text-info' />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Evening' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>

                                                                                                {i.type === 'Transport' &&
                                                                                                    <p className="ms-4">
                                                                                                        <small> --- {i.distance}KM by {i.mode}</small>
                                                                                                    </p>
                                                                                                }

                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })}

                                                            {item.data.map((i, index) => {
                                                                return (
                                                                    i.type === 'Sleep' && <h4 className="ite-sleep">
                                                                        <LocalHotelIcon />
                                                                        Overnight @ {i.place} - {i?.propertyName}
                                                                    </h4>
                                                                )
                                                            })}

                                                        </>
                                                    )
                                                }) : ''
                                            }

                                        </div >
                                        <div className={`tab-pane ${activeTab === 'Transfers' && 'active'}`} id="transfer">
                                            {experienceData?.expItinerary ?
                                                experienceData?.expItinerary.map((item, index) => {
                                                    return (
                                                        <>
                                                            <h4 className="ite-date">
                                                                {/* <small>Day</small> {item.day} */}
                                                                <small>Day</small> {item.day} <small>{getDayName(experienceData?.startDate, item.day).dayName} | {getDayName(experienceData?.startDate, item.day).date}</small>
                                                            </h4>

                                                            {experienceData?.transportGreetingData.map((i, index) => {
                                                                return (
                                                                    Number(i.day) === item.day && <>
                                                                        {i.greeting === 'Morning' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness5OutlinedIcon className='text-danger' />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Morning' && i.type === 'Transport' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>

                                                                                                <p>
                                                                                                    <PinDropIcon />{i.meetGreetPlace}
                                                                                                </p>
                                                                                                <p className="ms-4">
                                                                                                    <small> --- {i.distance}KM | ~ {i.durationInHrs}HRS</small>
                                                                                                </p>
                                                                                                <h4 className="ite-sleep">
                                                                                                    <LocalTaxiIcon />
                                                                                                    {i.mode}
                                                                                                </h4>
                                                                                                <p className="mt-4">
                                                                                                    <PinDropIcon />{i.destination}
                                                                                                </p>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {i.greeting === 'Afternoon' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <BrightnessMediumOutlinedIcon className="text-warning" />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Afternoon' && i.type === 'Transport' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>
                                                                                                <p>
                                                                                                    <PinDropIcon />{i.meetGreetPlace}
                                                                                                </p>
                                                                                                <p className="ms-4">
                                                                                                    <small> --- {i.distance}KM | ~ {i.durationInHrs}HRS</small>
                                                                                                </p>
                                                                                                <h4 className="ite-sleep">
                                                                                                    <LocalTaxiIcon />
                                                                                                    {i.mode}
                                                                                                </h4>
                                                                                                <p className="mt-4">
                                                                                                    <PinDropIcon />{i.destination}
                                                                                                </p>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {i.greeting === 'Evening' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness3Icon className='text-info' />
                                                                                        {i.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Evening' && i.type === 'Transport' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>
                                                                                                <p>
                                                                                                    <PinDropIcon />{i.meetGreetPlace}
                                                                                                </p>
                                                                                                <p className="ms-4">
                                                                                                    <small> --- {i.distance}KM | ~ {i.durationInHrs}HRS</small>
                                                                                                </p>
                                                                                                <h4 className="ite-sleep">
                                                                                                    <LocalTaxiIcon />
                                                                                                    {i.mode}
                                                                                                </h4>
                                                                                                <p className="mt-4">
                                                                                                    <PinDropIcon />{i.destination}
                                                                                                </p>
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })}

                                                        </>
                                                    )
                                                }) : ''
                                            }

                                        </div>
                                        <div className={`tab-pane ${activeTab === 'Sleep' && 'active'}`} id="sleep">
                                            {experienceData?.expItinerary ?
                                                experienceData?.expItinerary.map((item, index) => {
                                                    return (
                                                        <>
                                                            <h4 className="ite-date">
                                                                {/* <small>Day</small> {item.day} <small>Monday | 31 Dec, 2024</small> */}
                                                                {/* <small>Day</small> {item.day} <small></small> */}
                                                                <small>Day</small> {item.day} <small>{getDayName(experienceData?.startDate, item.day).dayName} | {getDayName(experienceData?.startDate, item.day).date}</small>
                                                            </h4>

                                                            {item.data.map((i, index) => {
                                                                return (
                                                                    i.type === 'Sleep' && <>
                                                                        <div className="card experience-card text-center">
                                                                            <figure>
                                                                                <img className='text-center mt-3' src={`${BASE_URL}/${i.sleepImg}`} />
                                                                            </figure>
                                                                            <div className="d-flex align-middle property-details">
                                                                                <div>
                                                                                    {/* <h6 className="fw-bold">Dome tent at Riveside Camp - yelbong</h6> */}
                                                                                    <h6 className="fw-bold">{i.place} at {i?.propertyName}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })}
                                                        </>
                                                    )
                                                }) : ''
                                            }

                                        </div>
                                        <div className={`tab-pane ${activeTab === 'Food' && 'active'}`} id="food">
                                            <div className="d-flex gap-2 mt-5 rounded border p-4">
                                                {experienceData?.expItinerary ?
                                                    experienceData?.expItinerary.map((item, index) => {
                                                        return (
                                                            <>

                                                                {item.data.map((i, index) => {
                                                                    return (
                                                                        i.type === 'Food' &&
                                                                        <>
                                                                            {i?.itineraryImg && i?.itineraryImg.map((item, index) => {
                                                                                return (
                                                                                    <>
                                                                                        <figure className="figure">
                                                                                            <img src={item?.imgName ? `${BASE_URL}/${item?.imgName}` : "https://picsum.photos/1024"} className="figure-img img-fluid rounded" alt="..." />
                                                                                        </figure>
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </>
                                                                    )
                                                                })}


                                                            </>
                                                        )
                                                    }) : ''
                                                }

                                                {/* <figure className="figure">
                                                <img src="https://picsum.photos/1024" className="figure-img img-fluid rounded" alt="..." />
                                            </figure>
                                            <figure className="figure">
                                                <img src="https://picsum.photos/1024" className="figure-img img-fluid rounded" alt="..." />
                                            </figure>
                                            <figure className="figure">
                                                <img src="https://picsum.photos/1024" className="figure-img img-fluid rounded" alt="..." />
                                            </figure>
                                            <figure className="figure">
                                                <img src="https://picsum.photos/1024" className="figure-img img-fluid rounded" alt="..." />
                                            </figure> */}
                                            </div>
                                        </div>
                                        <div className={`tab-pane ${activeTab === 'Activity' && 'active'}`} id="activity">
                                            {experienceData?.expItinerary ?
                                                experienceData?.expItinerary.map((item, index) => {
                                                    return (
                                                        experienceData?.activityDayData.length > 0 && <>
                                                            <h4 className="ite-date">
                                                                {/* <small>Day</small> {item.day} */}
                                                                <small>Day</small> {item.day} <small>{getDayName(experienceData?.startDate, item.day).dayName} | {getDayName(experienceData?.startDate, item.day).date}</small>
                                                            </h4>

                                                            {experienceData?.activityDayData.map((j, index) => {
                                                                return (
                                                                    Number(j.day) === item.day && <>
                                                                        {j.greeting === 'Morning' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness5OutlinedIcon className='text-danger' />
                                                                                        {j.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Morning' && i.type === 'Activity' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>


                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {j.greeting === 'Afternoon' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <BrightnessMediumOutlinedIcon className="text-warning" />
                                                                                        {j.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Afternoon' && i.type === 'Activity' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>



                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {j.greeting === 'Evening' &&
                                                                            <div className="tod-block d-flex mt-4">
                                                                                <div className="position-relative">
                                                                                    <p className="tod">
                                                                                        <Brightness3Icon className='text-info' />
                                                                                        {j.greeting}
                                                                                    </p>
                                                                                </div>
                                                                                <div className="ite-details position-relative">
                                                                                    <p>
                                                                                        {/* <PinDropIcon />{item.data[0].meetGreetPlace} */}
                                                                                    </p>
                                                                                    {item.data.map((i, index) => {

                                                                                        return (
                                                                                            i.greeting === 'Evening' && i.type === 'Activity' && <>
                                                                                                {/* <div key={index} className="exp_itinerary_item"> <b>{i.type}</b></div> */}
                                                                                                <p>
                                                                                                    {/* <PinDropIcon />{i.meetGreetPlace} */}
                                                                                                </p>


                                                                                                {i.type != 'Sleep' && <p>
                                                                                                    {i.type === 'Transport' && <PinDropIcon /> || i.type === 'Activity' && <SportsGymnasticsOutlinedIcon /> || i.type === 'Food' && <RestaurantMenuOutlinedIcon />}{i.title}
                                                                                                    <i className="d-block mt-2 ms-4">
                                                                                                        {i.description}
                                                                                                    </i>
                                                                                                </p>}
                                                                                            </>
                                                                                        )
                                                                                    })}

                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            })}

                                                        </>
                                                    )
                                                }) : ''
                                            }

                                        </div>
                                    </div>
                                </div>

                                <div className="card p-4 mt-5 exp_evt">
                                    <h4>What is included</h4>
                                    <ul className="list-group list-group-flush mt-4">
                                        {experienceData?.includesData && experienceData?.includesData.map((i, index) => {
                                            return (
                                                <li className="list-group-item d-flex align-items-center text-primary">
                                                    <DoneAllOutlinedIcon />
                                                    <p className="m-0">
                                                        {i?.description}
                                                    </p>
                                                </li>
                                            )
                                        })}


                                    </ul>
                                    <h4 className="mt-5">What is NOT included</h4>
                                    <ul className="list-group list-group-flush mt-4">
                                        {experienceData?.notIncludeData && experienceData?.notIncludeData.map((i, index) => {
                                            return (
                                                <li className="list-group-item d-flex align-items-center text-primary">
                                                    <WarningOutlinedIcon className='text-danger' />
                                                    <p className="m-0">
                                                        {i?.description}
                                                    </p>
                                                </li>
                                            )
                                        })}



                                    </ul>
                                </div>

                                <div className="card p-4 mt-5 exp_evt1">
                                    <h4>What to bring</h4>
                                    <section>
                                        <ul className="list-group list-group-flush mt-4">
                                            {experienceData?.carryData && experienceData?.carryData.map((i, index) => {
                                                return (
                                                    <li className="list-group-item d-flex align-items-center text-primary">
                                                        <DoneAllOutlinedIcon />
                                                        <p className="m-0">
                                                            {i?.carryName}
                                                        </p>
                                                    </li>
                                                )
                                            })}


                                        </ul>
                                    </section>
                                </div>

                                <div className="mt-5">
                                    <h4>how to reach Pick-up-spot</h4>
                                    <ul className="list-group list-group-flush mt-4">
                                        <li
                                            className="list-group-item d-flex align-items-center text-primary bg-transparent"
                                        >
                                            <LocalAirportOutlinedIcon />
                                            <p className="m-0">
                                            {experienceData?.airport}
                                            </p>
                                        </li>
                                        <li
                                            className="list-group-item d-flex align-items-center text-primary bg-transparent"
                                        >
                                            <TramIcon />
                                            <p className="m-0">
                                            {experienceData?.busStation}
                                            </p>
                                        </li>
                                        <li
                                            className="list-group-item d-flex align-items-center text-primary bg-transparent"
                                        >
                                            <TrainIcon />
                                            <p className="m-0">
                                            {experienceData?.railwayStation}
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                {/* <div className="guest_card_box sticky-top d-md-block d-none"> */}
                                <div className={`guest_card_box sticky-top ${bookingPopup === true && 'active'}`}>
                                    <div className="guest_card_inner">
                                        <div className="guest_card_top d-flex justify-content-between align-items-center">
                                            <div className="gct_left">
                                                <p class="ribbon">
                                                    <span class="text"><strong class="fw-bold"><span><CurrencyRupeeOutlinedIcon /></span>{experienceData?.priceRegular}/-</strong><small class="ribbon-small">{experienceData?.perPerson === 1 ? 'per person' : 'per exp'}</small></span>
                                                </p>
                                            </div>
                                            <div className="gct_right">
                                                <h6>min persons: {experienceData?.minPerson}</h6>
                                            </div>
                                        </div>
                                        <div className="guest_card_footer">
                                            <form>
                                                <div className="form-group floatLabel">

                                                    <div class="form-floating mb-3">
                                                        <DatePicker
                                                            selected={startDate}
                                                            // onChange={(date) => { setStartDate(convertDateToYYYYMMDD(date)); console.log('StartDate', convertDateToYYYYMMDD(date)) }}
                                                            onChange={(date) => { setStartDate(convertDateToYYYYMMDD(date)); console.log('StartDate', convertDateToYYYYMMDD(date)) }}
                                                            minDate={new Date()}
                                                            className="form-control cstmInp"
                                                            dateFormat="dd/MM/yyyy"
                                                            disabled

                                                        />
                                                        <label for="checkin">Check in</label>
                                                    </div>

                                                </div>

                                                <div class="d-flex justify-content-evenly mb-3 gap-1">
                                                    <div class="form-floating">
                                                        <input
                                                            type="number"
                                                            class="form-control"
                                                            id="adult"
                                                            name="adult"
                                                            min={experienceData.minPerson}
                                                            onChange={(e) => setAdults(e.target.value)}
                                                            value={adults}
                                                        />
                                                        <label for="adult">Adult ({adults}+)</label>
                                                    </div>

                                                    <div class="form-floating">
                                                        <input
                                                            type="number"
                                                            class="form-control"
                                                            id="young"
                                                            name="young"
                                                            min="0"
                                                            onChange={(e) => setChildren(e.target.value)}
                                                            value={children}
                                                        />
                                                        <label for="young">Young ({children}+)</label>
                                                    </div>
                                                </div>
                                            </form>

                                            <div className="guest_input mt-2">
                                                <button className='btn btn-lg btn-success w-100 text-uppercase' onClick={() => checkExpAvalablity(experienceData)}>Review & Book</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="alert alert-warning mt-3 ms-1 me-1" role="alert">
                                    <strong>4</strong> persons are viewing this page now!
                                </div>

                                <div class="mobile-bottom-wrapper d-none">
                                    <div class="m-auto d-flex justify-content-around mobile-bottom">
                                        <h6 class="flex-grow-1 m-auto text-danger">
                                            <CurrencyRupeeOutlinedIcon />{experienceData?.priceRegular}/-<small>{experienceData?.perPerson === true ? 'per person' : 'per event'} </small>
                                        </h6>
                                        <button class="btn btn-success btn-sm ms-5" onClick={onClickBookingBtn}>BOOK NOW</button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="container">
                            <h5 className="mt-4">hosted by</h5>
                            <small className="text-light">hosts of {selectedPropertyDetail?.propertyName || experienceData?.experienceName}</small >
                            <HostedBy selectedPropertyDetail={experienceData} />
                        </div>
                    </div>
                </section>
                <section className="pdp06">
                    <div className="container">


                        <div className="row mt-lg-5 mt-4">
                            <div className="col-md-6 col-12">
                                <div className="campus_rules_card">
                                    <h5> <img src={campRules} /> Camp rules</h5>
                                    <div className="d-flex justify-content-between align-items-center gap-2">
                                        <p>For a 50% refund of the nightly rate, the guest must cancel 30 full days before the listing’s local check-in time (shown in the confirmation email).</p>

                                        {/* <p>{selectedPropertyDetail?.campRules && selectedPropertyDetail?.campRules?.rule.join(", ")}</p> */}
                                        <button className="btn-campusRules"> <img src={arrowWhite} /> </button>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-12">
                                <div className="campus_rules_card campus_rules_card1">
                                    <h5> <img src={campRules1} /> Cancellation policy - {experienceData.cancellationPolicy?.policyType === 1 && 'Esay'} {experienceData.cancellationPolicy?.policyType === 2 && 'Strict'} {experienceData.cancellationPolicy?.policyType === 3 && 'Moderate'} {experienceData.cancellationPolicy?.policyType === 4 && 'Custome'}</h5>
                                    {/* 1-Esay,2-Strict,3- Moderate, 4 - Custome */}
                                    <div className="d-flex justify-content-between align-items-center gap-2">
                                        {/* <p>For a 50% refund of the nightly rate, the guest must cancel 30 full days before the listing’s local check-in time (shown in the confirmation email).</p> */}
                                        <p>{experienceData?.cancellationPolicy && experienceData?.cancellationPolicy?.policy.join(", ")}</p>
                                        <button className="btn-campusRules"> <img src={arrowWhite} /> </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <Features /> */}
            </main>



            <OffcanvasModal toggle={toggleCanvas} isOpen={isOpenCanvas} images={experienceData?.expImg} title={experienceData?.experienceName} />
        </>

    )

}


EventDetail.propTypes = {
    direction: PropTypes.string,
};

export default EventDetail;