

import card01 from '../../assets/images/card01.jpg'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from "react";
import authServices from '../../services/auth.services'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../services/config'
import { calculateDayCount, calculateHoursStartTimeAndEndTime } from '../../helpers/Helper';
const ExperiencesData = ({ data }) => {
  const [experienceData, setExperienceData] = useState([])
  const [ativeTerrian, setActiveTerrian] = useState('')
  const navigate = useNavigate();
  const getExperienceData = async () => {
    if(data==='HOME'){
      const result = await authServices.expListForHomePage()
      if (result.status === 200) {
        let resData = result.data.data
        setExperienceData(resData)
      }
    }else{
      const result = await authServices.experienceList()
      if (result.status === 200) {
        let resData = result.data.data
        setExperienceData(resData)
      }
    }
   
  }
  const onClickTerrian = (value) => {
    setActiveTerrian(value)
  }
  const onClickExp = (id) => {
    navigate(`/experiences/booking/${id}`)
  }
  useEffect(() => {
    getExperienceData()
  }, [])
  return (

    <section className="section04">
      {/* <Swiper
                slidesPerView='auto'
                spaceBetween={10}
                loop='true'
                navigation={{
                  clickable: true,
                }}
                breakpoints={{
                  640: {
                    slidesPerView: 'auto',
                    spaceBetween: 16,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 16,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 16,
                  },
                }}
                modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                className="mySwiper02"
              >
                     {terrianList.map((item, index)=>{
                    return (
                      <SwiperSlide key={index}>
                      <button className={`btn-tab-tracking ${item?.terrainName === ativeTerrian && 'active' }`} onClick={()=>onClickTerrian(item?.terrainName)}>
                        <div className="d-flex align-items-center gap-1">
                          <img src={ic01} alt="icons" />
                          <h5 className="m-0">{item?.terrainName}</h5>
                        </div>
                      </button>
                    </SwiperSlide>
                    )
                  })} */}
      {/* <SwiperSlide>
                  <button className="btn-tab-tracking active">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic01} alt="icons" />
                      <h5 className="m-0">Trending</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic02} alt="icons" />
                      <h5 className="m-0">Beach</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic03} alt="icons" />
                      <h5 className="m-0">Mountains</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic04} alt="icons" />
                      <h5 className="m-0">Forest</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">River</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Plateu</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Plateu</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide>

                <SwiperSlide>
                  <button className="btn-tab-tracking">
                    <div className="d-flex align-items-center gap-1">
                      <img src={ic05} alt="icons" />
                      <h5 className="m-0">Farm</h5>
                    </div>
                  </button>
                </SwiperSlide> */}
      {/* </Swiper> */}


      <div className="row row-cols-lg-4 row-cols-md-3 row-cols-2">

        {experienceData.map((item, index) => {
          return (
            <>
              <div className="col mb-4" key={index}>
                <div className="experience_card card" onClick={() => onClickExp(item?._id)}>
                  <div className="experience_card_image position-relative ">
                    <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || card01}`} className="img-fluid experienceImg01" alt="" />

                    <div class="experience_card_det d-flex justify-content-evenly">
                      <p class="ribbon flex-grow-1">
                        <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular || 'Free'}</span>
                      </p>
                      {/* <small class="badge bg-dark align-self-end me-3 duration">{calculateDayCount(item.startDate, item.endDate)}N{calculateDayCount(item.startDate, item.endDate)+1}D</small> */}
                      <small class="badge bg-dark align-self-end me-3 duration">{item?.durationName === 'Multi-day' ? `${calculateDayCount(item.startDate, item.endDate)}N${calculateDayCount(item.startDate, item.endDate)+1}D`: `${calculateHoursStartTimeAndEndTime(item?.startTime, item?.endTime)} H`}</small>
                    </div>
                    {/* <div className="experience_card_image_overlay">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="price_tag">₹ {item?.priceRegular} - {item?.priceWeekend}</div>
                              </div>
                            </div> */}
                  </div>

                  <div className="experience_card_content">
                    <div className="d-flex justify-content-between flex-1">
                      <div>
                        <h4>{item?.experienceName}</h4>
                        <small>{item?.location?.city}, {item?.location?.state}</small>
                     
                        {/* <p className="m-0">{item?.experienceTypeName}</p> */}
                      </div>
                      {/* <h5> <img src={ratingStar} alt="rating star" />  4.8</h5> */}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}

      </div>
    </section>
  )
}

export default ExperiencesData;