import SafetyAtFurutCamps from "../components/SafetyAtFurutCamps";
import leftImg from '../../src/assets/img/right.png'
import rightImg from '../../src/assets/img/left.png'
import cardImg from '../../src/assets/img/card.png'
import aiIcon from '../../src/assets/images/App icon.png'
import experienceImg from '../../src/assets/images/card-image.jpg'
import rating from '../../src/assets/images/star-rating.png'
import duration from '../../src/assets/images/clock.png'
import calender from '../../src/assets/images/date.png'
import tabImg from '../../src/assets/images/tab-btn-image.png'
import posterImg1 from '../../src/assets/images/camping01.jpg'
import posterImg2 from '../../src/assets/images/camping02.jpg'
import arrowImg from '../../src/assets/images/arrow.png'

import filterIc from '../../src/assets/img/filter-ic.png'
import searchIc from '../../src/assets/img/search-ic.png'
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
// section04 image/icon import 


import ratingStar from '../../src/assets/images/Rating icon.png'
import card01 from '../../src/assets/images/card01.jpg'
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';

// SECTION06 IMAGES/ICONS 

import activitiesImg1 from '../../src/assets/images/activities01.jpg'
import activitiesImg2 from '../../src/assets/images/activities02.jpg'
import activitiesImg3 from '../../src/assets/images/activities03.jpg'
import activitiesImg4 from '../../src/assets/images/activities04.jpg'
import activitiesImg5 from '../../src/assets/images/activities05.jpg'
import activitiesImg6 from '../../src/assets/images/activities06.jpg'

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import SearchModal from "../components/search/SearchModal";
import SearchResult from "../components/search/SearchResult";
import HappeningExperiences from "../components/Experience/HappeningExperiences";
import authServices from "../services/auth.services";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../services/config";
import Features from "../components/Comman/Features";
import ExperiencesData from "../components/Experience/ExperiencesData";
import Terrian from "../components/Comman/Terrian";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import forestImg from "../../src/assets/img/forest.png";
import treeIc from "../../src/assets/img/tree-ic.png";
import NearBy from "../components/Comman/NearBy";
import campingInIndianStates from '../components/Comman/campingInIndianStates.json'
import HeroSec from "../components/Comman/HeroSection";
import { getLoggedData } from "../helpers/Helper";
import StayTypes from "../components/Comman/StayTypes";
import ExpTypes from "../components/Comman/ExpTypes";

const Home = () => {
  const [modal, setModal] = useState(false);
  const [searchResult, setSearchResult] = useState([])
  const [isSearch, setIsSearch] = useState(false)
  const [propertyList, setPropertyList] = useState([])
  const [terrianList, setTerrianList] = useState([])
  const [terrianId, setTerrianId] = useState('all')
  const [uniqueStays, setUniqueStays] = useState([])
  const [events, setEvents] = useState('HOME')
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();

  const onClickProperty = (data) => {
    navigate(`/property-detail/${data._id}`)
  };

  const onClickStay = (value) => {
    console.log("value", value);
    navigate(`/stay-detail/${value._id}`)
  };

  const onClickTerrian = (value) => {
    setTerrianId(value);
    propertyListByTerrian(value)
  }
  const onClicStayTypes = (value) => {
    console.log(value)
    uniqueStayList(value)
  }
  const onClickExpTypeBadge = (value) =>{
    console.log('value', value)
  }
  const propertyListByTerrian = (terrianId) => {
    authServices.propertyByTerrianId({ terrianId: terrianId }).then((result) => {
      if (result.status === 200) {
        setPropertyList(result.data.data)
      }
    })
  }
  const uniqueStayList = (stayTypeId) => {
    authServices.uniqueStayListApi({ stayTypeId: stayTypeId }).then((result) => {
      if (result.status === 200) {
        console.log(result.data.data)
        setUniqueStays(result.data.data)
      }
    })
  }

  useEffect(() => {
    authServices.propertyListApi().then((result) => {
      if (result.status === 200) {
        setPropertyList(result.data.data)
      }
    })
    uniqueStayList('all')
    // propertyListByTerrian()
    authServices.terrianListApi().then((result) => {
      if (result.status === 200) {
        console.log(result.data.data)
        setTerrianList(result.data.data)
      }
    })
  
      

  }, [])
  return (
    <>
      {isSearch === false ? <>
        <HeroSec />

        <div className="search-bar">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-12">
                <button className="filter-btn w-100" onClick={toggle}>
                  <div className="d-flex justify-content-between align-items-center gap-2">
                    <div className="search_sec d-flex align-items-center gap-2">
                      <img src={searchIc} alt="" className="d-md-block d-none" />
                      <LocationSearchingOutlinedIcon className="d-md-none d-block" />
                      <div className="text-start">
                        <h5>Let’s explore?</h5>
                        <p>destinations all over india</p>
                      </div>
                    </div>
                    <div className="filter_box d-md-flex d-none">
                      <img src={filterIc} alt="" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ------------------------------------------------------------------------------------------------ SECOND SECTION START  */}

        <HappeningExperiences data={events} />

        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}

        <section className="section03">
          <div className="container">
            <NearBy  title='wander around'/>
          </div>
        </section>

        {/* ------------------------------------------------------------------------------------------------ FOURTH SECTION START  */}

        <section className="section04">
          <div className="container">
            <div className="row">
              <div className="col-12 text-start">
                <div className="mt-4 text-light mb-0 h4 fw-semibold">explore properties
                </div>
                <div className="mb-1 text-light small">find out unique properties all over India
                </div>
              </div>
              
              <Terrian onClickTerrianBadge={onClickTerrian} />

              <div className="col-12">
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                  {propertyList.map((item, index) => {
                    return (
                      <>
                        <div className="col mb-4" key={index} onClick={() => onClickProperty(item)}>
                          <div className="experience_card card">
                            <div className="experience_card_image position-relative ">
                              <img src={item?.propertyImage ? `${BASE_URL}/${item?.propertyImage}` : card01} className="img-fluid experienceImg01" alt="" />

                              <div className="experience_card_det d-flex justify-content-evenly">
                                <p className="ribbon flex-grow-1">
                                  <span className="text"> <strong><CurrencyRupeeIcon /></strong> {item?.lowestPrice} - {item?.highestPrice}</span>
                                </p>
                              </div>
                            </div>

                            <div className="experience_card_content">
                              <div className="d-flex align-middle property-details">
                                <div>
                                  <h4 className="p-0">{item.propertyName}</h4>
                                  <p className="m-0">{item?.propertyLocated?.city}, {item?.propertyLocated?.state}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}

                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ------------------------------------------------------------------------------------------------ FIFTH SECTION START  */}

        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}

        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}

        <section id="experiences" className="section07">
          <div className="container">
            <div className="row">
              <div className="col-12 text-start">
                <div className="text-light mt-5 h5 fw-semibold">experiences</div>
                <div className="subtitle">activities & experiences worth having

                </div>
              </div>

              <ExpTypes onClickExpTypeBadge={onClickExpTypeBadge} />

              <ExperiencesData data={'HOME'} />
            </div>
          </div>
          {/* </div> */}
        </section>

        <section className="section08">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 text-start">
                <div className="mt-4 text-light mb-0 h5 fw-semibold">explore unique stays
                </div>
                <div className="subtitle">be-spoke stays all over India
                </div>
              </div>
              <StayTypes onClicStayTypes={onClicStayTypes} />
            </div>
              <div className="row row-cols-xl-4 row-cols-lg-4 row-cols-md-2 row-cols-1">
                {uniqueStays.map((item, index) => {
                  return (
                    <>
                      <div className="col" onClick={() => onClickStay(item)}>
                        <div className="stays_card_wrapper">
                          <div className="stays_card_image">
                            <img src={item?.stayImg ? `${BASE_URL}/${item?.stayImg}` : forestImg} alt="" className="img-fluid w-100 br16"/>
                          </div>
                          <div className="stays_card_content">
                            <div className="scc_top d-flex justify-content-start align-items-center gap-lg-3">
                              <div className="scc_top_left">

                                <img src={item?.stayTypeData?.stayImg ? `${BASE_URL}/${item?.stayTypeData?.stayImg}` : treeIc} alt="" />
                              </div>
                              <div className="scc_top_right">
                                <h6>{item?.stayType}</h6>
                                <p className="m-0"> <CurrencyRupeeIcon />
                                  {item?.priceRegular || 0} <span>/ night</span>
                                </p>
                              </div>
                            </div>
                            <p>{item?.propertyName || ''} | <small> {item?.propertyLocated?.city && `${item?.propertyLocated?.city || ''}, ${item?.propertyLocated?.state || ''}`}</small></p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>

          </div>
        </section>
        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}

        {/* <Features /> */}

        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}



        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}



        {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}



        {/* ------------------------------------------------------------------------------------------------ ELEVEN SECTION START  */}


      </>
        :
        <SearchResult searchResult={searchResult} toggle={toggle} />
      }
      <Modal className="search-modal" isOpen={modal} toggle={toggle} size="xl" centered={true}>
        {/* <Logi toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} language={language} getUsers={getUsers} /> */}
        <SearchModal toggle={toggle} setSearchResult={setSearchResult} setIsSearch={setIsSearch} />
      </Modal>
    </>
  )
}

export default Home;