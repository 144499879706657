import { Swiper, SwiperSlide } from 'swiper/react';
import TeamMemberPhoto from '../assets/img/profile-placeholder.png'
import leagueBg from '../assets/img/league-bg.svg'
import JoincardImg1 from '../assets/img/explorer-card.jpg'
import JoincardImg2 from '../assets/img/host-card.jpg'
import AboutJoin from '../assets/img/about-join.jpg'
import AboutValue from '../assets/img/about-value-img.avif'
import PanToolIcon from '@mui/icons-material/PanTool';

import 'swiper/css';
// import 'swiper/css/pagination';

// import './styles.css';

const AboutUs = () => {
    return (
        <>
            <main className="about-page">
                <div className="hero-about">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 class="display-lg-5 display-6 text-center">
                                    furutcamps foster a sense of <strong>community</strong> in order to
                                    build a <strong>positive</strong>, <strong>natural</strong>, &
                                    <strong>collaborative</strong> environment through
                                    <strong>camping</strong>, <strong>experiential learning</strong>,
                                    <strong>leisure activities</strong>, & active participation in
                                    <strong>sustainable</strong>, <strong>zero-waste</strong> living.
                                </h1>
                                <div className="text-center pt-5">
                                    <a
                                        type="button"
                                        class="btn btn-primary btn-rounded btn-lg"
                                        role="button"
                                        href="#join"
                                    >JOIN US</a
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about01">
                    <div className="container">
                        <div className="row culture-about justify-content-center gx-5">
                            <div className="col-lg-4">
                                <div className="culture-about-img"></div>
                            </div>
                            <div className="col-lg-4 ps-5">
                                <h2 class="h2 mt-5 mt-lg-1">
                                    <small class="text-light">our</small> culture
                                </h2>
                                <p class="h4 mt-5">
                                    We, the team at furutcamps, strive to awaken our inner explorer in
                                    order to comfort our adventurous souls and quench the wanderlust
                                    spirit within us.
                                </p>
                                <p class="mt-5">
                                    In the midst of nature, we feel at our best. The enormous mountain
                                    reminds us that the best comes after the most difficult trek, while
                                    the glorious blue sky encourages optimism and hope.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about02">
                    <div className="container">
                        <div className="row values-about justify-content-center gx-lg-5">
                            <div
                                class="col-12 col-lg-8 d-flex justify-content-md-center justify-content-around flex-wrap flex-lg-nowrap"
                            >
                                <h2 class="h2 align-self-center me-lg-5">
                                    <small class="text-light">our</small> values
                                </h2>
                                <div className="culture-value-img"><img src={AboutValue} className='img-fluid'/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about03">
                    <div className="container">
                        <div className="row justify-content-center gx-5 mt-5 values">
                            <div className="col-lg-10 col-12">
                                <section class="row">
                                    <ul class="nav nav-tabs flex-column col-6 border-0">
                                        <li class="nav-item active">
                                            <a class="nav-link" href="#tab-1">five-finger spell</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#tab-2">we are humans</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#tab-3">we work together</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#tab-4">we change</a>
                                        </li>
                                    </ul>
                                    <div className="tab-content col-6">
                                        <div id="tab-1" class="tab-pane active">
                                            <h5 class="mt-2">
                                                <PanToolIcon />useful five-finger spell
                                            </h5>
                                            <ul class="list-group value-list mt-2">
                                                <li class="list-group-item value-item">
                                                    causing no harm to nature & our environment.
                                                </li>
                                                <li class="list-group-item value-item">
                                                    taking care of each other.
                                                </li>
                                                <li class="list-group-item value-item">
                                                    collaborate & produce meaningful, productive work.
                                                </li>
                                                <li class="list-group-item value-item">
                                                    Practice sustainability & zero waste.
                                                </li>
                                                <li class="list-group-item value-item">
                                                    Speak up for the good.
                                                </li>
                                            </ul>
                                        </div>
                                        <div id="tab-2" class="tab-pane">
                                            <h5 class="mt-2 ps-4 pb-4">be a human first</h5>
                                            <p class="ps-4">
                                                We act like humans, we talk like humans, and we think like
                                                humans, most essentially respect humanity. And we call out
                                                anyone who doesn’t.
                                            </p>
                                        </div>
                                        <div id="tab-3" class="tab-pane">
                                            <h5 class="mt-2 ps-4 pb-4">show but dont tell</h5>
                                            <p class="ps-4">
                                                Collaboration is the key to success and so we love to work
                                                together. No rockstars. No departments. The whole team owns
                                                the whole thing, together.
                                            </p>
                                        </div>

                                        <div id="tab-4" class="tab-pane">
                                            <h5 class="mt-2 ps-4 pb-4">get out of your comfort-zone</h5>
                                            <p class="ps-4">
                                                Nothing is sacred. From our habits to our rituals to our
                                                environment. Change is a natural part of human life, and we
                                                prefer to embrace it.
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about04">
                    <div className="container">
                        <div className="teamMember">
                            <Swiper
                                slidesPerView={5}
                                spaceBetween={5}
                                loop={true}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 2,
                                    },
                                    640: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                    },
                                    1304: {
                                        slidesPerView: 5,
                                    },
                                }}
                                className="mySwiper teamMemberSwiper"
                            >
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Surajit</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Amrita</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Sourav</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Arijit</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Dipaak</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Surajit</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="team-member">
                                        <div className="team-member-img">
                                            <img
                                                src={TeamMemberPhoto} className='img-fluid'
                                                alt="team member name"
                                            />
                                        </div>
                                        <div className="team-member-caption">
                                            <h6>Amrita</h6>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>





                        </div>

                        <div className="row mt-5">
                            <div className="col-12 text-center">
                                <img src={leagueBg} className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about05">
                    <div className="container">
                        <div class="row justify-content-center">
                            <div class="col-10 col-lg-4">
                                <h4>we do great things</h4>
                                <p class="display-3 fw-bolder">together.</p>
                            </div>
                            <div class="col-10 col-lg-4">
                                <p>
                                    We are expanding and looking for individuals that find inspiration
                                    in questioning the status quo. We'd love to have you join us if you
                                    want to help change the way outdoor tourism works.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about07">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10 col-lg-8">
                                <img src={AboutJoin} className='img-fluid w-100' />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about06" id="join">
                    <div className="container">
                        <div class="row justify-content-center" >
                            <div class="col-10 col-lg-8">
                                <figure></figure>
                                <h2 class="text-center mb-5">join us</h2>
                            </div>
                            <div class="row justify-content-center">

                                <div className='col-12 col-lg-8'>
                                    <div className='row justify-content-center'>
                                        <div class="col-12 col-md-6 mb-md-0 mb-4">
                                            <div class="card join-card">
                                                <img src={JoincardImg1} className='img-fluid' />
                                                <div class="mt-4 ms-4 me-4 mb-4">
                                                    <h4 class="fw-medium m-0">explorer</h4>
                                                    <small>anywhere, India</small>
                                                </div>
                                                <div class="mt-0 ms-4 me-4 mb-4">
                                                    <p class="small">
                                                        If you enjoy going outside and want to discover new locations,
                                                        you can consider becoming a furut-explorer. Join the awesome
                                                        outdoor enthusiast community.
                                                    </p>
                                                </div>
                                                <a href="#" class="btn btn-outline-primary w-100"> Join now</a>
                                            </div>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <div class="card join-card">
                                                <img src={JoincardImg2} className='img-fluid' />
                                                <div class="mt-4 ms-4 me-4 mb-4">
                                                    <h4 class="fw-medium m-0">hosts</h4>
                                                    <small>anywhere, India</small>
                                                </div>
                                                <div class="mt-0 ms-4 me-4 mb-4">
                                                    <p class="small">
                                                        furutcamps makes it simple and secure to host campers. You're
                                                        in full control of your availability, prices, camp rules, and
                                                        how you interact with guests.
                                                    </p>
                                                </div>
                                                <a href="#" class="btn btn-outline-primary w-100"> Join now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AboutUs;