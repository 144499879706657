
import experienceImg from '../../assets/images/card-image.jpg'
import rating from '../../assets/images/star-rating.png'
import duration from '../../assets/images/clock.png'
import calender from '../../assets/images/date.png'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

//SECTION09 IMAGE/ICON IMPORT

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from 'react';
import authServices from '../../services/auth.services';
import { calculateDayCount, calculateHoursStartTimeAndEndTime, formattedDateDDMMMYYYY } from '../../helpers/Helper';
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../services/config';
const HappeningExperiences = ({data}) => {

  const [events, setEvents] = useState([])

  const navigate = useNavigate();

  const onClickEvent = (id) => {
    navigate(`/events/${id}`)
  }
  useEffect(() => {
    if(data==='HOME'){
      authServices.eventListForHomePage().then((result) => {
        setEvents(result.data.data)
      })
        .catch((error) => {
          console.log('error', error)
        })
    }else{
      authServices.eventList().then((result) => {
        setEvents(result.data.data)
      })
        .catch((error) => {
          console.log('error', error)
        })
    }
    
  }, [])
  return (
    <section className="experiences" id='events'>
      <div className="container">
        <div className="row">
          <div className="col-12 text-start">
            <div className="text-light mb-0 mt-5 h4 fw-semibold">happening experience
            </div>
            <div className="subtitle">upcoming events near your
            </div>
          </div>
        </div>

        <div className='row row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4'>
          {events.length> 0 && events.map((item, index) => {
            return (
              <>
                <div className='col mb-4' onClick={() => onClickEvent(item._id)}>

                  <div className="experience_card card">
                    <div className="experience_card_image position-relative ">
                      <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || experienceImg}`} className="img-fluid experienceImg01" alt="" />
                      <div class="experience_card_det d-flex justify-content-evenly">
                        <p class="ribbon flex-grow-1">
                          <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular || 'Free'}</span>
                        </p>
                        {/* <small class="badge bg-dark align-self-end me-3 duration">{calculateDayCount(item.startDate, item.endDate)}N{calculateDayCount(item.startDate, item.endDate)+1}D</small> */}
                        <small class="badge bg-dark align-self-end me-3 duration">{item?.durationName === 'Multi-day' ? `${calculateDayCount(item.startDate, item.endDate)}N${calculateDayCount(item.startDate, item.endDate)+1}D`: `${calculateHoursStartTimeAndEndTime(item?.startTime, item?.endTime)} H`}</small>
                      </div>
                    </div>

                    <div className="experience_card_content">
                      <div className="pb-3">
                        <h4 className='mb-0'>{item?.experienceName}</h4>
                        <small>{item?.location?.city}, {item?.location?.state}</small>
                      </div>

                      {/* <div className="dashedBorder"></div> */}

                      

                      <div class="d-flex align-items-center flex-row event-bottom"> {/* Update Classes */}
                        <div class="d-flex flex-row card_det">
                          <CalendarMonthIcon className='me-2' />
                          <small class="bold pe-lg-4 pe-0">{formattedDateDDMMMYYYY(item?.startDate)} To {formattedDateDDMMMYYYY(item?.endDate)}</small>
                        </div>
                        <button class="btn btn-outline-dark btn-sm align-self-baseline" onClick={() => onClickEvent(item._id)}><small>LIMITED SEATS</small></button>
                      </div>

                    </div>
                  </div>

                </div>
              </>
            );
          })}
        </div>

        {/* <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop='true'
          navigation={{
            clickable: true,
          }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              spaceBetween: 8,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 16,
            },
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper01"
        >
          {events.map((item, index) => {
            return (
              <>
                <SwiperSlide key={index}>
                  <div className="experience_card card-shadow">
                    <div className="experience_card_image position-relative ">
                      <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || experienceImg}`} className="img-fluid experienceImg01" alt="" />
                      <div class="experience_card_det d-flex justify-content-evenly">
                        <p class="ribbon flex-grow-1">
                          <span class="text"> <strong><CurrencyRupeeIcon /></strong> 99000</span>
                        </p>
                        <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                      </div>
                    </div>

                    <div className="experience_card_content">
                      <div className="d-flex justify-content-between flex-1">
                        <h4>{item?.experienceName}</h4>
                        <h5> <img src={rating} />  4.8</h5>
                      </div>

                      <div className="dashedBorder"></div>

                      <div className="d-flex justify-content-between flex-1">
                        <div className="list-date">
                          <h6> <img src={calender} /> <span>{formattedDateDDMMMYYYY(item?.startDate)} To {formattedDateDDMMMYYYY(item?.endDate)}</span></h6>
                        </div>

                        <button className="btn btn-transperent" onClick={() => onClickEvent(item._id)}>limited seats</button>
                      </div>

                    </div>
                  </div>
                </SwiperSlide>
              </>
            );
          })} */}



        {/* <SwiperSlide>
              <div className="experience_card card-shadow">
                <div className="experience_card_image position-relative ">
                  <img src={experienceImg} className="img-fluid experienceImg01" alt="" />
                  <div className="experience_card_image_overlay">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="price_tag">₹ 2000 - 4000</div>
                      <div className="stay_duration"> <img src={duration} /> 3 Nights 4 Days</div>
                    </div>
                  </div>
                </div>

                <div className="experience_card_content">
                  <div className="d-flex justify-content-between flex-1">
                    <h4>Hike to mulkarkha lake Via chandratal</h4>
                    <h5> <img src={rating} />  4.8</h5>
                  </div>

                  <div className="dashedBorder"></div>

                  <div className="d-flex justify-content-between flex-1">
                    <div className="list-date">
                      <h6> <img src={calender} /> <span>Fri 8th Apr to Sun 10th Apr</span></h6>
                    </div>

                    <button className="btn btn-transperent">limited seats</button>
                  </div>

                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="experience_card card-shadow">
                <div className="experience_card_image position-relative ">
                  <img src={experienceImg} className="img-fluid experienceImg01" alt="" />
                  <div className="experience_card_image_overlay">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="price_tag">₹ 2000 - 4000</div>
                      <div className="stay_duration"> <img src={duration} /> 3 Nights 4 Days</div>
                    </div>
                  </div>
                </div>

                <div className="experience_card_content">
                  <div className="d-flex justify-content-between flex-1">
                    <h4>Hike to mulkarkha lake Via chandratal</h4>
                    <h5> <img src={rating} />  4.8</h5>
                  </div>

                  <div className="dashedBorder"></div>

                  <div className="d-flex justify-content-between flex-1">
                    <div className="list-date">
                      <h6> <img src={calender} /> <span>Fri 8th Apr to Sun 10th Apr</span></h6>
                    </div>

                    <button className="btn btn-transperent">limited seats</button>
                  </div>

                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="experience_card card-shadow">
                <div className="experience_card_image position-relative ">
                  <img src={experienceImg} className="img-fluid experienceImg01" alt="" />
                  <div className="experience_card_image_overlay">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="price_tag">₹ 2000 - 4000</div>
                      <div className="stay_duration"> <img src={duration} /> 3 Nights 4 Days</div>
                    </div>
                  </div>
                </div>

                <div className="experience_card_content">
                  <div className="d-flex justify-content-between flex-1">
                    <h4>Hike to mulkarkha lake Via chandratal</h4>
                    <h5> <img src={rating} />  4.8</h5>
                  </div>

                  <div className="dashedBorder"></div>

                  <div className="d-flex justify-content-between flex-1">
                    <div className="list-date">
                      <h6> <img src={calender} /> <span>Fri 8th Apr to Sun 10th Apr</span></h6>
                    </div>

                    <button className="btn btn-transperent">limited seats</button>
                  </div>

                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="experience_card card-shadow">
                <div className="experience_card_image position-relative ">
                  <img src={experienceImg} className="img-fluid experienceImg01" alt="" />
                  <div className="experience_card_image_overlay">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="price_tag">₹ 2000 - 4000</div>
                      <div className="stay_duration"> <img src={duration} /> 3 Nights 4 Days</div>
                    </div>
                  </div>
                </div>

                <div className="experience_card_content">
                  <div className="d-flex justify-content-between flex-1">
                    <h4>Hike to mulkarkha lake Via chandratal</h4>
                    <h5> <img src={rating} />  4.8</h5>
                  </div>

                  <div className="dashedBorder"></div>

                  <div className="d-flex justify-content-between flex-1">
                    <div className="list-date">
                      <h6> <img src={calender} /> <span>Fri 8th Apr to Sun 10th Apr</span></h6>
                    </div>

                    <button className="btn btn-transperent">limited seats</button>
                  </div>

                </div>
              </div>
            </SwiperSlide> */}
        {/* </Swiper> */}
      </div>
    </section>
  )
}

export default HappeningExperiences;