import arrowUp from '../../src/assets/img/arrow-long-up.png'
import arrowDown from '../../src/assets/img/arrow-long-down.png'
import timer from '../../src/assets/img/clock-ic-dark.png'
import calenderIc from '../../src/assets/img/calender-ic-dark.png'
import shareIc from '../../src/assets/img/share-ic.png'
import tripCardImg from '../../src/assets/img/trip-ic.png'
import confirmIc from '../../src/assets/img/confirmation-sm-ic.png'
import tripPoster from '../../src/assets/img/trip-card-img.png'
import { useEffect, useState } from 'react'
import authServices from '../services/auth.services'
import { formattedDateDDMMMYYYY, getLoggedData } from '../helpers/Helper'
import { useNavigate } from 'react-router-dom'
import ReactStars from "react-rating-stars-component";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReportIcon from '@mui/icons-material/Report';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ShareIcon from '@mui/icons-material/Share';
import VerifiedIcon from '@mui/icons-material/Verified';

const MyTrip = () => {
    const [upComingTripBooking, setUpComingTripBooking] = useState([])
    const [pastTripBooking, setPastTripBooking] = useState([])
    const [rating, setRating] = useState(0)
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const navigate = useNavigate();
    let loggedUser = getLoggedData()

    const ratingChanged = (newRating) => {
        console.log(newRating);
        setRating(newRating)
    };

    const submitRating = () => {
        alert('submited')
        toggle()
    }
    const onClickBooking = (id) => {
        navigate(`/booking-confirmation/${id}`)

    }
    useEffect(() => {

        authServices.upComingBookingByUserID({ userId: loggedUser?.user?._id }).then((result) => {
            if (result.status === 200) {
                setUpComingTripBooking(result.data.data)
                const today = new Date();
                today.setUTCHours(0, 0, 0, 0);
                if (result.data.data.length > 0) {
                    const filteredData = result.data.data.filter(item => new Date(item.earliestDate) >= today);
                    setUpComingTripBooking(filteredData)

                }
            }
        })

        authServices.pastTripByUserID({ userId: loggedUser?.user?._id }).then((result) => {
            if (result.status === 200) {
                const today = new Date();
                today.setUTCHours(0, 0, 0, 0);
                console.log('pastTrip', result.data.data)
                if (result.data.data.length > 0) {
                    const filteredData = result.data.data.filter(item => new Date(item.earliestDate) < today);

                    setPastTripBooking(filteredData)

                }
            }
        })
    }, [])
    return (
        <>
            <main className="my-trip-page">
                <div className="container">
                    <div className="upcoming-trips-wrapper">
                        <div className="upcoming-trips-head">
                            <div className="upcoming-trips-lft">
                                <h6 className='text-light'>upcoming trips</h6>
                                <hr />
                            </div>

                            {/* <div className="upcoming-trips-rgt d-md-flex d-block align-items-start justify-content-start gap-2 w-100"> */}
                            <div className="upcoming-trips-rgt row g-2 w-100 mt-5">
                                {upComingTripBooking.length > 0 ? upComingTripBooking.map((item, index) => {
                                    return (<>
                                        <div className='utr_trip_card col-lg-4 col-md-6 col-12' key={index} >
                                            <div className='trip_timer'> <HourglassTopIcon /> {item?.remainingDays} days to go</div>

                                            <div className='utr_trip_card_inner d-flex flex-column'>
                                                <div className='utr_card_head d-flex justify-content-between align-items-center'>
                                                    <h6> <DateRangeIcon /> {formattedDateDDMMMYYYY(item?.checkInDate)}</h6>
                                                    <button className='btn btn-share text-success'> <ShareIcon /> SHARE</button>
                                                </div>

                                                <div className='utr_card_center d-flex justify-content-between align-items-center'>
                                                    {/* <h3>5N/6D at Great Himalayan Four Season Outdoor Camp</h3> */}
                                                    <h3> {item?.propertyId === null ? item?.eventBooking?.length > 0 ? item?.eventBooking[0].experienceName : item?.experienceBooking[0].experienceName : item?.propertyName}</h3>
                                                    <img src={tripCardImg} className='img-fluid' />
                                                </div>

                                                <div className='utr_card_footer d-flex justify-content-between align-items-center'>
                                                    {item.status === 0 ? <p className='text-warning'> <ReportIcon /> Pending with host!</p> : <p className='text-success'> <VerifiedIcon /> CONFIRMED</p>}
                                                    <button className='btn btn-done btn-sm text-uppercase'>modify</button>
                                                    <button className='btn btn-sm btn-transperent' onClick={() => navigate(`/messages/${item._id}`)}>Message</button>
                                                    {/* <ReactStars
                                                        count={5}
                                                        onChange={ratingChanged}
                                                        size={24}
                                                        isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#ffd700"
                                                    /> */}
                                                    {/* <button className='btn btn-done' onClick={() =>toggle()}>rate us</button> */}
                                                </div>
                                            </div>
                                        </div>
                                    </>)
                                }) : <p>No Upcoming Trips Found</p>}

                            </div>
                        </div>

                        <div className="upcoming-trips-footer">
                            <div className="upcoming-trips-lft">
                                <h6 className='text-light'>past trips</h6>
                                <hr />
                            </div>

                            <div className="upcoming-trips-rgt row row-cols-lg-4 row-cols-md-3 row-cols-1 g-2 mt-5">
                                {pastTripBooking.map((item, index) => {
                                    return (
                                        <div className='utr_trip_card' key={index}>

                                            <div className='utr_trip_card_inner d-flex flex-column'>

                                                <div className='trip_location_poster'>
                                                    <img src={tripPoster} className='img-fluid w-100' />
                                                </div>

                                                <div className='utr_card_head d-flex justify-content-between align-items-center'>
                                                    <h6> <DateRangeIcon /> {formattedDateDDMMMYYYY(item?.checkInDate)}</h6>
                                                    <button className='btn btn-share text-success'> <ShareIcon /> SHARE</button>
                                                </div>

                                                <div className='utr_card_center'>
                                                    <h3>{item?.propertyId === null ? item?.eventBooking?.length > 0 ? item?.eventBooking[0].experienceName : item?.experienceBooking[0].experienceName : item?.propertyName}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                        </div>
                    </div>
                </div>
            </main>

            <Modal isOpen={modal} toggle={toggle} size="sm" centered={true} className="">
                <ModalHeader className='pb-2' toggle={toggle}>Rate Us </ModalHeader>
                <ModalBody className='editModal'>
                    <div className="col-12 mb-3">
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={24}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                        />

                        <span>{rating} out of 5</span>
                    </div>

                </ModalBody>
                <ModalFooter className='justify-content-between'>
                    <Button className='btn btn-confirm w-auto d-inline-block border-0' onClick={() => { submitRating() }}>
                        Submit
                    </Button>{' '}
                    <Button className='btn signIn' onClick={toggle}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default MyTrip;