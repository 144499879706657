import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import tabImg from '../../assets/images/tab-btn-image.png'
import { useEffect, useState } from 'react';
import authServices from '../../services/auth.services';
import { BASE_URL } from '../../services/config';
const NearBy = ({title}) => {
    const [nearByList, setNearByList] = useState([])

    useEffect(() => {
        authServices.nearByListApi()
            .then((response) => {
                setNearByList(response.data.data)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }, [])
    return (
        <div className="row">
            <div className="col-12 text-start">
                <h4 className="mt-5 text-light">{title}</h4>
            </div>
            <div className="col-12 mb-4 mt-4">
                <Swiper
                    slidesPerView='auto'
                    spaceBetween={8}
                    loop='true'
                    breakpoints={{
                        640: {
                            slidesPerView: 'auto',
                            spaceBetween: 16,
                        },
                        768: {
                            slidesPerView: 4,
                            spaceBetween: 16,
                        },
                        1024: {
                            slidesPerView: 6,
                            spaceBetween: 16,
                        },
                    }}
                    modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                    className="tabSwiper"
                >
                    {nearByList.map((item, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <button className="btn-tab">
                                    <div className="d-flex align-items-center gap-2 nowrap">
                                        <div className="btn-tab-img"> <img src={item.media ? `${BASE_URL}/${item.media}` : tabImg} /> </div>
                                        <h6>{item?.placeName}</h6>
                                    </div>
                                </button>
                            </SwiperSlide>
                        )
                    })}

                </Swiper>
            </div>
        </div>
    )
}

export default NearBy