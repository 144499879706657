import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
const BookingModal =({
    title,
    toggle,
    type,
    primaryguestEmail,
    primaryguestName, 
    primaryguestPhone,
    updatePrimaryGuestDetail
})=>{
    const [name, setName] = useState(primaryguestName)
    const [email, setEmail] = useState(primaryguestEmail)
    const [phone, setPhone] = useState(primaryguestPhone)


    const onClickUpdate =()=>{
        const result = {
            primaryguestEmail : email,
            primaryguestName : name,
            primaryguestPhone : phone
        }
        updatePrimaryGuestDetail(result)
        toggle()
    }
useEffect(()=>{
    console.log('primaryguestPhone', primaryguestPhone)
    setName(primaryguestName)
    setEmail(primaryguestEmail)
    setPhone(primaryguestPhone)
},[])
    return(
        <>
            <ModalHeader className='pb-2' toggle={toggle}>{title} </ModalHeader>
                <ModalBody className='editModal'>
              <div className="col-12 mb-3">
                            <label htmlFor="date" className="form-label">
                                Primary guest name
                            </label>
                            <input type='text' id='primaryguestname' className='form-control mb-0' onChange={(e)=>setName(e.target.value)} value={name}/>
                        </div> 
                        <div className="col-12 mb-3">
                            <label htmlFor="date" className="form-label">
                                Primary guest email
                            </label>
                            <input type='email' id='primaryguestemail' className='form-control mb-0' onChange={(e)=>setEmail(e.target.value)} value={email}/>
                        </div> 
                        <div className="col-12 mb-3">
                            <label htmlFor="date" className="form-label">
                                Primary guest phone
                            </label>
                            <input type='text' id='primaryguestmobile' className='form-control mb-0' onChange={(e)=>setPhone(e.target.value)} value={phone}/>
                        </div> 
                 </ModalBody>
                <ModalFooter className='justify-content-between'>
                <Button className='btn btn-confirm w-auto d-inline-block border-0' onClick={onClickUpdate}>
                    Update 
                </Button>{' '}
                <Button className='btn signIn' onClick={toggle}>
                Cancel
                </Button>
                </ModalFooter>
        </>
    )
}

export default BookingModal