import { ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import loginImg from '../../assets/img/login-image.png'
import googleImg from '../../assets/img/google.svg'
import facebookSvg from '../../assets/img/facebook.svg'
import callImg from '../../assets/img/call.png'
import nextImg from '../../assets/img/next.png'
import { TextField, Button, Box, Typography, Link } from '@mui/material';
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, provider, signInWithPopup, facebookProvider } from '../../firebase';
import { FacebookAuthProvider, fetchSignInMethodsForEmail, linkWithCredential, signOut } from "firebase/auth";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import { getLoggedData } from '../../helpers/Helper'
import authServices from "../../services/auth.services";
import { AuthSchema } from "../../schema/AuthSchema";
import userIc from '../../assets/img/userIc.png'
import mailIc from '../../assets/img/mailIc.png'
import ReactInputMask from "react-input-mask";

import CallIcon from '@mui/icons-material/CallOutlined';
import TrendingFlatOutlinedIcon from '@mui/icons-material/TrendingFlatOutlined';

const secretPass = "XkhZG4fW2t2W0#$";
const Login = ({ toggle, isAddMode }) => {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [step, setStep] = useState(1);
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [userExist, setUserExist] = useState(false)
  const [timer, setTimer] = useState(30); // 30 seconds timer
  const [isTimerActive, setIsTimerActive] = useState(true);

  const initialMobileNo = phoneNumber;
  const [user] = useAuthState(auth);


  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return;
    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);
    // Focus next input
    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };
  const onVerifyOtp = async () => {
    let str = otp.join('');
    let num = Number(str);
    console.log('num', num);
    if (userExist === true) {
      const data = getLoggedData()
      const reqBody = {
        "id": data.data._id,
        "otp": str
      }

      const result = await authServices.otpVerifyApi(reqBody);
      if (result.status === 200) {
        // result.data.data.auth = true;
        result.data.data.isLoggedIn = true
        const endata = CryptoJS.AES.encrypt(
          JSON.stringify(result.data.data),
          secretPass
        ).toString();
        localStorage.setItem("data", endata);
        console.log("getLoggedData", getLoggedData())
        toggle()
        window.location.reload()
      } else {
        console.log("in here", result);
        toast.error("Invalid  OTP", {
          toastId: "login_fail",
          autoClose: 3000,
        });
      }
    } else {
      const logD = getLoggedData()
      if (num === Number(logD.data.otp)) {
        console.log('verify otp', otp);
        nextStep()
      } else {
        toast.error("Invalid  OTP", {
          toastId: "login_fail",
          autoClose: 3000,
        });
      }
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('User:', result.user);
      loginByEmail(result.user)
    } catch (error) {
      console.error('Error during sign-in:', error);
    }
  };

  const handleFacebookSignIn = async () => {
    try {
      handleSignOut()
      const result = await signInWithPopup(auth, facebookProvider);
      console.log('UserFacebook:', result.user);
      loginByEmail(result.user)
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = FacebookAuthProvider.credentialFromError(error);
        handleAccountExistsWithDifferentCredential(error.email, pendingCred);
      } else {
        console.error('Error during sign-in:', error);
      }
    }
  };

  const handleAccountExistsWithDifferentCredential = async (email, pendingCred) => {
    try {
      const methods = await fetchSignInMethodsForEmail(auth, email);
      if (methods.includes('google.com')) {
        const googleResult = await signInWithPopup(auth, provider);
        await linkWithCredential(googleResult.user, pendingCred);
        console.log('Accounts linked:', googleResult.user);
        loginByEmail(googleResult.user)
      }
      // Add additional providers as needed
    } catch (error) {
      console.error('Error linking credentials:', error);
    }
  };
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log('User signed out');
    } catch (error) {
      console.error('Error during sign-out:', error);
    }
  };

  const loginByEmail = async (data) => {
    const reqBody = {
      "email": data.email,
    }
    const result = await authServices.userLoginByEmailApi(reqBody);
    if (result.status === 200) {
      // result.data.data.auth = true;
      result.data.data.isLoggedIn = true
      const endata = CryptoJS.AES.encrypt(
        JSON.stringify(result.data.data),
        secretPass
      ).toString();
      localStorage.setItem("data", endata);
      console.log("getLoggedData", getLoggedData())
      toggle()
      window.location.reload()
    } else {
      console.log("in here", result);
      // toast.error("Invalid  Credentials", {
      //   toastId: "login_fail",
      //   autoClose: 3000,
      // });
      if (result.status === 404) {
        handleNameAndEmailChange(data)
        setStep(3)
      }
    }
  }


  useEffect(() => {
    let interval = null;
    if (isTimerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
      setIsTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, timer]);


  const handleResendOTP = async () => {
    setOtp(new Array(4).fill(""));
    setTimer(30);
    setIsTimerActive(true);
    const reqBody = {
      mobileNo: phoneNumber,
    };
    const result = await authServices.resendOTPApi(reqBody)
    if (result.status === 200) {
      if (result.data?.data?._id) {
        console.log('success', result.data.data)
        result.data.isLoggedIn = false
        const endata = CryptoJS.AES.encrypt(
          JSON.stringify(result.data),
          secretPass
        ).toString();
        localStorage.setItem("data", endata);

      } else {
        const endata = CryptoJS.AES.encrypt(
          JSON.stringify(result.data),
          secretPass
        ).toString();
        localStorage.setItem("data", endata);

      }
    } else {
      console.log("in here", result);
      toast.error("Invalid  Credentials", {
        toastId: "login_fail",
        autoClose: 3000,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      mobileNo: "",
    },
    validationSchema: AuthSchema("PHONE"),
    onSubmit: async (values) => {
      const reqBody = {
        mobileNo: values.mobileNo,
      };
      setPhoneNumber(values.mobileNo)
      handleMobileNoChange(values.mobileNo)
      const result = await authServices.loginApi(reqBody);
      if (result.status === 200) {
        if (result.data?.data?._id) {
          console.log('success', result.data.data)
          result.data.isLoggedIn = false
          const endata = CryptoJS.AES.encrypt(
            JSON.stringify(result.data),
            secretPass
          ).toString();
          localStorage.setItem("data", endata);
          setUserExist(true)
          nextStep()
        } else {
          const endata = CryptoJS.AES.encrypt(
            JSON.stringify(result.data),
            secretPass
          ).toString();
          localStorage.setItem("data", endata);
          setUserExist(false)
          nextStep()
        }
      } else {
        console.log("in here", result);
        toast.error("Invalid  Credentials", {
          toastId: "login_fail",
          autoClose: 3000,
        });
      }
    },
  });

  const formikRegister = useFormik({
    initialValues: {
      mobileNo: initialMobileNo,
      name: name,
      email: email
    },
    validationSchema: AuthSchema("REGISTER"),
    onSubmit: async (values) => {
      const reqBody = {
        name: values.name,
        mobileNo: values.mobileNo,
        email: values.email
      };
      const result = await authServices.userRegisterApi(reqBody);


      console.log("sa", result);

      if (result.status === 200) {
        // result.data.auth = true;
        result.data.data.isLoggedIn = true
        const endata = CryptoJS.AES.encrypt(
          JSON.stringify(result.data.data),
          secretPass
        ).toString();
        localStorage.setItem("data", endata);
        console.log("getLoggedData", getLoggedData())
        window.location.reload()
        toggle()
      } else {
        console.log("in here", result);
        toast.error("Invalid  Credentials", {
          toastId: "login_fail",
          autoClose: 3000,
        });
      }
    },
  });

  const handleMobileNoChange = (value) => {
    formikRegister.setFieldValue('mobileNo', value); // Update Formik state
    localStorage.setItem('mobileNo', value); // Update local storage
  };
  const handleNameAndEmailChange = (data) => {
    formikRegister.setFieldValue('name', data.displayName); // Update Formik state
    formikRegister.setFieldValue('email', data.email); // Update Formik state
  }


  return (
    <>
      {/* <ModalHeader toggle={toggle} className='border-0 pb-0'>
                <div className="card-title d-flex align-items-center mb-0">
                </div>
            </ModalHeader> */}
      <ModalBody className="pt-4">
        <div className="login_main_screen">
          <div className="authentication_content">
            {step === 1 && <div>
              <h1 className="mt-4">Start <br className="d-md-block d-none" /> exploring</h1>
              <div className="login_social_media_icon d-flex gap-3 align-items-center">
                <a href="#" className="socialIc" onClick={handleGoogleSignIn}>
                  <img src={googleImg} alt="" className="img-fluid" />

                </a>
                <a href="#" className="socialIc" onClick={handleFacebookSignIn}>
                  <img src={facebookSvg} alt="" className="img-fluid" />
                </a>
                <small>or</small>
              </div>

              <form className="mt-4 mb-3" onSubmit={formik.handleSubmit}>
                <div className="input-group mb-3">
                  <span className="input-group-text"><CallIcon /></span>
                  <div className="form-floating">
                  <ReactInputMask
                      className="form-control floatingIc"
                      onChange={e => {
                        // call the built-in handleBur
                        // handleBlur(e)
                        // and do something about e
                        let someValue = e.currentTarget.value
                        console.log('someValue', someValue)
                        formik.setFieldValue("mobileNo", someValue);
                        // ...
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNo}
                      // mask="(999) 999-9999"
                      mask="9999999999"
                      maskChar=""
                      name="mobileNo"
                    // placeholder="9999999999"
                    />
                      <label for="user_phone">phone</label>
                  </div>
                </div>
                <button type="submit" className="btn btn-success w-100" data-bs-target="#signup_modal" data-bs-toggle="modal">
                  <TrendingFlatOutlinedIcon /></button>
              </form>

              <form className="authentication_form" onSubmit={formik.handleSubmit}>
                <div className="mb-3">

                  {/* <div className="position-relative mb-1">
                    <label htmlFor="mobileNo" className="formLabel">
                      phone
                    </label>
                    <input type="text" className="form-control floatingIc" id="mobileNo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.mobileNo}
                          />
                    <ReactInputMask
                      className="form-control floatingIc"
                      onChange={e => {
                        let someValue = e.currentTarget.value
                        console.log('someValue', someValue)
                        formik.setFieldValue("mobileNo", someValue);
                        // ...
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNo}
                      mask="9999999999"
                      maskChar=""
                      name="mobileNo"
                    />
                    <img src={callImg} alt="" className="inputIc" />
                  </div> */}
                  {formik.touched.mobileNo && formik.errors.mobileNo ? (
                    <small className="text-danger d-block text-left">
                      {formik.errors.mobileNo}
                    </small>
                  ) : null}
                </div>
                {/* <button type="submit" className="btn btn-custom" onClick={() => { nextStep(2); handleSignOut() }}> */}
                {/* <button type="submit" className="btn btn-custom">
                  <img src={nextImg} />
                </button> */}
              </form>
            </div>}

            {step === 2 &&
              <div>

                <form className="authentication_form">
                  <h3>Verify
                    one time password</h3>
                  <div className="reotp">
                    <small>{`an OTP (one time password) has been sent to mobile number ${phoneNumber} OTP ${getLoggedData().data?.otp}.`}</small>

                    <small className="mt-2 d-inline-block">Wrong number? <Link onClick={prevStep} className='pointer'>go back to change.</Link></small>
                  </div>

                  <div className='otp-field d-flex justify-content-start gap-2'>
                    {otp.map((data, index) => (
                      <input className="otp_inp_field"
                        key={index}
                        type="text"
                        name="otp"
                        value={data}
                        onChange={e => handleChange(e.target, index)}
                        onFocus={e => e.target.select()}
                      />
                    ))}
                  </div>

                </form>
                {isTimerActive === true ? <>
                  <button className="btn btn-custom text-white" onClick={() => { onVerifyOtp() }} >
                    VERIFY <img src={nextImg} />
                  </button>
                </>
                  : <>
                    <button className="btn btn-custom text-white" onClick={handleResendOTP} disabled={isTimerActive}>
                      Resend OTP
                    </button>
                  </>

                }
                <div className="resend_otp">Resend OTP in <span id="otpDuration"> {timer} </span> </div>

              </div>
            }


            {step === 3 && <div>
              <h3>Let’s get started</h3>

              <form className="authentication_form" onSubmit={formikRegister.handleSubmit}>
                <div className="login_social_media_icon d-flex gap-3 align-items-end"></div>
                <div className="mb-4">
                  <div className="position-relative mb-1">
                    <label htmlFor="name" className="formLabel">
                      name
                    </label>
                    <input type="text" className="form-control floatingIc" id="name"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.name}
                      disabled={name == null || name == '' ? false : true}
                    />
                    <img src={userIc} alt="" className="inputIc" />
                  </div>
                  {formikRegister.touched.name && formikRegister.errors.name ? (
                    <small className="text-danger d-block text-left">
                      {formikRegister.errors.name}
                    </small>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="position-relative mb-1">
                    <label htmlFor="phoneNumber" className="formLabel">
                      phone
                    </label>
                    <input type="text" className="form-control floatingIc" id="mobileNo"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.mobileNo}
                      disabled={phoneNumber == null || phoneNumber == '' ? false : true}

                    />
                    <img src={callImg} alt="" className="inputIc" />
                  </div>
                  {formikRegister.touched.mobileNo && formikRegister.errors.mobileNo ? (
                    <small className="text-danger d-block text-left">
                      {formikRegister.errors.mobileNo}
                    </small>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="position-relative mb-1">
                    <label htmlFor="email" className="formLabel">
                      email
                    </label>
                    <input type="email" className="form-control floatingIc" id="email"
                      onChange={formikRegister.handleChange}
                      onBlur={formikRegister.handleBlur}
                      value={formikRegister.values.email}
                      disabled={email == null || email == '' ? false : true}
                    />
                    <img src={mailIc} alt="" className="inputIc" />
                  </div>
                  {formikRegister.touched.email && formikRegister.errors.email ? (
                    <small className="text-danger d-block text-left">
                      {formikRegister.errors.email}
                    </small>
                  ) : null}
                </div>

                <button type="submit" className="btn btn-custom mb-0" >
                  <img src={nextImg} />
                </button>

                <div className="reotp text-center">
                  <small>by “signing up”, you agree to our <Link className='pointer'>‘terms’</Link> and consent to our <Link className='pointer'>guest policy</Link> and ‘<Link className='pointer'>‘privacy policy</Link>’</small>
                </div>
              </form>
            </div>}
            {/* <div className="footer-Link">
                    <a href="#">Help</a>
                    <a href="#">Privacy</a>
                    <a href="#">Terms</a>
                    <a href="#">Policy</a>
                  </div> */}
          </div>

        </div>
        {/* <div className="row" >


        </div> */}
      </ModalBody>
      {/* <ModalFooter className='border-0 pt-0'>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            onClick={toggle}
                        >
                            Close
                        </button>
                        <button type="submit" className="btn btn-primary btn-sm">
                            Submit
                        </button>
                    </ModalFooter> */}
    </>
  )
}
export default Login;