import CryptoJS from "crypto-js";

import moment from 'moment';

export const formattedDate = (date) => {
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const today = new Date(date);
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  // const formattedToday = dd + "-" + mm + "-" + yyyy;
  const formattedToday = dd + "-" + monthNames[today.getMonth()] + "-" + yyyy;
  return formattedToday;
};

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export const getLoggedData = () => {
  let result = {}
  const secretPass = "XkhZG4fW2t2W0#$";
  const dataUser = localStorage.getItem('data');
  if (dataUser) {
    const bytes = CryptoJS.AES.decrypt(dataUser, secretPass);
    let d = isJsonString(bytes.toString(CryptoJS.enc.Utf8))
    if (d) {
      result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
  }
  return result;
}

export const getDaysBetweenDates = (startDate, endDate) => {
  const weekendDays = [];
  const regularDays = [];
  let currentDate = new Date(startDate);

  while (currentDate <= new Date(endDate)) {
    const dayOfWeek = currentDate.getDay();
    const formattedDate = currentDate.toISOString().split('T')[0];

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      weekendDays.push(formattedDate);
    } else {
      regularDays.push(formattedDate);
    }

    // currentDate.setDate(currentDate.getDate() + 1);
    currentDate.setDate(currentDate.getDate() + 1);
  }

  console.log('weekendDays', weekendDays)
  console.log('regularDays', regularDays)
  return { weekendDays, regularDays };
}

export const calculateDayCount = (startDate, endDate) => {
  // Convert the start and end dates to timestamps
  const startTimestamp = new Date(startDate).getTime();
  const endTimestamp = new Date(endDate).getTime();

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = endTimestamp - startTimestamp;

  // Convert the difference to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const millisecondsInADay = 24 * 60 * 60 * 1000;
  const dayCount = differenceInMilliseconds / millisecondsInADay;

  return dayCount;
}

export const checkWeekendOrRegular = (someDateString) => {
  // Parse the date string
  let dateString = moment(someDateString).format("DD-MM-YYYY")
  console.log('ddddate', dateString)
  let [day, month, year] = dateString.split('-').map(Number);

  // Create a Date object
  let givenDate = new Date(year, month - 1, day); // Note: months are 0-based in JavaScript

  // Get the day of the week (Sunday is 0 and Saturday is 6)
  let dayOfWeek = givenDate.getDay();

  // Check if it's a weekend or regular day
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return "Weekend";
  } else {
    return "Regular weekday";
  }
}

// Example usage



// const startDate = '2024-07-12';
// const endDate = '2024-07-17';

// const { weekendDays, regularDays } = getDaysBetweenDates(startDate, endDate);

// console.log('Weekend Days:', weekendDays);
// console.log('Regular Days:', regularDays);
export const formattedDateDDMMMYYYY = (dateString) => {
  // let date = moment(dateString).format("DD-MM-YYYY");
  let date = moment.utc(dateString).format("DD-MM-YYYY");
  // Convert the UTC date to local time and format it
  var formattedDate = moment(date, "DD-MM-YYYY").local().format("DD MMM YYYY");

  return formattedDate;
}

export const getDaysCheckInAndCheckOutDates = (startDate, endDate) => {
  let weekendCount = 0;
  let regularCount = 0;
  let currentDate = new Date(startDate);
  const checkoutDate = new Date(endDate);

  // Loop through each date from startDate to the day before endDate
  while (currentDate < checkoutDate) {
    const dayOfWeek = currentDate.getDay(); // Get the day of the week (0 for Sunday, 6 for Saturday)

    if (dayOfWeek === 0 || dayOfWeek === 6) { // Check if it's a weekend
      weekendCount++;
    } else {
      regularCount++;
    }

    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  // Return the counts
  return { weekendCount, regularCount };
}

// Example usage
// const result = getDaysBetweenDates('2024-07-22', '2024-07-23');
// console.log(result); // Should be { weekendCount: 0, regularCount: 1 }


export function getTimeOfDay(date) {
  const hour = date.getHours(); // Get the hour from the date object

  if (hour >= 5 && hour < 12) {
    return 'Morning';
  } else if (hour >= 12 && hour < 17) {
    return 'Afternoon';
  } else if (hour >= 17 && hour < 21) {
    return 'Evening';
  } else {
    return 'Night';
  }
}

export const convertDateToYYYYMMDD = (date) => {
  console.log('date', date)
  if (date === null) {
    // return ''
    date = new Date()
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
    let day = date.getDate().toString().padStart(2, '0');

    // Return the formatted date in "yyyy-mm-dd" format
    return `${year}-${month}-${day}`;
  } else {
    // Get year, month, and day from the date object
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed, so we add 1
    let day = date.getDate().toString().padStart(2, '0');

    // Return the formatted date in "yyyy-mm-dd" format
    return `${year}-${month}-${day}`;
  }
}
// // Example usage:
// const date = new Date(); // current time
// console.log(getTimeOfDay(date));


export const calculateHoursStartTimeAndEndTime = (startTime, endTime) => {

  const start = new Date(`1970-01-01 ${startTime}`);
  const end = new Date(`1970-01-01 ${endTime}`);

  const diffMillis = end - start;

  const hours = Math.floor(diffMillis / (1000 * 60 * 60));
  const minutes = Math.floor((diffMillis % (1000 * 60 * 60)) / (1000 * 60));

  // Format the result as HH:MM
  return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;


}

export const getDayName = (date, day) => {
  const newDate = moment.utc(date).subtract(1, 'days').format();
  const addDate = moment.utc(newDate).add(day, 'days').format("DD MMM YYYY");
  const dayName = moment.utc(addDate).format('dddd');
  
  return {dayName: dayName, date: addDate};
}