import { useEffect, useState } from 'react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import authServices from '../../services/auth.services';
import ic01 from '../../assets/images/fire-ic.png'
const Terrian = ({onClickTerrianBadge}) =>{
    const [terrianList, setTerrianList] = useState([])
    const [ativeTerrian, setActiveTerrian] = useState('all')

    useEffect(()=>{
        authServices.terrianListApi().then((result) => {
            if (result.status === 200) {
              console.log(result.data.data)
              setTerrianList(result.data.data)
            }
          })
    },[])
    const onClickTerrian = (value) => {
        setActiveTerrian(value)
      }
    return (
        <>
            <div className="col-12">
                <Swiper
                  slidesPerView='auto'
                  spaceBetween={10}
                  loop='true'
                  breakpoints={{
                    640: {
                      slidesPerView: 'auto',
                      spaceBetween: 16,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 16,
                    },
                    1024: {
                      slidesPerView: 6,
                      spaceBetween: 16,
                    },
                  }}
                  modules={[Navigation, Pagination, Mousewheel, Keyboard]}
                  className="mySwiper02"
                >
                   <SwiperSlide>
                        <button className={`btn-tab-tracking ${'all' === ativeTerrian && 'active'}`} onClick={() => {onClickTerrian('all'); onClickTerrianBadge('all') }}>
                          <div className="d-flex align-items-center gap-1">
                            <img src={ic01} alt="icons" />
                            <h5 className="m-0">All</h5>
                          </div>
                        </button>
                      </SwiperSlide>
                  {terrianList.map((item, index) => {
                    return (
                      <SwiperSlide key={index}>
                        <button className={`btn-tab-tracking ${item?.terrainName === ativeTerrian && 'active'}`} onClick={() => {onClickTerrian(item?.terrainName); onClickTerrianBadge(item?._id) }}>
                          <div className="d-flex align-items-center gap-1">
                            <img src={ic01} alt="icons" />
                            <h5 className="m-0">{item?.terrainName}</h5>
                          </div>
                        </button>
                      </SwiperSlide>
                    )
                  })}

                </Swiper>
              </div>
        </>
    )
}

export default Terrian