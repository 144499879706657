import { useEffect, useState } from 'react'
import AppIcon1 from '../../assets/images/AppIcon1.png'
import AppIcon2 from '../../assets/images/AppIcon2.png'
import AppIcon3 from '../../assets/images/AppIcon3.png'

import partnerCompany1 from '../../assets/images/partnerCompany1.png'
import partnerCompany2 from '../../assets/images/partnerCompany2.png'
import partnerCompany3 from '../../assets/images/partnerCompany3.png'
import partnerCompany4 from '../../assets/images/partnerCompany4.png'
import partnerCompany5 from '../../assets/images/partnerCompany5.png'

const Features = () => {
    const [isBookingConfirmation, setIsBookingConfirmation] = useState(false)
    useEffect(() => {
        console.log('window.location.pathname', window.location.pathname)
        let url = window.location.pathname
        var parts = url.split("/");
        console.log('parts', parts[1])
        if (parts[1] === 'booking-confirmation') {
            setIsBookingConfirmation(true)
        }
      }, []);
    return (
        <>
            <section className="section10 my-5">
                <div className="container">
                    <div className='card br16 bg-white p-md-5 p-3'>
                        <div className="row">
                            <div className="col-12">
                                <div className="safety_furutcamp_lt">
                                    <h4 className='mb-3'>Safety @ furut camps</h4>
                                    <p>Building Resilient Communities is one of Furut Camp's fundamental beliefs. To do this, we actively cultivate a culture of trust and respect, one that promotes inclusion and safety. We value and safeguard diversity as a strength of nature and those who study it. Discrimination undermines our efforts to develop resilient communities and has no place on furut camp.</p>
                                </div>
                                <div className="row mt-4">
                                    <div className='col-md-6 mb-2'>
                                        <div className="card border-0 br16 safetyfurut mb-3">
                                            <h4 className='mt-2 mb-2'>Hosting standards</h4>
                                            <p>All Hosts should study and follow these guidelines for delivering a great furut camps experience, being a kind neighbour, and acting as a responsible citizen.</p>
                                            <a href="#" className="btn btn-outline-warning">Learn more</a>
                                        </div>
                                    </div>

                                    <div className='col-md-6 mb-2'>
                                        <div className="card border-0 br16 safetyfurut">
                                            <h4 className='mt-2 mb-2'>furut camper's standards</h4>
                                            <p>To preserve furut camp's most essential principle, "conserve the future," all furut campers should examine and adhere to these criteria for safety, trip planning, and respect.</p>
                                            <a href="#" className="btn btn-outline-warning">Learn more</a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* ------------------------------------------------------------------------------------------------ THIRD SECTION START  */}

           {isBookingConfirmation === false &&  <section className="section08">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="Initiatives_furut_camps br16">
                                <div className="lgTitle">
                                    <h2 className="mb-3">Initiatives @ furutcamps</h2>
                                    <p>Building Resilient Communities is one of Furut Camp's fundamental beliefs. To do this, we actively cultivate a culture of trust and respect, one that promotes inclusion and safety. We value and safeguard diversity as a strength of nature and those who study it. Discrimination undermines our efforts to develop resilient communities and has no place on furut camp.</p>
                                </div>

                                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4">
                                    <div className="col">
                                        <div className="card_content br16 bg-white">
                                            <img src={AppIcon1} alt="" className="img-fluid card_content_ic" />
                                            <h4>Go hyper-local</h4>

                                            <ol className="numberList">
                                                <li>Respect local customs and tradition</li>
                                                <li>Venture beyond tourist attractions</li>
                                                <li>Try local foods and drink</li>
                                                <li>Don't rely on stereotypes</li>
                                                <li>Learn the language if possible</li>
                                                <li>Explore the landscape</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card_content br16 bg-white">
                                            <img src={AppIcon2} alt="" className="img-fluid card_content_ic" />
                                            <h4>Sustainably restore</h4>

                                            <ol className="numberList">
                                                <li>Build an inclusive outdoor.</li>
                                                <li>Plan ahead.</li>
                                                <li>Know before you go.</li>
                                                <li>Play safe.</li>
                                                <li>Maintain physical distancing.</li>
                                                <li>leave no trace.</li>
                                                <li>Respect nature and people.</li>
                                                <li>Explore locally.</li>
                                            </ol>
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card_content br16 bg-white">
                                            <img src={AppIcon3} alt="" className="img-fluid card_content_ic" />
                                            <h4>Leave only thanks
                                                take only memories</h4>

                                            <ol className="numberList">

                                                <li>Plan ahead and prepare.</li>
                                                <li>Minimise & dispose your waste responsibly.</li>
                                                <li>Avoid damaging live trees, plants, natural objects and cultural artefacts.</li>
                                                <li>Respect wildlife.</li>
                                                <li>Avoid alternating sites.</li>
                                                <li>Be considerate of other visitors.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        }
            {/* ------------------------------------------------------------------------------------------------ NINE SECTION START  */}

            <section className="section12 my-5">
                <div className="container">
                    <div className="second_footer">
                        <div class="row align-items-center justify-content-center position-relative z-2">
                            <div class="col-lg-4 justify-content-around p-5">
                                <h4 class="text-light"><strong> </strong> for the planet &amp; the people</h4>
                            </div>
                            <div class="col-lg-4 p-5 p-md-5">
                                <div class="mt-5 mb-5">
                                    <h6 class="text-light">1.5% for the planet</h6>
                                    <small class="text-light">furut's self-imposed Earth tax, provides support to environmental nonprofits working to defend our air, land, and water.</small>
                                </div>
                                <div class="mt-5 mb-5">
                                    <h6 class="text-light">1.5% for the people</h6>
                                    <small class="text-light">furut's self-imposed people tax, 1.5% for the communities, self-help groups, or individuals for economic independence.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Features