import { Children, useEffect, useState } from "react";
import { BASE_URL } from "../../services/config";
import { calculateDayCount, convertDateToYYYYMMDD, formattedDateDDMMMYYYY, getDaysBetweenDates, getDaysCheckInAndCheckOutDates, getLoggedData } from "../../helpers/Helper";

import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import InfoIcon from '@mui/icons-material/Info';


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
} from 'reactstrap';
import Thumbnails from "../../assets/img/Thumbnails.png";
import { Swiper, SwiperSlide } from "swiper/react";
import shareIc from "../../assets/img/share-ic.png";
import likeIc from "../../assets/img/like-ic.png";

import hostPoster from "../../assets/img/hostPoster.jpg"

import foodBeverage from "../../assets/img/food-beverage.png"
import { useNavigate, useParams } from "react-router-dom";
import Login from "../../components/Authentication/Login";
import authServices from "../../services/auth.services";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import checkIn from "../../../src/assets/img/check-in-ic.png";
import checkOut from "../../../src/assets/img/check-out-ic.png";
import arrowWhite from '../../assets/img/arrow-light.png'
import campRules from '../../assets/img/campus-rules.png'
import campRules1 from '../../assets/img/campus-rules-restrict.png'
import { Padding } from "@mui/icons-material";
import Features from "../../components/Comman/Features";
import cardImg from "../../assets/img/card-image.jpg";
import clockImg from "../../assets/img/clock.png";
import starRatings from "../../assets/img/star-ratings.png";
import forestImg from "../../assets/img/forest.png";
import treeIc from "../../assets/img/tree-ic.png";
import datePng from "../../assets/img/date.png";
import khadaAadmi from '../../assets/img/khada-aadmi.png'
import card01 from '../../assets/images/card01.jpg'
import ratingStar from '../../assets/images/Rating icon.png'
import USI_ICON from '../../assets/img/USI_ICON.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// MATERIAL ICONS IMPORT 
import HotTubIcon from '@mui/icons-material/HotTub';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ShowerIcon from '@mui/icons-material/Shower';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import NearBy from "../../components/Comman/NearBy";
import OffcanvasModal from "../../components/Comman/OffcanvasModal";
import HostedBy from "../../components/Comman/HostedBy";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// const StayDetail = ({ stayDetail, selectedPropertyDetail, setShowStayDetail, callBackProperty, setShowProperty }) => {
const StayDetail = ({ direction, ...args }) => {
  const [stayDetail, setStayDetail] = useState('');
  const [showStayBooking, setShowStayBooking] = useState(false)
  const [adults, setAduts] = useState(1)
  const [children, setChildren] = useState(0)
  const [pet, setPet] = useState(0)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [stayBookingData, setStayBookingData] = useState()
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedPropertyDetail, setSelectedPropertyDetail] = useState('')
  const [temporaryBooking, setTemporaryBooking] = useState('')
  const [bookingPopup, setPopup] = useState(false)
  const [formErrors, setFormErrors] = useState({
    startDate: '',
  });
  const [addonCount, setAddonCount] = useState(1)
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);

  const onClickBookingBtn = () => setPopup(!bookingPopup)
  const toggleLogin = () => setModal(!modal);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const [isOpenCanvas, setIsOpenCanvas] = useState(false);

  const toggleCanvas = () => setIsOpenCanvas(!isOpenCanvas);

  const formatDates = (date) => date ? date.format('MM/DD/YYYY') : '';
  const [bookedDates, setBookedDates] = useState([])
  const { id } = useParams();
  const onSubmit = (e) => {
    // return false
    if (Number(adults) < 1) {
      toast.error("Please select atleast 1 adulst", { toastId: "login_fail", autoClose: 3000 });
      return false
    }
    if (startDate === null || startDate === '') {
      // alert('Please select first chekin and checkout date')
      toast.error("Please select first chekin  date", { toastId: "login_fail", autoClose: 3000 });
      return false
    } else if (endDate === null || endDate === '' || endDate === undefined) {
      // alert('Please select first checkin and checkout date')
      toast.error("Please select checkout date", { toastId: "login_fail", autoClose: 3000 });
      return false
    }

    // if(new Date(startDate)< new Date()){
    //   toast.error("Please select valid date", {toastId: "login_fail", autoClose:3000})
    //   return false;
    // }
    console.log('no input blank')
    let propertyDetailObj = selectedPropertyDetail
    let loggedUser = getLoggedData()
    if (!loggedUser.isLoggedIn) {
      toggleLogin()
      return
    }
    e.preventDefault();
    const errors = validate();
    setFormErrors(errors);
    console.log("refresh prevented");
    const checkInCheckOutDays = getDaysCheckInAndCheckOutDates(startDate, endDate)
    console.log('checkInCheckOutDays', checkInCheckOutDays)
    let weekendDays = checkInCheckOutDays.weekendCount
    let regularDays = checkInCheckOutDays.regularCount
    setShowStayBooking(true);
    // let existBooking = localStorage.getItem('stayBooking');

    // let booking = JSON.parse(existBooking);
    let booking = temporaryBooking
    if (temporaryBooking) {
      if (booking.stayBooking) {
        console.log('in stayBooking')

        console.log('boookkkkk', booking.stayBooking)
        console.log('stayDetailBo', stayDetail)
        const findStayBookingById = (array, id) => {
          return array.filter(booking => booking.stayId === id);
        };

        const stayBookingIdToFind = stayDetail?._id;
        const foundStayBooking = findStayBookingById(booking?.stayBooking, stayBookingIdToFind);
        if (foundStayBooking.length > 0) {
          console.log('found stay booking', booking)
          for (let i of booking.stayBooking) {
            if (i.stayId === stayBookingIdToFind) {
              console.log('iiiiidddddss', i)
              i.adults = adults
              // i.stayCount = foundStayBooking.length + i.stayCount
              i.children = children
              i.pet = pet
              i.weekendDays = weekendDays
              i.regularDays = regularDays
              i.stayDay = regularDays + weekendDays
              i.checkInDate = startDate
              i.checkOutDate = endDate
              let countAdults = adults
              let countAvailable = stayDetail.countAvailable
              let countCapacity = stayDetail.countCapacity
              // let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
              let count = adults < countAvailable * countCapacity ? Math.ceil(countAdults / countCapacity) : countAvailable;
              // console.log('cccc', countAdults / countCapacity)
              i.stayCount = count
              if (adults > countAvailable * countCapacity) {
                alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
                i.stayCount = count
              }
              if (i.stayCount > countAvailable) {
                i.stayCount = countAvailable
                alert(`This Stay Availeblty Only ${countAvailable}`)
              }
            }

          }
          booking.userId = loggedUser?.user?._id || ''
          booking.adults = adults
          booking.children = children
          booking.pet = pet
          booking.checkInDate = startDate
          booking.checkOutDate = endDate
          booking.propertyId = stayDetail.propertyId
          booking.hostId = selectedPropertyDetail.userId
          booking.userId = loggedUser?.user?._id || ''
        } else {
          console.log('no found stay booking', foundStayBooking)
          let countAdults = adults
          let countAvailable = stayDetail.countAvailable
          let countCapacity = stayDetail.countCapacity
          // let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
          let count = adults < countAvailable * countCapacity ? Math.ceil(countAdults / countCapacity) : countAvailable;
          let stayPrice = stayDetail.perPerson === true ? stayDetail.priceRegular * adults : stayDetail.priceRegular
          let bookingData = {
            hostId: propertyDetailObj.userId,
            stayId: stayDetail._id,
            propertyId: stayDetail.propertyId,
            stayTypeId: stayDetail.stayTypeId,
            stayType: stayDetail.stayType,
            stayTypeImg: stayDetail.stayTypeImg,
            perPerson: stayDetail.perPerson,
            // price: 1500,
            price: stayDetail.priceRegular,
            // price: stayPrice,
            stayCount: count,
            // stayCount: 1,
            totalPrice: 0,
            adults: adults,
            children: children,
            pet: pet,
            checkInDate: startDate,
            checkOutDate: endDate,
            countAvailable: countAvailable,
            countCapacity: countCapacity,
            stayDay: regularDays + weekendDays,
            weekendDays: weekendDays,
            regularDays: regularDays,
            priceRegular: stayDetail.priceRegular,
            priceWeekend: stayDetail.priceWeekend
          }
          // stayDetail.stayCount = 1
          booking.userId = loggedUser?.user?._id || ''
          booking.stayBooking.push(bookingData);
          booking.adults = adults
          booking.children = children
          booking.pet = pet
          booking.checkInDate = startDate
          booking.checkOutDate = endDate
          booking.propertyId = stayDetail.propertyId
          booking.hostId = selectedPropertyDetail.userId
          booking.userId = loggedUser?.user?._id || ''
          // booking.stayCount = 1
        }

        setStayBookingData(booking)
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
        addBookingInCart(booking)
      } else {
        console.log('in else stay')
        let countAdults = adults
        let countAvailable = stayDetail.countAvailable
        let countCapacity = stayDetail.countCapacity
        // let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
        let count = adults < countAvailable * countCapacity ? Math.ceil(countAdults / countCapacity) : countAvailable;
        let bookingData = {
          hostId: propertyDetailObj.userId,
          stayId: stayDetail._id,
          propertyId: stayDetail.propertyId,
          stayTypeId: stayDetail.stayTypeId,
          stayType: stayDetail.stayType,
          stayTypeImg: stayDetail.stayTypeImg,
          perPerson: stayDetail.perPerson,
          // price: stayDetail.perPerson === true ? stayDetail.priceRegular * adults : stayDetail.priceRegular,: 
          price: stayDetail.priceRegular,
          stayCount: count,
          // stayCount: 1,
          totalPrice: 0,
          adults: adults,
          children: children,
          pet: pet,
          checkInDate: startDate,
          checkOutDate: endDate,
          countAvailable: countAvailable,
          countCapacity: countCapacity,
          stayDay: regularDays + weekendDays,
          weekendDays: weekendDays,
          regularDays: regularDays,
          priceRegular: stayDetail.priceRegular,
          priceWeekend: stayDetail.priceWeekend,

        }
        if (adults > countAvailable * countCapacity) {
          alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
        }
        booking.userId = loggedUser?.user?._id || ''
        booking.stayBooking = [bookingData]
        booking.adults = adults
        booking.children = children
        booking.pet = pet
        booking.checkInDate = startDate
        booking.checkOutDate = endDate
        booking.propertyId = stayDetail.propertyId
        booking.hostId = selectedPropertyDetail.userId
        booking.userId = loggedUser?.user?._id || ''
        addBookingInCart(booking)
      }
    } else {
      console.log('not exist booking');
      // stayDetail.stayCount = 1
      let countAdults = adults
      let countAvailable = stayDetail.countAvailable
      let countCapacity = stayDetail.countCapacity
      // let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
      let count = adults < countAvailable * countCapacity ? Math.ceil(countAdults / countCapacity) : countAvailable;
      let bookingData = {
        hostId: propertyDetailObj.userId,
        stayId: stayDetail._id,
        propertyId: stayDetail.propertyId,
        stayTypeId: stayDetail.stayTypeId,
        stayType: stayDetail.stayType,
        stayTypeImg: stayDetail.stayTypeImg,
        perPerson: stayDetail.perPerson,
        // price: stayDetail.perPerson === true ? stayDetail.priceRegular * adults : stayDetail.priceRegular,: 
        price: stayDetail.priceRegular,
        stayCount: count,
        // stayCount: 1,
        totalPrice: 0,
        adults: adults,
        children: children,
        pet: pet,
        checkInDate: startDate,
        checkOutDate: endDate,
        countAvailable: countAvailable,
        countCapacity: countCapacity,
        stayDay: regularDays + weekendDays,
        weekendDays: weekendDays,
        regularDays: regularDays,
        priceRegular: stayDetail.priceRegular,
        priceWeekend: stayDetail.priceWeekend,

      }
      if (adults > countAvailable * countCapacity) {
        alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
      }
      let obj = {
        userId: loggedUser?.user?._id || '',
        propertyId: stayDetail.propertyId,
        hostId: selectedPropertyDetail.userId,
        adults: adults,
        children: children,
        pet: pet,
        checkInDate: startDate,
        checkOutDate: endDate,
        stayBooking: [bookingData],

        // stayCount: 1
      };
      setStayBookingData(obj)
      // localStorage.setItem('stayBooking', JSON.stringify(obj));
      addBookingInCart(obj)
    }
    // authServices.checkStayAvalablity({stayId: stayDetail._id, startDate: startDate, endDate: endDate, adults: adults}).then((result)=>{
    //   if(result.status === 200){

    //     // navigate('/booking')
    //   }
    //   if(result.status===404){
    //     alert('This Stay is not available for the selected dates')
    //   }
    // }).catch((error)=>{
    //   console.log('checkStayError', error)
    // })
    // navigate('/booking')
  };

  const addAddons = (addonData) => {
    let existBooking = temporaryBooking
    let booking = existBooking
    console.log('addonCount', addonData)
    console.log('fffdasfas', booking.addonBooking)
    if (existBooking) {
      if (booking?.addonBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter(booking => booking.addonId === id);
        };
        const addonBookingIdToFind = addonData?._id;
        const foundAddonBooking = findAddonBookingById(booking?.addonBooking, addonBookingIdToFind);
        if (foundAddonBooking.length > 0) {
          console.log('found addon booking', booking)
          for (let i of booking.addonBooking) {
            if (i.addonId === addonBookingIdToFind) {
              console.log('iiiiidddddss', i)
              // i.addonCount = foundAddonBooking.length + i.addonCount
              i.addonCount = addonCount
          
            }

          }
        } else {
          console.log('no found addon booking', foundAddonBooking)
          let bookingData = {
            addonId: addonData._id,
            propertyId: addonData.propertyId,
            addonTypeId: addonData.addonTypeId,
            addonTypeName: addonData.addonTypeName,
            serviceName: addonData.serviceName,
            serviceDescription: addonData.serviceDescription,
            perPersonDay: addonData.perPersonDay,
            price: addonData.price,
            addonCount: addonCount,
            hostId: selectedPropertyDetail.userId
          }
          // stayDetail.stayCount = 1
          // booking.addonsBooking.push(bookingData);
          // booking.addonBooking = [bookingData]
          booking.addonBooking.push(bookingData)
        }
        console.log('addonBooking', booking)
        addBookingInCart(booking)
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      } else {
        console.log('not exist booking', booking);
        // addonData.addonCount = 1
        let bookingData = {
          addonId: addonData._id,
          propertyId: addonData.propertyId,
          addonTypeId: addonData.addonTypeId,
          addonTypeName: addonData.addonTypeName,
          serviceName: addonData.serviceName,
          serviceDescription: addonData.serviceDescription,
          perPersonDay: addonData.perPersonDay,
          price: addonData.price,
          addonCount: addonCount,
          hostId: selectedPropertyDetail.userId

        }
        let obj = {
          addonBooking: [addonData],
          // stayCount: 1
        };
        booking.addonBooking = [bookingData]
        console.log('bbb', booking)
        addBookingInCart(booking)
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }else{
      alert('Please add first any stay, event or experience')
    }
  }

  const handleDateChange = (event) => {
    console.log('dateee', event.target.value)
    setStartDate(event.target.value); // Update start date state
  };
  const addBookingInCart = (data) => {
    console.log('dddda', data)
    let booking = data
    authServices.addTripBookingInCartApi({ "booking": booking }).then((response) => {
      console.log('booking added in cart', response.data.data)
      authServices.checkStayAvalablity({ stayId: stayDetail._id, startDate: startDate, endDate: endDate, adults: adults }).then((result) => {
        if (result.status === 200) {
          navigate(`/booking/${response.data.data._id}`)
          // navigate('/booking')
        }
        if (result.status === 404) {
          alert('This Stay is not available for the selected dates')
        }
      }).catch((error) => {
        console.log('checkStayError', error)
      })
      // navigate(`/booking/${response.data.data._id}`)

    }).catch((error) => {
      console.log('error in cart', error)
    })

  }
  const today = new Date().toISOString().split('T')[0];


  // const disabledDates = [
  //   new Date("2024-08-07"),
  //   new Date("2024-08-11"),
  //   new Date("2024-08-15"),
  //   new Date("2024-09-02"),
  // ];
  const disabledDates = bookedDates.map(date => new Date(date));
  const isDateDisabled = (date) => {
    return disabledDates.some(
      (disabledDate) =>
        date.getFullYear() === disabledDate.getFullYear() &&
        date.getMonth() === disabledDate.getMonth() &&
        date.getDate() === disabledDate.getDate()
    );
  };

  const onClickStay = (value) => {
    console.log("value", value);

    localStorage.setItem('stayDetail', JSON.stringify(value))
    navigate(`/stay-detail/${value._id}`)
  };

  const onClickExperience = (value) => {
    console.log("onClickExperience", value)
    localStorage.setItem('experienceDetail', JSON.stringify(value))
    navigate(`/experience-detail/${value._id}`)
  };

  const onClickEvent = (value) => {
    console.log("event", value)
    localStorage.setItem('event', JSON.stringify(value))
    navigate(`/event-detail/${value._id}`)
  };

  useEffect(() => {
    authServices.stayDetailByIdApi({ id: id }).then((result) => {
      console.log('in use')
      if (result.status === 200) {
        setStayDetail(result.data.data)
        let bookedDatesData = result.data.data.bookedDates

        setBookedDates(bookedDatesData)
        console.log('result.data.data.bookedDates', bookedDatesData)
        let loggedUser = getLoggedData()
        authServices.propertyDetailByIdApi({ id: result.data.data.propertyId }).then((result) => {
          setSelectedPropertyDetail(result.data.data)
        })
        if (loggedUser?.isLoggedIn) {
          authServices.temporaryTripBookingByUserIdAndPropertyId({ userId: loggedUser?.user?._id, propertyId: result.data.data.propertyId }).then((result) => {
            if (result.status === 200) {
              console.log('temporaryBooking', result.data.data)
              let rs = result?.data?.data
              if (rs?.booking) {
                setTemporaryBooking(result.data.data.booking)
                // setAduts(rs?.booking.stay.adults)
                // setChildren(rs?.booking.children)
                // setPet(rs?.booking.pet)
                setStartDate(rs?.booking.checkInDate)
                setEndDate(rs?.booking.checkOutDate)
              }
            }
          }).catch((error) => {
            console.log('error', error)
          })
        }
      }
    }).catch((error) => {
      console.log('error', error)
    })
  }, [])
  //   }, [showStayBooking])


  const validate = () => {
    let errors = {};

    if (!startDate) {
      errors.startDate = "Start Date is required";
    }

    // if (!formData.email) {
    //   errors.email = "Email is required";
    // } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    //   errors.email = "Email address is invalid";
    // }

    // if (!formData.password) {
    //   errors.password = "Password is required";
    // } else if (formData.password.length < 6) {
    //   errors.password = "Password must be at least 6 characters";
    // }

    return errors;
  };

  return (
    <>

      <main className="stay-details-page">
        <section className="pd01">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
                <div className="pd01_top_wrapper d-flex justify-content-start align-items-center gap-1">
                  <Swiper
                    className="mySwiper"
                    slidesPerView={4}
                    spaceBetween={1}
                    loop="true"
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1,
                      },
                      // when window width is >= 768px
                      640: {
                        slidesPerView: 2,
                      },
                      800: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {stayDetail?.stayImages ? stayDetail?.stayImages.map((item, index) => {
                      return (
                        <SwiperSlide className="pd01_top_thumbnail" key={index} onClick={() => toggleCanvas()}>
                          <img
                            src={`${BASE_URL}/${item.propImgName}`}
                            alt=""
                            className="img-fluid"
                          />
                        </SwiperSlide>
                      )
                    }) : <>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </SwiperSlide>
                    </>
                    }
                  </Swiper>
                </div>

              </div>
            </div>
          </div>

          <div className="container">
            <div className="pd01_bottom_wrapper row">
              <div className="col-lg-8">
                <div className="pd-bottom_rtl">
                  <div className="pd01_bottom_content mt-4 pt-0">
                    <div className="breadcrumb_wrapper">
                      <nav
                        style={{ "--bs-breadcrumb-divider": "'>'" }}
                        ariaLabel="breadcrumb"
                      >
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <a href="#">Camps in</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">{selectedPropertyDetail?.propertyLocated?.state}</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">{selectedPropertyDetail?.propertyLocated?.city}</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="#">{selectedPropertyDetail?.propertyLocated?.fullAddress}</a>
                          </li>
                        </ol>
                      </nav>
                    </div>

                    <div className="pd01_content justify-content-between align-items-end d-flex mt-4">
                      <h4 className="productTitle h2 fw-bold mt-0 mb-2">{stayDetail?.stayType} @ {selectedPropertyDetail?.propertyName}</h4>

                      {/* <div className="d-flex align-items-center justify-content-end">
                        <div className="pd01_content_rtl">
                          <button className="btn_cutom_atn border-0 bg-transparent">
                            <img src={shareIc} alt="" />
                            Share
                          </button>
                          <button className="btn_cutom_atn border-0 bg-transparent">
                            <img src={likeIc} alt="" />
                            Save
                          </button>
                        </div>
                      </div> */}
                      <div className="pd01_content_rtl d-flex justify-content-end">
                        <button className="btn_cutom_atn border-0 bg-transparent text-success">
                          <ShareIcon />
                          share
                        </button>
                        <button className="btn_cutom_atn border-0 bg-transparent text-danger">
                          <FavoriteIcon />
                          save
                        </button>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <div className="sdp_about mt-5">
                    <p className="text-light"><span>about {stayDetail?.stayType} </span>@ {selectedPropertyDetail?.propertyName}</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis ante. Morbi egestas nisl nec scelerisque consequat. Curabitur eu tellus placerat, maximus tortor at, vulputate magna. Duis tincidunt diam erat, sed vulputate tortor vestibulum eget. Nunc cursus leo nec odio hendrerit, ut mollis urna semper. </p>


                  </div>

                  <hr />


                  <div className="sdp_highlights">
                  
                  {selectedPropertyDetail?.uspOne &&  <div className="icwtxt">
                    <img src={USI_ICON} />
                    {selectedPropertyDetail?.uspOne}
                  </div>
                  }
                  {selectedPropertyDetail?.uspTwo && <div className="icwtxt">
                    <img src={USI_ICON} />
                    {selectedPropertyDetail?.uspTwo}
                  </div>
                  }
                 {selectedPropertyDetail?.uspThree && <div className="icwtxt">
                    <img src={USI_ICON} />
                    {selectedPropertyDetail?.uspThree}
                  </div>
                }
                 {selectedPropertyDetail?.uspOne || selectedPropertyDetail?.uspTwo || selectedPropertyDetail?.uspThree ?  <hr /> : ''}
                </div>
                  <hr />
                  <div className="sdp_amentities">
                    <div className="title mt-5">facilities at {stayDetail.stayType}</div>
                    <div className="subtitle">facilities available at {stayDetail.stayType} in {selectedPropertyDetail.propertyName}</div>

                    <div className="highlights_sec d-flex justify-content-start align-items-start bg-transparent px-0">
                      {stayDetail?.includedFacilitiesFreeData && stayDetail?.includedFacilitiesFreeData.map((item, index) => {
                        return (
                          <>
                            <div className="highlights_card" key={index}>
                              <div className="highlights_card_img d-flex justify-content-center align-items-center">
                                <img src={foodBeverage} />
                              </div>
                              <div className="highlights_card_content">
                                <h6>{item.facilitiesName}</h6>
                              </div>
                            </div>
                          </>
                        )
                      })}

                    </div>
                  </div>

                  <div className="sdp_amentities">
                    <div className="title mt-5">food & beverages</div>
                    <div className="subtitle">food & beverages available in {selectedPropertyDetail?.propertyName} </div>

                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="sdp_amentities_card d-flex flex-wrap justify-content-between">
                          {stayDetail?.includedFoodData && stayDetail?.includedFoodData.map((item, index) => {
                            return (
                              <div className="amentities_card d-flex align-items-center">
                                <div className="amentities_card_content"> <h5>{item?.foodName} (included)</h5></div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>

                  </div>

                  <div className="sdp_amentities">
                    <div className="title mt-5">hosted by</div>
                    <div className="subtitle">hosts of {selectedPropertyDetail?.propertyName} </div>
                    <HostedBy selectedPropertyDetail={selectedPropertyDetail} />
                  </div>

                  <section className="pdp06">
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="col-12">
                          <div class="title mt-5">things to know</div>
                          <div class="subtitle">important information about {selectedPropertyDetail?.propertyName}</div>
                          <div className="bsfi-head">
                            <div className="row align-items-center mt-5">
                              <div className="col-6">
                                <div className="checkin">
                                  <LastPageIcon />
                                  <div>
                                    <span className="text-muted fw-bold text-light d-inline-block">Check in</span>
                                    <h6>after {selectedPropertyDetail?.checkInCheckOutData?.checkInTime}</h6>
                                    {/* <h6>{bookingDetail?.checkInDate}</h6> */}
                                    <small className="text-light">Early check in might be available. Please message host for more
                                      info.
                                    </small>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="checkin">
                                  <FirstPageIcon />
                                  <div>
                                    <span className="text-muted fw-bold text-light d-inline-block">Check Out</span>
                                    <h6>before {selectedPropertyDetail?.checkInCheckOutData?.checkOutTime}</h6>
                                    {/* <h6>{bookingDetail?.checkOutDate}</h6> */}
                                    <small className="text-light">Early check out might be available. Please message host for
                                      more info.
                                    </small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row gap-xl-4 mt-lg-5 mt-4">
                        <div className="col-12">
                          <div className="campus_rules_card p-4 card">
                            <h5 className="mb-3 text-info"> <InfoIcon /> Camp rules</h5>
                            <div className="d-flex justify-content-between align-items-center gap-2">
                              <p>{selectedPropertyDetail?.campRules && selectedPropertyDetail?.campRules?.rule.join(", ")}</p>
                              <button className="btn-campusRules"> <ChevronRightOutlinedIcon /> </button>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 mt-lg-0 mt-4">
                          <div className="campus_rules_card campus_rules_card1 p-4 card">
                            <h5 className="mb-3 text-danger"> <InfoIcon /> Strict cancellation policy</h5>
                            <div className="d-flex justify-content-between align-items-center gap-2">
                              <p>{selectedPropertyDetail?.cancellationPolicy && selectedPropertyDetail?.cancellationPolicy?.policy.join(", ")}</p>
                              <button className="btn-campusRules"> <ChevronRightOutlinedIcon /> </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                </div>
              </div>

              <div className="col-lg-4">
                <div className={`guest_card_box sticky-top d-md-block ${bookingPopup === true && 'active'}`}>
                  <div className="guest_card_inner">
                    <div className="guest_card_top d-flex justify-content-between align-items-center">
                      <div className="gct_left">
                        <p class="ribbon">
                          <span class="text"><strong class="fw-bold"><span><CurrencyRupeeOutlinedIcon /></span>{stayDetail.priceRegular}/-</strong><small class="ribbon-small">{stayDetail.perPerson === true ? 'per person' : 'per stary'}</small></span>
                        </p>
                      </div>
                      <div className="gct_right">
                        {/* <h6>min persons: 2</h6> */}
                      </div>
                    </div>
                    <div className="guest_card_footer">
                      <form>
                        <div className="form-group floatLabel">
                          <div class="form-floating mb-3">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => {
                                setStartDate(convertDateToYYYYMMDD(date)); console.log('StartDate', convertDateToYYYYMMDD(date))
                                if (endDate != '') {
                                  if (convertDateToYYYYMMDD(date) >= endDate) {
                                    setEndDate('')
                                  }
                                }
                              }}
                              minDate={new Date()}
                              filterDate={(date) => !isDateDisabled(date)}
                              className="form-control cstmInp"
                              dateFormat="dd/MM/yyyy"

                            />
                            <label for="checkin">Check in</label>
                          </div>
                          <div class="form-floating mb-3 floatLabel">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => date != null ? setEndDate(convertDateToYYYYMMDD(date)) : setEndDate('')}
                              minDate={new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1))}
                              filterDate={(date) => !isDateDisabled(date)}
                              className="form-control cstmInp"
                              locale="es"
                              dateFormat="dd/MM/yyyy"
                              disabled={startDate === '' ? true : false}
                            />
                            <label for="checkout">Check out </label>
                          </div>
                        </div>

                        <div class="d-flex justify-content-evenly mb-3 gap-1">
                          <div class="form-floating">
                            <input
                              type="number"
                              class="form-control"
                              id="adult"
                              name="adult"
                              min={1}
                              onChange={(e) => setAduts(e.target.value)}
                              value={adults}
                            />
                            <label for="adult">Adult (13+)</label>
                          </div>
                          <div class="form-floating">
                            <input
                              type="number"
                              class="form-control"
                              id="young"
                              name="young"
                              min="0"
                              onChange={(e) => setChildren(e.target.value)}
                              value={children}
                            />
                            <label for="young">Young (4+)</label>
                          </div>
                          <div class="form-floating">
                            <input
                              type="number"
                              class="form-control"
                              id="pet"
                              name="pet"
                              min={0}
                              onChange={(e) => setPet(e.target.value)}
                              value={pet}
                            />
                            <label for="adult">Pet</label>
                          </div>
                        </div>
                      </form>
                      <div className="guest_input mt-2">
                        <button className='btn btn-lg btn-success w-100 text-uppercase' onClick={onSubmit} > Review & Book</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="alert alert-warning mt-3 ms-1 me-1" role="alert">
                  <strong>4</strong> persons are viewing this page now!
                </div>

                <div class="mobile-bottom-wrapper d-none">
                  <div class="m-auto d-flex justify-content-around mobile-bottom">
                    <h6 class="flex-grow-1 m-auto text-danger">
                      <CurrencyRupeeOutlinedIcon />{stayDetail.priceRegular}/-<small> {stayDetail.perPerson === true ? 'per person' : 'per stay'}</small>
                    </h6>
                    <button class="btn btn-success btn-sm ms-5" onClick={onClickBookingBtn} >BOOK NOW</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {selectedPropertyDetail?.events && selectedPropertyDetail?.events.length > 0 &&  <section className="edp02">
          <div className="container">
            <hr className="mt-5" />

            <div className="row">
              <div className="col-12">
                <div className="heading h2 mt-5 mb-4">Explore more @ {selectedPropertyDetail?.propertyName}</div>
                <div className="title">happening experience</div>
                <div className="subtitle">book upcoming events in {selectedPropertyDetail?.propertyName}</div>
                <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1">

                  {selectedPropertyDetail?.events ? selectedPropertyDetail?.events.map((item, index) => {
                    return (
                      <>
                        <div className="col" key={index}
                          onClick={() => onClickEvent(item)}
                        >

                          <div className="experience_card">
                            <div className="experience_card_image position-relative ">
                              <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || cardImg}`} className="img-fluid experienceImg01" alt="" />
                              <div class="experience_card_det d-flex justify-content-evenly">
                                <p class="ribbon flex-grow-1">
                                  <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular || 'Free'}</span>
                                </p>
                                <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                              </div>
                            </div>

                            <div className="experience_card_content">
                              <div className="d-flex justify-content-between flex-1">
                                <h4>{item?.experienceName}</h4>
                              </div>

                              <div class="d-lg-flex d-block flex-row event-bottom">
                                <div class="d-flex flex-row card_det">
                                  <CalendarMonthIcon className='me-2' />
                                  <small class="bold pe-lg-4 pe-0">{formattedDateDDMMMYYYY(item?.startDate)} To {formattedDateDDMMMYYYY(item?.endDate)}</small>
                                </div>
                                <button class="btn btn-outline-dark btn-sm align-self-baseline"><small>LIMITED SEATS</small></button>
                              </div>

                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }) : ''}
                </div>

              </div>
            </div>
          </div>
        </section>
}
        <section className="edp03 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-4 text-light h5">stays</div>
                <div className="subtitle">book your preferred accommodation at {selectedPropertyDetail?.propertyName}</div>
                <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4">
                  {selectedPropertyDetail?.stayTypeDetail ? selectedPropertyDetail?.stayTypeDetail.map((item, index) => {
                    return (
                      <>
                        <div className="col mb-4">
                          <div
                            className="stays_card_wrapper"
                            onClick={() => onClickStay(item)}
                          >
                            <div className="stays_card_image">
                              <img
                                // src={forestImg}
                                src={item?.stayImg ? `${BASE_URL}/${item?.stayImg}` : forestImg}
                                alt=""
                                className="img-fluid w-100 br16"
                              />
                            </div>
                            <div className="stays_card_content">
                              <div className="scc_top d-flex justify-content-start align-items-center gap-lg-3">
                                <div className="scc_top_left">

                                  <img src={treeIc} alt="" />
                                </div>
                                <div className="scc_top_right">
                                  <h6>{item?.stayType}</h6>
                                  <p> <CurrencyRupeeIcon />
                                    {item?.priceRegular || 0} <span>/ night</span>
                                  </p>
                                </div>
                              </div>
                              <small>HURRY ! Only 2 available</small>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }) : ''}
                </div>
              </div>
            </div>
          </div>
        </section>

        {selectedPropertyDetail?.experience && selectedPropertyDetail?.experience.length > 0 &&      <section className="edp04">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-4 text-light h5">experiences</div>
                <div className="subtitle">activities & experiences offered at {selectedPropertyDetail?.propertyName} or nearby</div>
                <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1">

                  {selectedPropertyDetail?.experience ? selectedPropertyDetail?.experience.map((item, index) => {
                    return (
                      <>
                        <div className="col mb-3" key={index} onClick={() => onClickExperience(item)}>


                          <div className="experience_card card-shadow">
                            <div className="experience_card_image position-relative ">
                              <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || card01}`} className="img-fluid experienceImg01" alt="" />

                              <div class="experience_card_det d-flex justify-content-evenly">
                                <p class="ribbon flex-grow-1">
                                  <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular || 'Free'}</span>
                                </p>
                                <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                              </div>
                            </div>

                            <div className="experience_card_content">
                              <div className="d-flex justify-content-between flex-1">
                                <div>
                                  <h4>{item?.experienceName}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  }) : ''}
                </div>
              </div>
            </div>
          </div>
        </section>}

        {selectedPropertyDetail?.addons && selectedPropertyDetail?.addons.length > 0 && <section className="edp05">
          <div className="container">
            <div className="row">
              <div className="3">
                <div className="mt-4 text-light h5">add on's</div>
                <div className="subtitle">helpful extras offered at of {selectedPropertyDetail?.propertyName}</div>
                <div className="row row-cols-lg-4 row-cols-md-3 row-cols-1">
                  {selectedPropertyDetail?.addons ? selectedPropertyDetail?.addons.map((item, index) => {
                    return (
                      <>
                        <div className="col mb-4">
                          <div className="serviceCardWrapper d-flex flex-column justify-content-start">
                            <div className="serviceHead">
                              <div className="serviceCardImg"><img src={khadaAadmi} /></div>
                              <h6 className="mt-3 mt-lg-4">{item?.serviceName} </h6>
                              <div className="serviceDesc">{item?.serviceDescription}</div>
                            </div>
                            <div className="d-flex justify-content-between align-items-end">
                              <div>
                                <p className="servicePrice m-0 mt-4"><CurrencyRupeeIcon /> {item?.price}/-</p>
                                <small>per person</small>
                              </div>
                              <button class="btn btn-done w-auto" onClick={() => addAddons(item)} >ADD</button>
                            </div>
                          </div>

                        </div>
                      </>
                    )
                  }) : ''}
                </div>
              </div>
            </div>
          </div>
        </section>
        }
        <section className="edp06">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-4 text-light h5">near by</div>
                <div className="subtitle">near by attractions of {selectedPropertyDetail?.propertyName}</div>
                <NearBy />

              </div>
            </div>
          </div>
        </section>

      </main>

      <Modal className='popupModal' isOpen={modal} toggle={toggleLogin} size="lg" centered={true}>
        {/* <Logi toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} language={language} getUsers={getUsers} /> */}
        <Login toggle={toggleLogin} />
      </Modal>

      <OffcanvasModal toggle={toggleCanvas} isOpen={isOpenCanvas} images={stayDetail?.stayImages} title={stayDetail?.stayType} />
    </>
  )
}

export default StayDetail;