import { useEffect, useState } from "react";
import { BASE_URL } from "../../services/config";
import '../../../src/assets/css/BookingSummary.css'
import { getLoggedData } from "../../helpers/Helper";
import authServices from "../../services/auth.services";

import logoImg from '../../../src/assets/img/logo.png'
import userProfile from '../../../src/assets/img/user-profile.png'
import backArrow from '../../../src/assets/img/back-arrow-ic.png'
import visitPeople from '../../../src/assets/img/people-ic-red.png'
import guestImg from '../../../src/assets/img/profile-frame.png'
import checkIn from '../../../src/assets/img/check-in-ic.png'
import checkOut from '../../../src/assets/img/check-out-ic.png'
import propertyImg from '../../../src/assets/img/tree-house-sm.png'
import pickupType from '../../../src/assets/img/taxi-ic.png'
import customerSupport from '../../../src/assets/img/support-ic-dark.png'
import { useNavigate, useParams } from "react-router-dom";

const StayBooking = ({ stayDetailData, selectedPropertyDetail, callBack, stayBookingData }) => {
  const [adults, setAdults] = useState(stayBookingData?.adults || 0);
  const [bookingDetail, setBookingDetail] = useState(stayBookingData)
  const [stayTotalPrice, setStayTotalPrice] = useState(0)
  const [clickBtn, setClickBtn] = useState('')
  const clickBtnPlusMinus = () => setClickBtn((prevState) => !prevState);
  const loggedUser = getLoggedData()
  const { id } = useParams();
    const navigate = useNavigate();
  console.log('loggedUser', loggedUser)
  const onSubmit = (e) => {
    // e.preventDefault();
    // const loggedUser = getLoggedData()
    console.log("refresh prevented", loggedUser);
    console.log("stayBookingData", stayBookingData)

    const reqBody = {
      userId: loggedUser?.userData?._id || '',
      userName: loggedUser?.userData?.name || '',
      primaryguestName: 'Balwan',
      primaryguestEmail: 'test@gmail.com',
      primaryguestPhone: '9758525154',
      ...stayBookingData
    }
    console.log('reqBody', reqBody)
    console.log('submitBooking', bookingDetail)
    // authServices.bookingApi(reqBody).then((result) => {
    //   console.log('result', result)
    // }).catch((error) => {
    //   console.log('error', error)
    // })
    navigate('/booking-confirmation/654865486f5s4d54f8654')
  };

  const onCLickPlus = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('plus clicked')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log('boookkkkk', booking.stayBooking)
      console.log('stayDetailBo', stayDetail)
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const stayBookingIdToFind = stayDetail._id;
      const foundStayBooking = findStayBookingById(booking.stayBooking, stayBookingIdToFind);
      if (foundStayBooking.length > 0) {
        console.log('found stay booking', booking)
        for (let i of booking.stayBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log('iiiiidddddssNEw', i)
            i.stayCount = foundStayBooking.length + i.stayCount
            let countAdults = adults
            let countAvailable = stayDetail.countAvailable
            let countCapacity = stayDetail.countCapacity
            let count = adults < countAvailable * countCapacity ? Math.round(countAdults / countCapacity) : countAvailable;
            let totalAvailableAndCapicty = countAvailable * countCapacity
            if (adults > countAvailable * countCapacity) {
              alert(`This Stay Capicity Only ${countAvailable * countCapacity} adults`)
              i.stayCount = count
            }
            if (i.stayCount > countAvailable) {
              i.stayCount = countAvailable
              alert(`This Stay Availeblty Only ${countAvailable}`)
            }
            console.log('i.stayCount', i.stayCount)
            console.log('countAvailable', countAvailable)
          }
        }
        localStorage.setItem('stayBooking', JSON.stringify(booking));
        setBookingDetail(booking)
      } else {
        console.log('no found stay booking', foundStayBooking)
      }
    }
  }
  const onClickMinus = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('minus clicked')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log('minusBooking', booking.stayBooking)
      console.log('minusStayDetailBo', stayDetail)
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const findStayBookingByIdAfterMinus = (array, id) => {
        return array.filter(booking => booking._id != id);
      };
      const stayBookingIdToFind = stayDetail._id;
      console.log('stayBookingIdToFind', stayBookingIdToFind)
      const foundStayBooking = findStayBookingById(booking.stayBooking, stayBookingIdToFind);
      const foundStayBookingAfterMinus = findStayBookingByIdAfterMinus(booking.stayBooking, stayBookingIdToFind)
      if (foundStayBooking.length > 0) {
        console.log('found stay booking', booking)
        for (let i of booking.stayBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log('iiiiidddddssNEw', i)
            i.stayCount = i.stayCount - 1
            if (i.stayCount < 1) {
              console.log('in less 1')
              // booking.stayBooking= foundStayBookingAfterMinus
              i.stayCount = 1
            }
            localStorage.setItem('stayBooking', JSON.stringify(booking));
            setBookingDetail(booking)
          }
        }
      } else {
        console.log('no found stay booking', foundStayBooking)
        booking.stayBooking = []
      }
      localStorage.setItem('stayBooking', JSON.stringify(booking));
      setBookingDetail(booking)
    }
  }

  const onCLickExpPlus = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('plus clicked exp')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const stayBookingIdToFind = stayDetail._id;
      const foundStayBooking = findStayBookingById(booking.experienceBooking, stayBookingIdToFind);
      if (foundStayBooking.length > 0) {
        console.log('found exp booking', booking)
        for (let i of booking.experienceBooking) {
          if (i._id === stayBookingIdToFind) {
            i.experienceCount = foundStayBooking.length + i.experienceCount
            console.log('i.exp', i.experienceCount)
          }
        }
        localStorage.setItem('stayBooking', JSON.stringify(booking));
        setBookingDetail(booking)
      } else {
        console.log('no found exp booking', foundStayBooking)
      }
    }
  }
  const onClickExpMinus = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('minus clicked')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log('minusExpBooking', booking.experienceBooking)
      console.log('minusStayDetailBo', stayDetail)
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const findStayBookingByIdAfterMinus = (array, id) => {
        return array.filter(booking => booking._id != id);
      };
      const stayBookingIdToFind = stayDetail._id;
      console.log('stayBookingIdToFind', stayBookingIdToFind)
      const foundStayBooking = findStayBookingById(booking.experienceBooking, stayBookingIdToFind);
      const foundStayBookingAfterMinus = findStayBookingByIdAfterMinus(booking.experienceBooking, stayBookingIdToFind)
      if (foundStayBooking.length > 0) {
        console.log('found stay booking', booking)
        for (let i of booking.experienceBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log('iiiiidddddssNEw', i)
            i.experienceCount = i.experienceCount - 1
            if (i.experienceCount < 1) {
              console.log('in less 1')
              // booking.stayBooking= foundStayBookingAfterMinus
              i.experienceCount = 1

            }
            localStorage.setItem('stayBooking', JSON.stringify(booking));
            setBookingDetail(booking)
          }
        }
      } else {
        console.log('no found stay booking', foundStayBooking)
        booking.experienceBooking = []
      }
      localStorage.setItem('stayBooking', JSON.stringify(booking));
      setBookingDetail(booking)
    }
  }


  const onCLickConv = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('plus clicked conv')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const stayBookingIdToFind = stayDetail._id;
      const foundStayBooking = findStayBookingById(booking.conveyenceBooking, stayBookingIdToFind);
      if (foundStayBooking.length > 0) {
        console.log('found conv booking', booking)
        for (let i of booking.conveyenceBooking) {
          if (i._id === stayBookingIdToFind) {
            i.conveyenceCount = foundStayBooking.length + i.conveyenceCount
            console.log('i.exp', i.conveyenceCount)
          }
        }
        localStorage.setItem('stayBooking', JSON.stringify(booking));
        setBookingDetail(booking)
      } else {
        console.log('no found conv booking', foundStayBooking)
      }
    }
  }

  const onClickConvMinus = (stayDetail) => {
    clickBtnPlusMinus()
    console.log('minus conv clicked')
    let existBooking = localStorage.getItem('stayBooking');
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log('minusExpBooking', booking.conveyenceBooking)
      console.log('minusStayDetailBo', stayDetail)
      const findStayBookingById = (array, id) => {
        return array.filter(booking => booking._id === id);
      };
      const findStayBookingByIdAfterMinus = (array, id) => {
        return array.filter(booking => booking._id != id);
      };
      const stayBookingIdToFind = stayDetail._id;
      console.log('stayBookingIdToFind', stayBookingIdToFind)
      const foundStayBooking = findStayBookingById(booking.conveyenceBooking, stayBookingIdToFind);
      const foundStayBookingAfterMinus = findStayBookingByIdAfterMinus(booking.conveyenceBooking, stayBookingIdToFind)
      if (foundStayBooking.length > 0) {
        console.log('found stay booking', booking)
        for (let i of booking.conveyenceBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log('iiiiidddddssNEw', i)
            i.conveyenceCount = i.conveyenceCount - 1
            if (i.conveyenceCount < 1) {
              console.log('in less 1')
              // booking.stayBooking= foundStayBookingAfterMinus
              i.conveyenceCount = 1

            }
            localStorage.setItem('stayBooking', JSON.stringify(booking));
            setBookingDetail(booking)
          }
        }
      } else {
        console.log('no found stay booking', foundStayBooking)
        booking.conveyenceBooking = []
      }
      localStorage.setItem('stayBooking', JSON.stringify(booking));
      setBookingDetail(booking)
    }
  }
  useEffect(() => {
    console.log('in herefff')
    const existBooking = localStorage.getItem('stayBooking');
    if (existBooking) {
      const booking = JSON.parse(existBooking);
      console.log('inUseEffect', booking)
      setBookingDetail(booking)

      let totalPrice = 0
      if (bookingDetail?.stayBooking.length > 0) {
        for (let item of bookingDetail?.stayBooking) {
          // console.log('priceTotal', i.price * i.stayCount)
          // totalPrice = totalPrice + i.price * i.stayCount
          let p = item.perPerson === true ? (Number(item.adults)*item.price) * item.stayCount:Number(item?.price) * Number(item?.stayCount)
          totalPrice = totalPrice + p
        }
      }
      if (bookingDetail?.experienceBooking){
      if (bookingDetail?.experienceBooking.length > 0) {
        for (let item of bookingDetail?.experienceBooking) {
          // console.log('priceTotal', i.price * i.stayCount)
          // totalPrice = totalPrice + i.price * i.stayCount
          let p = item.perPerson === true ? (Number(item.guest)*item.price) * item.experienceCount:Number(item?.price) * Number(item?.experienceCount)
          totalPrice = totalPrice + p
        }
      }
    }
    if (bookingDetail?.conveyenceBooking){
      if (bookingDetail?.conveyenceBooking.length > 0) {
        for (let item of bookingDetail?.conveyenceBooking) {
          // console.log('priceTotal', i.price * i.stayCount)
          // totalPrice = totalPrice + i.price * i.stayCount
          let p = item.price * item.conveyenceCount
          totalPrice = totalPrice + p
        }
      }
    }
      console.log('totallllll', totalPrice)
      setStayTotalPrice(totalPrice)
    }
  }, [clickBtn])
  return (
    <>


      {/* <div className="container p-4">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">
                <h5 onClick={callBack}>Request to book</h5>
              </div>
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h6>Arijit Majumder</h6>
                    <p>Email: Email@Email.Com</p>
                    <p>Phone: 9830572501</p>
                  </div>
                  <button className="btn btn-secondary">Edit</button>
                </div>
                <div>
                  <p>{Number(stayBookingData.adults) + Number(stayBookingData.children) + Number(stayBookingData.pet)} Persons ({stayBookingData.adults} adults + {stayBookingData.children} children + {stayBookingData.pet} pet)</p>
                </div>
                <div className="my-3">
                  <h6>Booking Details</h6>
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Check in</p>
                      <p>{stayBookingData.checkInDate}</p>
                    </div>
                    <div>
                      <p>Check out</p>
                      <p>{stayBookingData.checkOutDate}</p>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-header">

                      <h6>{stayBookingData.stayBooking.length} stays</h6>
                    </div>
                    <div className="card-body">
                      {bookingDetail.stayBooking.map((item, index) => {
                        return (
                          <>
                            <p>{item.stayType} <button onClick={()=>onClickMinus(item)}> - </button> {item.stayCount} <button onClick={() => onCLickPlus(item)}>+</button></p>
                            <p>Max Person {stayDetailData?.countCapacity}</p>
                          </>
                        )
                      })}

                      <button className="btn btn-outline-primary" onClick={callBack}>Suggest</button>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h6>0 experiences</h6>
                      <button className="btn btn-outline-primary">Suggest</button>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h6>0 events</h6>
                      <button className="btn btn-outline-primary">Suggest</button>
                    </div>
                  </div>
                  <div className="card mb-3">
                    <div className="card-body">
                      <h6>2 add ons</h6>
                      <p>Pick up: NJP Station - ₹3500.00</p>
                      <p>Drop: NJP Station - ₹3500.00</p>
                      <button className="btn btn-outline-primary">Suggest</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Price details</h5>
                <p>3 x Tree House @ ₹2500/-: ₹7500.00</p>
                <p>1 x Pickup @ ₹3500/-: ₹3500.00</p>
                <p>1 x Drop @ ₹3500/-: ₹3500.00</p>
                <p>2 x Hike to Mularkha Lake @ ₹1500/-: ₹3000.00</p>
                <p>Fees & Taxes: ₹1032.50</p>
                <h4>Total: ₹18532.50</h4>
                <button className="btn btn-success btn-block mb-2">Pay Now: ₹10000.00</button>
                <button className="btn btn-primary btn-block" onClick={onSubmit}>Confirm & Pay ₹10,000.00</button>
                <div className="mt-3">
                  <input type="text" className="form-control" placeholder="Referral or Promocode" />
                </div>
                <p className="mt-3">By continuing, I agree to the COVID-19 Safety Requirements and Terms and conditions</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}


      <div className="booking-summary-breadcrumb">
        {/* <div className="container-fluid">
          <div className="row justify-content-between align-items-center">
            <div className="col text-start">
              <a class="navbar-brand" href="/"><img src={logoImg} className="img-fluid" /></a>
            </div>

            <div className="col-md-6 align-self-end">
              <div className="breadcrumb-inner">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb flex-nowrap">
                    <li class="breadcrumb-item active" aria-current="page"><a href="#">BOOKING SUMMERY</a></li>
                    <li class="breadcrumb-item"><a href="#">PAYMENT</a></li>
                    <li class="breadcrumb-item">BOOKING CONFIRMATION</li>
                  </ol>
                </nav>
              </div>
            </div>

            <div className="col text-end">
              <a class="user-profile" href="/"><img src={userProfile} className="img-fluid" /></a>
            </div>
          </div>
        </div> */}

        {/* <div className="booking-summary-header">

        </div> */}
      </div>

      <div className="booking-summary-container">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="booking-summary-left">
                <div className="bsl-top">
                  <div className="d-flex justify-content-between align-items-center flex-lg-nowrap flex-wrap">
                    <button className="btn btn-back" onClick={callBack}> <img src={backArrow} /> Request to book</button>

                    <div className="people-visit-count">
                      <img src={visitPeople} />
                      21 people <span>viewing</span> Great Himalayan Outdoors - four season camps <span>now</span> !
                    </div>
                  </div>
                </div>

                <div className="booking_details_title">guest details</div>

                <div className="booking-summary-head">
                  <div className="guest_booking_details">
                    <div className="guest_booking_details_inner d-flex align-items-center">
                      <div className="guest_booking_details_inner_left">
                        <img src={guestImg} className="img-fluid" />
                      </div>
                      <div className="guest_booking_details_inner_right d-flex justify-content-between w-100">
                        <div>
                          <h5>{loggedUser?.userData?.name ||  loggedUser?.user?.name}</h5>
                          <h6> <a className="emil-id" href="#">{loggedUser?.userData?.email || loggedUser?.user?.email}</a> | <a className="phone-num" href="#">{loggedUser?.userData?.mobileNo || loggedUser?.user?.mobileNo}</a> </h6>
                        </div>
                        <div className="user-badge">Primary guest</div>
                      </div>
                    </div>
                    <div className="guest_booking_details01 d-flex align-items-center justify-content-between">
                      {/* <h6> <span className="totalPerson">{Number(bookingDetail.adults) + Number(stayBookingData.children) + Number(stayBookingData.pet)} Persons</span> {stayBookingData.adults} adults + {stayBookingData.children} children + {stayBookingData.pet}pet</h6> */}
                      <h6> <span className="totalPerson">{Number(bookingDetail?.adults) + Number(bookingDetail?.children) + Number(bookingDetail?.pet)} Persons</span> {bookingDetail?.adults} adults + {bookingDetail?.children} children + {bookingDetail?.pet}pet</h6>

                      <button className="btn signIn">Edit</button>
                    </div>

                  </div>
                </div>

                <div className="booking-summary-footer">
                  <div className="booking_details_title">booking details</div>

                  <div className="booking-summary-footer-inner booking-summary-box">
                    <div className="bsfi-head">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <div className="checkin">
                            <img src={checkIn} />
                            <div>
                              <span>Check in</span>
                              {/* <h6>29th Apr 2023</h6> */}
                              <h6>{bookingDetail?.checkInDate}</h6>
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="checkin">
                            <img src={checkOut} />
                            <div>
                              <span>Check Out</span>
                              {/* <h6>29th Apr 2023</h6> */}
                              <h6>{bookingDetail?.checkOutDate}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="bfi_stays_type">
                      <div className="bsf_title">3 stays</div>

                      <div className="d-flex align-items-center gap-2 bfi_stays_type_slider">
                        {bookingDetail?.stayBooking.map((item, index) => {
                          return (
                            <>
                              <div className="bfi_card_wrapper d-flex align-items-center gap-2">
                                <div className="bfi_card d-flex align-items-center">
                                  <div className="bfi_card_img">
                                    <img src={propertyImg} />
                                  </div>
                                  <div className="bfi_card_content">
                                    <div className="bfi_tag">{item.stayType}</div>
                                    <h6>{item.stayType}</h6>
                                    <div className="bfi_price">max {item.countCapacity} Person |  {item.price}/{item.perPerson === true ? 'person':'stay'}</div>
                                  </div>
                                </div>

                                <div className="bfi_actn-btn">
                                  <button className="btn btn-incDes" onClick={() => onClickMinus(item)}>-</button>
                                  <h5>{item?.stayCount}</h5>
                                  <button className="btn btn-incDes" onClick={() => onCLickPlus(item)}>+</button>
                                </div>
                              </div>
                            </>
                          )
                        })}

                      </div>

                    </div>

                    <div className="fun-type">
                      <div className="bsf_title">0 experinces</div>

                      {/* <div className="suggestion_box d-flex justify-content-center align-items-center text-center"> */}
                      {bookingDetail?.experienceBooking ?
                        bookingDetail?.experienceBooking.map((item, index) => {
                          return (
                            <>
                              <div className="d-flex align-items-center gap-2 bfi_stays_type_slider">
                                <div className="bfi_card_wrapper d-flex align-items-center gap-2">
                                  <div className="bfi_card d-flex align-items-center">
                                    <div className="bfi_card_img">
                                      <img src={propertyImg} />
                                    </div>
                                    <div className="bfi_card_content">
                                      <div className="bfi_tag">{item?.experienceName}</div>
                                      <h6>{item.experienceType}</h6>
                                      <div className="bfi_price">max {item?.maxPerson} Person | {item?.perPerson === true ? item?.price * item?.guest: item?.price} {item?.perPerson === true &&  '/person'}</div>
                                    </div>
                                  </div>

                                  {/* <div className="bfi_actn-btn">
                                    <button className="btn btn-incDes" onClick={() => onClickExpMinus(item)}>-</button>
                                    <h5>{item?.experienceCount}</h5>
                                    <button className="btn btn-incDes" onClick={() => onCLickExpPlus(item)}>+</button>
                                  </div> */}
                                </div>
                              </div>
                            </>
                          )
                        }) :
                        <><div className="suggestion_box d-flex justify-content-center align-items-center text-center">
                          <div className="suggestion_box_title text-uppercase" onClick={callBack}>Suggest</div>
                        </div>
                        </>
                      }

                      <div className="bsf_title">0 events</div>

                      <div className="suggestion_box d-flex justify-content-center align-items-center text-center">
                        <div className="suggestion_box_title text-uppercase">Suggest</div>
                      </div>

                    </div>

                    <div className="pickup-type conveyence">
                      <div className="bsf_title">Conveyence</div>
                      {bookingDetail?.conveyenceBooking ?
                        bookingDetail?.conveyenceBooking.map((item, index) => {
                          return (
                            <>

                            <div className="d-flex align-items-center gap-2 bfi_stays_type_slider">

                              <div className="bfi_card_wrapper d-flex align-items-center gap-2">
                                <div className="d-flex align-items-center gap-2">
                                  <div className="bfi_card d-flex align-items-center">
                                    <div className="bfi_card_img">
                                      <img src={pickupType} />
                                    </div>
                                    <div className="bfi_card_content">
                                      <div className="bfi_tag">Pick up</div>
                                      <h6>NJP STATION</h6>
                                      <div className="bfi_price">max 3 Person | 1500.00/person</div>
                                    </div>
                                  </div>
                                </div>
                                <div class="bfi_actn-btn"><button class="btn btn-incDes" onClick={()=>onClickConvMinus(item)}>-</button><h5>{item?.conveyenceCount}</h5><button class="btn btn-incDes" onClick={()=>onCLickConv(item)}>+</button></div>
                              </div>
                            </div>

                            </>
                          )
                        }) :
                        <div className="suggestion_box d-flex justify-content-center align-items-center text-center">
                          <div className="suggestion_box_title text-uppercase">Suggest</div>
                        </div>}
                    </div>


                    <div className="pickup-type">
                      <div className="bsf_title">2 add ons</div>

                      <div className="d-flex align-items-center gap-2">
                        <div className="bfi_card d-flex align-items-center">
                          <div className="bfi_card_img">
                            <img src={pickupType} />
                          </div>
                          <div className="bfi_card_content">
                            <div className="bfi_tag">Pick up</div>
                            <h6>NJP STATION</h6>
                            <div className="bfi_price">max 3 Person | 1500.00/person</div>
                          </div>
                        </div>

                        <div className="bfi_card d-flex align-items-center">
                          <div className="bfi_card_img">
                            <img src={pickupType} />
                          </div>
                          <div className="bfi_card_content">
                            <div className="bfi_tag">Drop</div>
                            <h6>NJP STATION</h6>
                            <div className="bfi_price">max 3 Person | 1500.00/person</div>
                          </div>
                        </div>
                      </div>

                      <div className="suggestion_box d-flex justify-content-center align-items-center text-center">
                        <div className="suggestion_box_title text-uppercase">Suggest</div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className="col">
              <div className="booking-summary-right">
                <div className="booking_details_title">Price details</div>

                <div className="price_detils_wrapper booking-summary-box">
                  <div className="price_head d-flex justify-content-between align-items-end">
                    <h6>TOTAL PRICE <br></br>
                      <span className="totalPrice">
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clip-rule="evenodd" d="M0.0276316 0V1.89583C1.07763 1.95417 3.75789 1.72083 4.17237 3.09167H0.0276316V4.78333H4.36579C4.36579 4.78333 3.78553 6.2125 2.32105 6.35833L0.0276316 6.41667L0 8.10833L4.80789 14H7.29474L2.76316 8.1375C2.76316 8.1375 5.63684 8.225 6.60395 4.69583L8.34474 4.75417V3.15H6.46579C6.46579 3.15 6.46579 2.39167 5.94079 1.6625H8.4V0H0.0276316Z" fill="#FF5733" /></svg>
                        {stayTotalPrice}/-
                      </span>
                    </h6>

                    <img src={customerSupport} />
                  </div>

                  {bookingDetail?.stayBooking ? bookingDetail?.stayBooking.map((item, index) => {
                      return (
                        <>
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>{item?.stayCount} x {item?.stayType} @{item?.price}/- {item.perPerson===true ? 'Per Person':'Per Stay'}</h6>
                                <div className="ppl_info">14/04/2022-15/04/2022 | {stayBookingData?.adults}P</div>
                              </div>

                              <div className="ppl-center">{item.perPerson === true ? (Number(item.adults)*item.price) * item.stayCount:Number(item?.price) * Number(item?.stayCount)}</div>

                              <div className="ppl-right"> <button className="btn btn-cancel">X</button> </div>
                            </div>
                          </div>
                        </>
                      )
                    }):''}
                     {bookingDetail?.experienceBooking ? bookingDetail?.experienceBooking.map((item, index) => {
                      return (
                        <>
                          <div className="ppl_box">
                            <div className="d-flex justify-content-between align-items-center">
                              <div className="ppl-left">
                                <h6>{item.experienceCount} x {item.experienceType} @{item.price}/- </h6>
                                <div className="ppl_info">14/04/2022-15/04/2022 | {item?.guest}P</div>
                              </div>

                              <div className="ppl-center">{Number(item.price) }</div>

                              <div className="ppl-right"> <button className="btn btn-cancel">X</button> </div>
                            </div>
                          </div>
                        </>
                      )
                    }):''}
                      
                    {bookingDetail?.conveyenceBooking ? bookingDetail?.conveyenceBooking.map((item, index) => {
                      return (
                        <>
                    <div className="ppl_box">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="ppl-left">
                          <h6>{item.conveyenceCount} x Pickup @{item.price}/-</h6>
                          <div className="ppl_info">14/04/2022 : 10.30 A.M | Bolero 6P</div>
                        </div>

                        <div className="ppl-center">{item.price*item.conveyenceCount }</div>

                        <div className="ppl-right"> <button className="btn btn-cancel">X</button> </div>
                      </div>
                    </div>
                    </>
                    )
                  }):''}

                  {/* <div className="price-range-wrapper">
                    <div className="price_box m-auto">
                      <h6>PAY NOW <br />
                        <span>10000.00</span>
                      </h6>
                    </div>

                    <div className="price-range-slider mt-md-2 mt-1">
                      <input type="range" min="0" max="100" step="5" />
                    </div>

                    <div className="dynamic_price">
                      <h6>PAY AT CHECK IN <br /> <span>8532.50</span> </h6>
                    </div>

                  </div> */}

                  <h6 className="promocode-link">Have <a href="#">Referral or Promocode</a> </h6>

                  <button className="btn btn-confirm" onClick={(e)=>onSubmit()}>Confirm & pay <span>{stayTotalPrice}</span>/-</button>

                  <p>By continuing, I agree to the  COVID-19 Safety Requirements and Terms and conditions</p>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default StayBooking;