

import googleIc from '../assets/img/logo google.png'
import fbIc from '../assets/img/facebook logo.png'
import { useEffect, useState } from 'react'
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom'
import MyTrip from '../components/MyTrip'
import userImg from '../assets/img/user-profile.png'
import tripCardImg from '../assets/img/trip-ic.png'
import authServices from '../services/auth.services'
import { getLoggedData } from '../helpers/Helper'
import Features from '../components/Comman/Features'
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import FiberManualRecordSharpIcon from '@mui/icons-material/FiberManualRecordSharp';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import EventSharpIcon from '@mui/icons-material/EventSharp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import UpdateProfileModal from '../components/MyAccount/UpdateProfileModal'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { BASE_URL } from '../services/config'
import axios from 'axios';

import dataNoFound from '../assets/img/data-no-found.png'

const MyAccount = () => {
    const [activeMenu, setActiveMenu] = useState('MY-TRIP')
    const [temporaryBookings, setTemporaryBookings] = useState([])
    const [temporaryExperienceBooking, setTemporaryExperienceBooking] = useState([])
    const [temporaryEventBooking, setTemporaryEventBooking] = useState([])
    const [profileDetail, setProfileDetail] = useState('')
    const [modal, setModal] = useState(false);
    const [modalProfilePhoto, setModalProfilePhoto] = useState(false);
    const [file, setFile] = useState('');
    const [profilePhoto, setProfilePhoto] = useState('');
    const toggle = () => setModal(!modal);
    const toggleProfilePhoto = () => setModalProfilePhoto(!modalProfilePhoto);
    const loggedUser = getLoggedData()
    const navigate = useNavigate();

    function handleChange(e) {
        if (e.target.files.length > 0) {
            setFile(URL.createObjectURL(e.target.files[0]));
        } else {
            setFile('')
        }
    }
    useEffect(() => {
        getAllTemporaryBooking()
        getAllExpTemporaryBooking()
        getAllEventTemporaryBooking()

    }, [])


    const onClickMenu = (value) => {
        setActiveMenu(value)
    }

    const logOut = () => {
        localStorage.removeItem('data')
        localStorage.removeItem('mobileNo')
        localStorage.removeItem('stayBooking')
        localStorage.removeItem('propertyDetail')
        localStorage.removeItem('experienceDetail')
        localStorage.removeItem('conveyenceDetail')
        localStorage.removeItem('stayBooking')
        localStorage.removeItem('stayDetail')
        localStorage.removeItem('profilePhotoUrl')

        handleSignOut()
        navigate('/')
    }
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            console.log('User signed out');
            //   window.location.reload()
        } catch (error) {
            console.error('Error during sign-out:', error);
        }
    };


    const getAllTemporaryBooking = () => {
        authServices.getAllCartTripBookingApi({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('bookingCartData', result.data.data)
                if (result.data.data) {
                    setTemporaryBookings(result.data.data)
                }

            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }

    const getAllExpTemporaryBooking = () => {
        authServices.getAllTemporaryExperienceBookingApi({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('experienceTemp', result.data.data)
                if (result.data.data) {
                    setTemporaryExperienceBooking(result.data.data)
                }
            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }

    const getAllEventTemporaryBooking = () => {
        authServices.eventTemporaryBookingByUserId({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('experienceTemp', result.data.data)
                if (result.data.data) {
                    setTemporaryEventBooking(result.data.data)
                }
            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }


    const deleteTemporaryBooking = (data) => {
        authServices.deleteTemporaryBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }

    const deleteTemporaryExpericenBooking = (data) => {
        authServices.deleteExperienceTemporaryBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllExpTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }

    const deleteTemporaryEventBooking = (data) => {
        authServices.deleteTemporaryEventBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllEventTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }


    const userProfileDetailById = async () => {
        const result = await authServices.profileDetailById({ id: loggedUser.user._id })
        console.log('resultProfile', result)
        if (result.status === 200) {
            setProfileDetail(result.data.data)
            setFile(result.data.data?.profilePhoto != null ? `${BASE_URL}/${result.data.data?.profilePhoto}` : '')
            localStorage.setItem('profilePhotoUrl', result.data.data?.profilePhoto)
            const button = document.getElementById("profileBtnf");
            if (button) {
                button.click();
            }
        }
    }
    const updateProfilePhoto = async (event) => {
        console.log('file', profilePhoto)
        event.preventDefault();
        const formData = new FormData()

        formData.append('file', profilePhoto)

        formData.append('id', profileDetail?._id)

        axios.post(`${BASE_URL}/auth/update-profile-photo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then(response => {
                console.log('File uploaded successfully:', response.data);
                userProfileDetailById()
                toggleProfilePhoto()
                setFile('')
            })
            .catch(error => {
                console.error('Error uploading file:', error);
            });
        // const result = await authServices.updateProfilePhoto(formData)
        // console.log('result', result)
        // if(result.status === 200){
        //     userProfileDetailById()
        //     toggleProfilePhoto()
        //     setFile('')
        // }
    }
    useEffect(() => {
        userProfileDetailById()
    }, [])

    return (
        <>
            <main className="myAccount-page">
                <section className="map01">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 text-center">
                                <div className="d-flex justify-content-md-center justify-content-start align-items-end w-100 gap-3 tabBtnWrapper overflow-x-auto py-2">
                                    <button className={`nav_btn ${activeMenu === 'MY-TRIP' ? 'active' : ''}`} onClick={() => onClickMenu('MY-TRIP')}>
                                        <MultipleStopIcon />
                                        <span className='d-block'>trips</span>
                                    </button>

                                    {/* <button className={`nav_btn ${activeMenu === 'CART' ? 'active' : ''}`} onClick={() => onClickMenu('CART')}>
                                        <ShoppingCartOutlinedIcon />
                                        <span className='d-block'>Cart</span>
                                    </button> */}

                                    <button className={`nav_btn ${activeMenu === 'PROFILE' ? 'active' : ''}`} onClick={() => onClickMenu('PROFILE')}>
                                        <SecurityOutlinedIcon />
                                        <span className='d-block'>profile </span>
                                    </button>

                                    <button className={`nav_btn ${activeMenu === 'SEQURITY' ? 'active' : ''}`} onClick={() => { onClickMenu('SEQURITY'); navigate('/policy') }}>
                                        <SecurityOutlinedIcon />
                                        <span className='d-block'>privacy & security</span>
                                    </button>

                                    <button className={`nav_btn ${activeMenu === 'HELP' ? 'active' : ''}`} onClick={() => onClickMenu('HELP')}>
                                        <FiberManualRecordSharpIcon />
                                        <span className='d-block'>help</span>
                                    </button>

                                    <button className="nav_btn" onClick={logOut}>
                                        <LogoutSharpIcon />
                                        <span className='d-block'>log out</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {activeMenu === 'PROFILE' && <section className="map01">
                    <div className="container">
                        <div className="row justify-content-center gx-lg-4">
                            <div className="col-lg-4 col-md-6 col-12 text-start">
                                <div className='profile-info-wrapper ms-lg-auto ms-0'>
                                    <div className='pi-title'>Profile info</div>
                                    <div className='pi-subtitle'>shared with the community & world wide web</div>

                                    <div className='profile-info-inner card br16'>
                                        <div className='profile_box d-flex align-items-center gap-lg-4 gap-3'>
                                            <div className='host-user-img'> <img src={profileDetail?.profilePhoto ? `${BASE_URL}/${profileDetail?.profilePhoto}` : userImg} alt='' className='img-fluid' /></div>
                                            <button className='btn btn-main' onClick={toggleProfilePhoto}>Change</button>
                                        </div>

                                        {/* <form className="myAccount_form">
                                        <div className="mb-3 position-relative">
                                            <label htmlFor="fullName" className="formLabel">full name</label>
                                            <input type="text" placeholder='JON Dohe' className="form-control" id="fullName" />
                                            <img src={userIc} alt="" className="inputIc" />
                                        </div>

                                        <div className="mb-3 position-relative">
                                            <label htmlFor="fullName" className="formLabel">about you</label>
                                            <textarea className="form-control" placeholder='Write something...' rows="3"></textarea>
                                        </div>

                                    </form> */}

                                        <div class="mt-4" >
                                            <div class="form-floating mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="username"
                                                    id="username"
                                                    autocomplete="name"
                                                    value={profileDetail?.name}
                                                    disabled
                                                />
                                                <label for="username">full name</label>
                                            </div>
                                            <div class="form-floating">
                                                <textarea
                                                    className="form-control mb-4"
                                                    name="about"
                                                    id="about"
                                                    rows="4"
                                                    value={profileDetail?.userDesc}
                                                    disabled
                                                >
                                                    {profileDetail?.userDesc}
                                                </textarea>
                                                <label for="about">about me</label>
                                                <button className="btn btn-outline-warning" onClick={toggle}>EDIT</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-12 text-start mt-md-0 mt-4">
                                <div className='profile-info-wrapper'>
                                    <div className='pi-title'>Contact info</div>
                                    <div className='pi-subtitle text-danger'>shared with community (guest, host, co-host & furut success team) only if  you book and/or if required</div>

                                    <form className="myAccount_form myAccount_form2 mt-3 card br16" autocomplete="on">


                                        <div class="form-floating mb-2">
                                            <input
                                                type="tel"
                                                class="form-control"
                                                name="phone"
                                                id="phone"
                                                value={`+91${profileDetail?.mobileNo}`}
                                                disabled
                                            />
                                            <label for="phone">phone number</label>
                                        </div>
                                        <div class="form-floating mb-2">
                                            <input
                                                type="tel"
                                                class="form-control"
                                                id="altphone"
                                                autocomplete="t"
                                                disabled
                                                value={`${profileDetail?.alternativePhoneNumber === null ? '' : profileDetail?.alternativePhoneNumber}`}
                                            />
                                            <label for="altphone">alternative phone number</label>
                                        </div>
                                        <div class="form-floating mb-2">
                                            <input
                                                type="email"
                                                class="form-control"
                                                id="Email"
                                                value={profileDetail?.email}
                                                disabled
                                            />
                                            <label for="Email">email id</label>
                                        </div>
                                        <div class="form-floating mb-2">
                                            <input
                                                type="text"
                                                class="form-control"
                                                id="address"
                                                value={profileDetail?.streetAddress}
                                                disabled
                                            />
                                            <label for="address">street address</label>
                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>

                        <div className='row justify-content-center'>
                            {/* <div className='col-lg-4 col-md-6 col-12'>
                                <div className='piw-footer'>
                                    <div className='piw-footer-card'>
                                        <div className='pi-title'>Social info</div>
                                        <div className='pi-subtitle text-danger'>shared with furut success team only if required</div>
                                        <div className='piw-card card br16'>
                                            <h5>Connect  social</h5>
                                            <p>for one click/tap log in</p>
                                            <div className='d-flex justify-content-start align-items-center mt-md-4 mt-2'>
                                                <button className='btn btn-social border-0 text-center active'>
                                                    <span className='socialIc'> <img src={googleIc} /> </span>
                                                </button>

                                                <button className='btn btn-social border-0 text-center'>
                                                    <span className='socialIc'> <img src={fbIc} /> </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div> */}
                            <div className='col-lg-4 col-md-6 col-12'>
                                <div className='piw-footer-card'>
                                    <div className='pi-title'>Government ID</div>
                                    <div className='pi-subtitle text-danger'>shared with furut success team only if required</div>
                                    <div className='piw-card text-md-start text-center card br16'>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>}

                {activeMenu === "MY-TRIP" && <MyTrip />}


                {/* {activeMenu === 'CART' && <>

                    {temporaryBookings?.map((item, index) => {
                        return (
                            <>
                                <section>
                                    <div className='container'>

                                        <div className="upcoming-trips-rgt d-md-flex d-block align-items-start justify-content-between gap-2 w-100 mb-4" key={index}>
                                            <div className='utr_trip_card col-lg-4 col-md-6 col-12'>

                                                <div className='utr_trip_card_inner d-flex flex-column'>
                                                    <div className='utr_card_head d-flex justify-content-between align-items-center'>
                                                        <h6> <EventSharpIcon /> {item?.booking?.checkInDate}</h6>
                                                    </div>

                                                    <div className='utr_card_center d-flex justify-content-between align-items-center'>
                                                        <h3>Trip Booking ID: {item._id}</h3>
                                                        <img src={tripCardImg} className='img-fluid' />
                                                    </div>

                                                    <div className='utr_card_footer d-flex justify-content-between align-items-center'>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => navigate(`/booking/${item._id}`)}>Continue to booking</button>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => deleteTemporaryBooking(item)}>Remove</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    })}

                    {temporaryExperienceBooking?.map((item, index) => {
                        return (
                            <>
                                <section>
                                    <div className='container'>

                                        <div className="upcoming-trips-rgt d-md-flex d-block align-items-start justify-content-between gap-2 w-100 mb-4" key={index}>
                                            <div className='utr_trip_card'>

                                                <div className='utr_trip_card_inner d-flex flex-column'>
                                                    <div className='utr_card_head d-flex justify-content-between align-items-center'>
                                                        <h6> <EventSharpIcon /> {item?.booking?.checkInDate} : Exp</h6>
                                                    </div>

                                                    <div className='utr_card_center d-flex justify-content-between align-items-center'>
                                                        <h3>Trip Booking ID: {item._id}</h3>
                                                        <img src={tripCardImg} className='img-fluid' />
                                                    </div>

                                                    <div className='utr_card_footer d-flex justify-content-between align-items-center'>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => navigate(`/experiences/booking/confirmation/${item._id}`)}>Continue to booking</button>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => deleteTemporaryExpericenBooking(item)}>Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    })}

                    {temporaryEventBooking?.map((item, index) => {
                        return (
                            <>
                                <section>
                                    <div className='container'>

                                        <div className="upcoming-trips-rgt d-md-flex d-block align-items-start justify-content-between gap-2 w-100 mb-4" key={index}>
                                            <div className='utr_trip_card'>

                                                <div className='utr_trip_card_inner d-flex flex-column'>
                                                    <div className='utr_card_head d-flex justify-content-between align-items-center'>
                                                        <h6> <EventSharpIcon /> {item?.booking?.checkInDate} : Event</h6>
                                                    </div>

                                                    <div className='utr_card_center d-flex justify-content-between align-items-center'>
                                                        <h3>Trip Booking ID: {item._id}</h3>
                                                        <img src={tripCardImg} className='img-fluid' />
                                                    </div>

                                                    <div className='utr_card_footer d-flex justify-content-between align-items-center'>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => navigate(`/events/booking/confirmation/${item._id}`)}>Continue to booking</button>
                                                        <button className='btn btn-done text-uppercase w-auto' onClick={() => deleteTemporaryEventBooking(item)}>Remove</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>
                        )
                    })}
                    {temporaryExperienceBooking.length === 0 && temporaryBookings.length === 0 && temporaryEventBooking.length === 0 ? <>
                        <section className='noDataFound mt-5'>
                            <div className='container'>
                                <div className='row justify-content-center'>
                                    <div className='col-xl-6 col-lg-8 col-12 text-center card pb-5'>
                                        <img src={dataNoFound} className='img-fluid' />
                                        <h1 className='text-danger'>No Data Found</h1>
                                    </div>
                                </div>
                            </div>
                            </section>
                    </> : ''
                    }
                </>
                } */}


            </main>
            {/* <Features /> */}

            <Modal
                isOpen={modal}
                toggle={toggle}
                size="lg"
                centered={true}
                className="editModalPopup"
            >
                <UpdateProfileModal
                    toggle={toggle}
                    type={"add"}
                    title={"update profile detail"}
                    profileDetail={profileDetail}
                    userProfileDetailById={userProfileDetailById}
                />
            </Modal>


            <Modal
                isOpen={modalProfilePhoto}
                toggle={toggleProfilePhoto}
                size="sm"
                centered={true}
                className="editModalPopup"
            >
                <form encType="multipart/form-data" onSubmit={updateProfilePhoto}>
                    <ModalHeader className='pb-2' toggle={toggleProfilePhoto}> Update Profile Photo </ModalHeader>
                    <ModalBody className='editModal'>
                        <div className="col-12 mb-3">
                            <label htmlFor="file" className="form-label">
                                Upload Image
                            </label>

                            <input id="file" name="file" type="file" onChange={(event) => {
                                setFile(event.currentTarget.files[0]);
                                setProfilePhoto(event.currentTarget.files[0])
                                handleChange(event)
                            }} className="form-control" />

                            {file === undefined || file === '' ? '' : <img src={file}
                                // alt={`${BASE_URL}/${profileDetail?.profilePhoto}`}
                                className="img-thumbnail mt-2"
                                height={150}
                                width={150} />}

                        </div>
                    </ModalBody>
                    <ModalFooter className='justify-content-between'>
                        <Button className='btn btn-confirm w-auto d-inline-block border-0' type="submit">
                            Update
                        </Button>{' '}
                        <Button className='btn signIn' onClick={toggleProfilePhoto}>
                            CANCEL
                        </Button>
                    </ModalFooter>
                </form>
            </Modal>
        </>
    )
}

export default MyAccount;