import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';


const Layout = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (window.location.pathname === '/testigo') {
  //     navigate("home");
  //   }
  // }, []);

  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}
export default Layout;