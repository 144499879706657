import { Button, Offcanvas, OffcanvasHeader, OffcanvasBody } from 'reactstrap';
import { BASE_URL } from '../../services/config';
import DateRangeIcon from '@mui/icons-material/DateRange';
import authServices from '../../services/auth.services';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoggedData } from '../../helpers/Helper';
const CartOffcanvas = ({ toggle, isOpen, images, title }) => {
    const [temporaryBookings, setTemporaryBookings] = useState([])
    const [temporaryExperienceBooking, setTemporaryExperienceBooking] = useState([])
    const [temporaryEventBooking, setTemporaryEventBooking] = useState([])

    const navigate = useNavigate();
    const loggedUser = getLoggedData()
    const getAllTemporaryBooking = () => {
        authServices.getAllCartTripBookingApi({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('bookingCartData', result.data.data)
                if (result.data.data) {
                    setTemporaryBookings(result.data.data)
                }

            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }

    const getAllExpTemporaryBooking = () => {
        authServices.getAllTemporaryExperienceBookingApi({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('experienceTemp', result.data.data)
                if (result.data.data) {
                    setTemporaryExperienceBooking(result.data.data)
                }
            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }

    const getAllEventTemporaryBooking = () => {
        authServices.eventTemporaryBookingByUserId({ userId: loggedUser?.user?._id })
            .then((result) => {
                console.log('experienceTemp', result.data.data)
                if (result.data.data) {
                    setTemporaryEventBooking(result.data.data)
                }
            })
            .catch((error) => {
                console.log('errorBookingCart', error)
            })
    }


    const deleteTemporaryBooking = (data) => {
        authServices.deleteTemporaryBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }

    const deleteTemporaryExpericenBooking = (data) => {
        authServices.deleteExperienceTemporaryBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllExpTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }

    const deleteTemporaryEventBooking = (data) => {
        authServices.deleteTemporaryEventBookingById({ id: data._id }).then((result) => {
            console.log('result', result)
            getAllEventTemporaryBooking()
        }).catch((error) => {
            console.log('error', error)
        })
    }

    useEffect(() => {
        getAllTemporaryBooking()
        getAllExpTemporaryBooking()
        getAllEventTemporaryBooking()
    }, [isOpen])

    return (
        <div >
            <Offcanvas className='offCanvas1' isOpen={isOpen} toggle={toggle} direction="end" >
                <OffcanvasHeader toggle={toggle}>
                    <h5 id="PendingBookings">Pending Bookings</h5>
                </OffcanvasHeader>
                <OffcanvasBody>
                    {temporaryBookings.map((item, index) => {
                        return (
                            <div className="trip-card" key={index}>
                                <div className="trip-card-body card mt-2">
                                    <div className="trip-card-head d-flex justify-content-between">
                                        <small><i className="material-symbols-outlined"><DateRangeIcon /></i>{item?.booking?.checkInDate}</small>
                                    </div>
                                    <h5 className="trip-card-title mt-5 mb-5 w-100">
                                        {/* {`Propertyname`}/{`ExpName`}/{`EventName`} */}
                                        {item?.propertyName}
                                    </h5>
                                    <div className="trip-card-foot d-flex justify-content-between">
                                        <a className="btn  btn-danger" onClick={() => navigate(`/booking/${item._id}`)}>Book now</a>
                                        <a className="btn  btn-outline-danger" onClick={() => deleteTemporaryBooking(item)}>Remove</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {temporaryExperienceBooking.map((item, index) => {
                        return (
                            <div className="trip-card" key={index}>
                                <div className="trip-card-body card mt-2">
                                    <div className="trip-card-head d-flex justify-content-between">
                                        <small><i className="material-symbols-outlined"><DateRangeIcon /></i>{item?.booking?.checkInDate}</small>
                                    </div>
                                    <h5 className="trip-card-title mt-5 mb-5 w-100">
                                        {item?.experienceName}

                                    </h5>
                                    <div className="trip-card-foot d-flex justify-content-between">
                                        <a className="btn  btn-danger" onClick={() => navigate(`/experiences/booking/confirmation/${item._id}`)}>Book now</a>
                                        <a className="btn  btn-outline-danger" onClick={() => deleteTemporaryExpericenBooking(item)}>Remove</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {temporaryEventBooking.map((item, index) => {
                        return (
                            <div className="trip-card" key={index}>
                                <div className="trip-card-body card mt-2">
                                    <div className="trip-card-head d-flex justify-content-between">
                                        <small><i className="material-symbols-outlined"><DateRangeIcon /></i>{item?.booking?.checkInDate}</small>
                                    </div>
                                    <h5 className="trip-card-title mt-5 mb-5 w-100">
                                        {/* {`Propertyname`}/{`ExpName`}/{`EventName`} */}
                                        {item?.experienceName}
                                    </h5>
                                    <div className="trip-card-foot d-flex justify-content-between">
                                        <a className="btn  btn-danger" onClick={() => navigate(`/events/booking/confirmation/${item._id}`)}>Book now</a>
                                        <a className="btn  btn-outline-danger" onClick={() => deleteTemporaryEventBooking(item)}>Remove</a>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </OffcanvasBody>
            </Offcanvas>
        </div>
    )
}

export default CartOffcanvas;