import { useEffect, useState } from "react";
import { BASE_URL } from "../../services/config";
import "../../../src/assets/css/BookingSummary.css";
import { formattedDateDDMMMYYYY, getLoggedData } from "../../helpers/Helper";
import authServices from "../../services/auth.services";

import logoImg from "../../../src/assets/img/logo.png";
import userProfile from "../../../src/assets/img/user-profile.png";
import backArrow from "../../../src/assets/img/back-arrow-ic.png";
import visitPeople from "../../../src/assets/img/people-ic-red.png";
import guestImg from "../../../src/assets/img/profile-frame.png";
import checkIn from "../../../src/assets/img/check-in-ic.png";
import checkOut from "../../../src/assets/img/check-out-ic.png";
import propertyImg from "../../../src/assets/img/tree-house-sm.png";
import pickupType from "../../../src/assets/img/taxi-ic.png";
import customerSupport from "../../../src/assets/img/support-ic-dark.png";
import { useNavigate, useParams } from "react-router-dom";
import BookingModal from "../../components/Booking/BookingModal";
import { Modal } from "reactstrap";
import axios from "axios";
import ConfirmationModal from "../../components/Comman/ConfirmationModal";
import BookingConfirmAmtModal from "../../components/Booking/BookingConfirmAmountModal";
import khadaAadmi from "../../assets/img/khada-aadmi.png";
import { toast } from "react-toastify";
import stayListImg from "../../assets/img/stay-list-img.jpg"
import MoreVertIcon from '@mui/icons-material/MoreVert';
// MATERIAL ICONS IMPORT
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import AddGuestModal from "../../components/Booking/AddGuestModal";

const ExperienceBookingConfirmation = () => {
  const [adults, setAdults] = useState();
  const [bookingDetail, setBookingDetail] = useState("");
  const [stayTotalPrice, setStayTotalPrice] = useState(0);
  const [clickBtn, setClickBtn] = useState("");
  const [modal, setModal] = useState(false);
  const [primaryguestName, setPrimaryguestName] = useState("");
  const [primaryguestEmail, setPrimaryguestEmail] = useState("");
  const [primaryguestPhone, setPrimaryguestPhone] = useState("");
  const [amountPayble, setAmountPayble] = useState(0);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmPayModal, setConfirmPayModal] = useState(false);
  const [price, setPrice] = useState(10); // Initial value set to min
  const [amtPayAtCheckIn, setAmtPayAtCheckIn] = useState(0);
  const [temporaryTripBookingId, setTemporaryTripBookingId] = useState('')
  const [profilePhotUrl, setProfilePhotoUrl] = useState(localStorage.getItem('profilePhotoUrl'))
  const toggleConfirm = () => setConfirmModal(!confirmModal);
  const toggleConfirmPay = () => setConfirmPayModal(!confirmPayModal);

  const toggle = () => setModal(!modal);
  const clickBtnPlusMinus = () => setClickBtn((prevState) => !prevState);
  const loggedUser = getLoggedData();
  const { id } = useParams();
  const navigate = useNavigate();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setPrice(newValue);
    let amountAtCheckIntime = stayTotalPrice - newValue;
    setAmtPayAtCheckIn(amountAtCheckIntime);
  };
  const bookingSubmit = () => {
    // e.preventDefault();
    // const loggedUser = getLoggedData()
    console.log("refresh prevented", loggedUser);
    console.log("amount", amountPayble);
    bookingDetail.userId = loggedUser?.user?._id || "";
    bookingDetail.totalPrice = stayTotalPrice;
    const reqBody = {
      userId: loggedUser?.user?._id || "",
      userName: loggedUser?.user?.name || "",
      primaryguestName: primaryguestName,
      primaryguestEmail: primaryguestEmail,
      primaryguestPhone: primaryguestPhone,
      totalPrice: stayTotalPrice,

      //   ...stayBookingData
      ...bookingDetail,
    };
    console.log("reqBody", reqBody);
    console.log("submitBooking", bookingDetail);
    authServices
      .addExperienceBookingApi(reqBody)
      .then((result) => {
        console.log("result", result);
        if (result.status === 200) {
          // navigate(`/booking-confirmation/${result.data.data._id}`)
          authServices
            .deleteExperienceTemporaryBookingById({ id: id })
            .then((resultDelete) => {
              console.log("resultDelete", resultDelete);
            });
          handlePayment(result.data.data);
        }
        if (result.status === 404) {
          toast.error("Not avalable any capicity", {
            toastId: "booking_fail",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error("Booking Faild", {
          toastId: "login_fail",
          autoClose: 3000,
        });
      });
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const handlePayment = async (bookingData) => {
    try {
      const res = await loadRazorpayScript();
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      console.log("amountPayble", price);
      const orderResult = await axios.post(`${BASE_URL}/booking/create-order`, {
        amount: Number(price),
      });
      const {
        amount: orderAmount,
        id: orderId,
        currency,
      } = orderResult.data.data;

      const options = {
        key: "rzp_test_sCsqjUV0yDnb67", // Enter the Key ID generated from the Dashboard
        amount: orderAmount,
        currency: currency,
        name: "Your Company Name",
        description: "Test Transaction",
        order_id: orderId,
        handler: function (response) {
          // alert(`Payment ID: ${response.razorpay_payment_id}`);
          // alert(`Order ID: ${response.razorpay_order_id}`);
          // alert(`Signature: ${response.razorpay_signature}`);
          const reqBody = {
            tripBookingId: bookingData._id,
            amount: price,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
          authServices
            .createTransaction(reqBody)
            .then((result) => {
              console.log("result", result);
              if (result.status === 200) {
                localStorage.removeItem("propertyDetail");
                localStorage.removeItem("experienceDetail");
                localStorage.removeItem("conveyenceDetail");
                localStorage.removeItem("stayBooking");
                localStorage.removeItem("stayDetail");
                localStorage.removeItem("stayBooking");
                navigate(`/booking-confirmation/${bookingData._id}`);
              }
            })
            .catch((error) => {
              console.log("errorTransaction", error);
            });
          // navigate(`/booking-confirmation/${bookingData._id}`)
        },
        // prefill: {
        //   name: 'Your Name',
        //   email: 'email@example.com',
        //   contact: '9999999999',
        // },
        notes: {
          address: "Your Company Address",
        },
        theme: {
          color: "#F37254",
        },
        modal: {
          ondismiss: function () {
            console.log("Checkout form closed");
            alert("Payment cancelled by user.");
          },
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        console.log("paymentFailedError", response.error);
        alert(
          `Payment failed.\nError code: ${response.error.code}\nError description: ${response.error.description}`
        );
      });
      paymentObject.open();
    } catch (error) {
      console.error("Error in handlePayment:", error);
      alert(
        "An error occurred while processing the payment. Please try again."
      );
    }
  };
  const onCLickPlus = (stayDetail) => {
    console.log("plus clicked");
    let booking = bookingDetail;
    if (booking) {
      console.log("boookkkkk", booking.stayBooking);
      console.log("stayDetailBo", stayDetail);
      const findStayBookingById = (array, id) => {
        return array.filter((booking) => booking.stayId === id);
      };
      const stayBookingIdToFind = stayDetail.stayId;
      const foundStayBooking = findStayBookingById(
        booking.stayBooking,
        stayBookingIdToFind
      );
      if (foundStayBooking.length > 0) {
        console.log("found stay booking", booking);
        for (let i of booking.stayBooking) {
          if (i.stayId === stayBookingIdToFind) {
            console.log("iiiiidddddssNEw", foundStayBooking.length);
            let countAdults = i.adults;
            let countAvailable = stayDetail.countAvailable;
            let countCapacity = stayDetail.countCapacity;
            let count =
              i.adults < countAvailable * countCapacity
                ? Math.round(countAdults / countCapacity)
                : countAvailable;
            if (stayDetail.perPerson === true) {
              i.adults = i.adults + 1
              // setAdults(i.adults)
              i.stayCount = count
            } else {
              i.stayCount = i.stayCount + 1;
            }

            let totalAvailableAndCapicty = countAvailable * countCapacity;
            if (i.adults > countAvailable * countCapacity) {
              alert(
                `This Stay Capicity Only ${countAvailable * countCapacity
                } adults`
              );
              i.stayCount = count;
              return false

            }
            if (i.stayCount > countAvailable) {
              i.stayCount = countAvailable;
              alert(`This Stay Availeblty Only ${countAvailable}`);
              return false
            }
            console.log("i.stayCount", i.stayCount);
            console.log("countAvailable", countAvailable);
          }
        }
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
        addBookingInCart(booking);
        // setBookingDetail(booking)
      } else {
        console.log("no found stay booking", foundStayBooking);
      }
    }
  };
  const onClickMinus = (stayDetail) => {
    console.log("minus clicked");
    let booking = bookingDetail;

    if (booking) {
      console.log("minusBooking", booking.stayBooking);
      console.log("minusStayDetailBo", stayDetail);

      const findStayBookingById = (array, id) => {
        return array.find((booking) => booking.stayId === id);
      };

      const stayBookingIdToFind = stayDetail.stayId;
      console.log("stayBookingIdToFind", stayBookingIdToFind);
      const foundStayBooking = findStayBookingById(
        booking.stayBooking,
        stayBookingIdToFind
      );

      if (foundStayBooking) {
        console.log("found stay booking", booking);
        for (let i of booking.stayBooking) {
          if (i.stayId === stayBookingIdToFind) {
            console.log("iiiiidddddssNEw", i);
            // i.stayCount = i.stayCount - 1;
            if (stayDetail.perPerson === true) {
              i.adults = i.adults - 1
              let countAdults = i.adults;
              let countAvailable = stayDetail.countAvailable;
              let countCapacity = stayDetail.countCapacity;
              let count =
                i.adults < countAvailable * countCapacity
                  ? Math.round(countAdults / countCapacity)
                  : countAvailable;
              i.stayCount = count
            } else {
              i.stayCount = i.stayCount - 1;
            }
            if (i.stayCount < 1) {
              console.log("in less 1");
              booking.stayBooking = booking.stayBooking.filter(
                (booking) => booking.stayId !== stayBookingIdToFind
              );
            }
            addBookingInCart(booking);
            break;
          }
        }
      } else {
        console.log("no found stay booking", foundStayBooking);
        booking.stayBooking = [];
      }
      addBookingInCart(booking);
    }
  };

  const removeStay = (stayDetail) => {
    let booking = bookingDetail;
    if (booking.stayBooking.length > 0) {
      booking.stayBooking = booking.stayBooking.filter(booking => booking.stayId !== stayDetail.stayId);
    }
    console.log('booking.stayBooking', booking.stayBooking)
    addBookingInCart(booking);
  }

  const onCLickExpPlus = (item) => {
    // clickBtnPlusMinus();
    console.log("plus clicked exp");
    let booking = bookingDetail;
    console.log('booking', booking)
  };

  function updateAdultsCountByExperienceId(experienceId, increment = true) {
    let booking = bookingDetail;
    if (booking.experienceBooking.length > 0) {
      for (let i of booking.experienceBooking) {
        if (i.experienceId === experienceId) {
          console.log('i', i)
          if (increment === true) {
            if (i.perPerson === true) {
              if(Number(i.adults) === Number(i.maxPerson)){
                toast.error(`Max person limit ${i.maxPerson} person`, { toastId: "login_fail", autoClose: 3000 });
                return false
              }
              i.adults = Number(i.adults) + 1
            } else {
              i.experienceCount = i.experienceCount + 1
            }
          } else {
            if (i.perPerson === true) {
              if(Number(i.adults) === Number(i.minPerson)){
                toast.error(`Min person limit ${i.minPerson} person`, { toastId: "login_fail", autoClose: 3000 });
                return false
              }
              i.adults = Number(i.adults) - 1
            } else {
              i.experienceCount = Number(i.experienceCount) - 1
            }
          }
        }

        if (i.adults < 1) {
          console.log("in less 1");
          if (i.children < 1) {
            booking.experienceBooking = booking.experienceBooking.filter(
              (booking) => booking.experienceId !== experienceId
            );
          } else {
            i.adults = 0
          }
        }

        if (i.experienceCount < 1) {
          booking.experienceBooking = booking.experienceBooking.filter(
            (booking) => booking.experienceId !== experienceId
          );
        }
      }
    }
    console.log('bookingExpInc', booking.experienceBooking)
    addBookingInCart(booking);
  }

  const removeExperience = (experienceId) => {
    let booking = bookingDetail;
    if (booking.experienceBooking.length > 0) {
      booking.experienceBooking = booking.experienceBooking.filter(booking => booking.experienceId !== experienceId);
    }
    console.log('booking.experienceBooking', booking.experienceBooking)
    addBookingInCart(booking);
  }

  function updateAdultsCountByEventId(experienceId, increment = true) {
    let booking = bookingDetail;
    if (booking.eventBooking.length > 0) {
      for (let i of booking.eventBooking) {
        if (i.experienceId === experienceId) {
          console.log('i', i)
          if (increment === true) {
            if (i.perPerson === true) {
              i.adults = i.adults + 1
            } else {
              i.experienceCount = i.experienceCount + 1
            }
          } else {
            if (i.perPerson === true) {
              i.adults = i.adults - 1
            } else {
              i.experienceCount = i.experienceCount - 1
            }
          }
        }

        if (i.adults < 1) {
          console.log("in less 1");
          if (i.children < 1) {
            booking.experienceBooking = booking.experienceBooking.filter(
              (booking) => booking.experienceId !== experienceId
            );
          } else {
            i.adults = 0
          }
        }

        if (i.experienceCount < 1) {
          booking.experienceBooking = booking.experienceBooking.filter(
            (booking) => booking.experienceId !== experienceId
          );
        }
      }
    }
    console.log('bookingExpInc', booking.eventBooking)
    addBookingInCart(booking);
  }


  const removeEvent = (experienceId) => {
    let booking = bookingDetail;
    if (booking.eventBooking.length > 0) {
      booking.eventBooking = booking.eventBooking.filter(booking => booking.experienceId !== experienceId);
    }
    console.log('booking.eventBooking', booking.eventBooking)
    addBookingInCart(booking);
  }



  const onClickConvMinus = (stayDetail) => {
    clickBtnPlusMinus();
    console.log("minus conv clicked");
    let existBooking = localStorage.getItem("stayBooking");
    let booking = JSON.parse(existBooking);
    if (existBooking) {
      console.log("minusExpBooking", booking.conveyenceBooking);
      console.log("minusStayDetailBo", stayDetail);
      const findStayBookingById = (array, id) => {
        return array.filter((booking) => booking._id === id);
      };
      const findStayBookingByIdAfterMinus = (array, id) => {
        return array.filter((booking) => booking._id != id);
      };
      const stayBookingIdToFind = stayDetail._id;
      console.log("stayBookingIdToFind", stayBookingIdToFind);
      const foundStayBooking = findStayBookingById(
        booking.conveyenceBooking,
        stayBookingIdToFind
      );
      const foundStayBookingAfterMinus = findStayBookingByIdAfterMinus(
        booking.conveyenceBooking,
        stayBookingIdToFind
      );
      if (foundStayBooking.length > 0) {
        console.log("found stay booking", booking);
        for (let i of booking.conveyenceBooking) {
          if (i._id === stayBookingIdToFind) {
            console.log("iiiiidddddssNEw", i);
            i.conveyenceCount = i.conveyenceCount - 1;
            if (i.conveyenceCount < 1) {
              console.log("in less 1");
              // booking.stayBooking= foundStayBookingAfterMinus
              i.conveyenceCount = 1;
            }
            localStorage.setItem("stayBooking", JSON.stringify(booking));
            setBookingDetail(booking);
          }
        }
      } else {
        console.log("no found stay booking", foundStayBooking);
        booking.conveyenceBooking = [];
      }
      localStorage.setItem("stayBooking", JSON.stringify(booking));
      setBookingDetail(booking);
    }
  };
  const onClickPlusAddon = (addonData) => {
    onClickConvMinus();
    let existBooking = bookingDetail;
    let booking = existBooking;
    console.log("fffdasfas", booking.addonBooking);
    if (existBooking) {
      if (booking?.addonBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter((booking) => booking.addonId === id);
        };
        const addonBookingIdToFind = addonData?.addonId;
        const foundAddonBooking = findAddonBookingById(
          booking?.addonBooking,
          addonBookingIdToFind
        );
        console.log("found addon booking", addonData);
        if (foundAddonBooking.length > 0) {
          for (let i of booking.addonBooking) {
            if (i.addonId === addonBookingIdToFind) {
              console.log("iiiiidddddss", i);
              // i.addonCount = foundAddonBooking.length + i.addonCount
              i.addonCount = foundAddonBooking.length + i.addonCount;
            }
          }
        }
        console.log("addonBooking", booking);
        addBookingInCart(booking);
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }
  };
  const onClickMinusAddon = (addonData) => {
    console.log("onClickMinusAddon");
    onClickConvMinus();
    let existBooking = bookingDetail;
    let booking = existBooking;
    console.log("fffdasfas", booking.addonBooking);
    if (existBooking) {
      if (booking?.addonBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter((booking) => booking.addonId === id);
        };
        const addonBookingIdToFind = addonData?.addonId;
        const foundAddonBooking = findAddonBookingById(
          booking?.addonBooking,
          addonBookingIdToFind
        );
        console.log("found addon booking", addonData);
        if (foundAddonBooking.length > 0) {
          for (let i of booking.addonBooking) {
            if (i.addonId === addonBookingIdToFind) {
              console.log("iiiiidddddss", i);
              // i.addonCount = foundAddonBooking.length + i.addonCount
              i.addonCount = i.addonCount - 1;
              if (i.addonCount <= 0) {
                booking.addonBooking = [];
              }
            }
          }
        }
        console.log("addonBooking", booking);
        addBookingInCart(booking);
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }
  };
  // const callBack = () => {
  //   navigate(`/property-detail/${bookingDetail.propertyId}`);
  // };

  const callBack = () => {
    navigate('/experiences')
  }

  const confirmBookingAndyPay = () => {
    // if(!loggedUser.isLoggedIn){
    //   alert('Please Login First')
    //   return
    // }
    bookingSubmit();
    // toggleConfirmPay()
    toggleConfirm();
  };

  const onClickBookingBtn = () => {
    if (!loggedUser.isLoggedIn) {
      const button = document.getElementById("signInBtn");
      if (button) {
        button.click();
      }
      return;
    }
    toggleConfirm();
  };

  const addBookingInCart = (data) => {
    console.log("dddda", data);
    let booking = data;
    authServices
      .addExperienceBookingInTemporaryApi({ booking: booking })
      .then((response) => {
        console.log("booking added in cart", response.data.data);
        clickBtnPlusMinus();
      })
      .catch((error) => {
        console.log("error in cart", error);
      });
  };
  // const addBookingInCart = (data) => {
  //   let booking = data
  //   authServices.addExperienceBookingInTemporaryApi({ "booking": booking }).then((response) => {
  //       //   navigate(`/booking/${response.data.data._id}`)
  //       navigate(`/experiences/booking/confirmation/${response.data.data._id}`)


  //   }).catch((error) => {
  //       console.log('error in cart', error)
  //   })
  // };

  useEffect(() => {
    if (loggedUser.isLoggedIn) {
      setPrimaryguestName(loggedUser?.user?.name)
      setPrimaryguestEmail(loggedUser?.user?.email)
      setPrimaryguestPhone(loggedUser?.user?.mobileNo)
    }
    authServices
      .temporaryExperienceBookingById({ id: id })
      .then((result) => {
        console.log("temResu", result);
        let rs = result?.data?.data;
        if(rs===null){
          navigate('/')
        }
        if (rs?.booking) {
          setBookingDetail(rs.booking);
          setTemporaryTripBookingId(rs._id)
          let totalPrice = 0;
          if (rs.booking?.stayBooking) {
            if (rs.booking?.stayBooking.length > 0) {
              for (let item of rs.booking?.stayBooking) {
                // console.log('priceTotal', i.price * i.stayCount)
                // totalPrice = totalPrice + i.price * i.stayCount
                // let p = item.perPerson === true ? (Number(item.adults)*item.price) * item.stayCount:Number(item?.price) * Number(item?.stayCount)
                if (item.weekendDays > 0) {
                  let p =
                    item.perPerson === true
                      ? item.adults * item.priceWeekend * item.weekendDays
                      : item.priceWeekend * item.stayCount * item.weekendDays;
                  totalPrice = totalPrice + p;
                  console.log("stayWeekenDaysPriceTotal", p);
                }
                if (item.regularDays > 0) {
                  let p =
                    item.perPerson === true
                      ? item.adults * item.priceRegular * item.regularDays
                      : item.priceRegular * item.stayCount * item.regularDays;
                  totalPrice = totalPrice + p;
                  console.log("stayregularDaysPriceTotal", p);
                }
              }
              console.log("stayTotalPrice", totalPrice);
            }
          }
          if (rs.booking?.experienceBooking) {
            if (rs.booking?.experienceBooking.length > 0) {
              for (let item of rs.booking?.experienceBooking) {
                if (item.isWeekendPrice === true) {
                  let p =
                    item.perPerson === true
                      ? (item.adults + item.children) * item.priceWeekend
                      : item.priceWeekend * item.experienceCount;
                  totalPrice = totalPrice + p;
                } else {
                  let p =
                    item.perPerson === true
                      ? (item.adults + item.children) * item.priceRegular
                      : item.priceRegular * item.experienceCount;
                  totalPrice = totalPrice + p;
                }
              }
            }
          }

          if (rs.booking?.eventBooking) {
            if (rs.booking?.eventBooking.length > 0) {
              for (let item of rs.booking?.eventBooking) {
                if (item.isWeekendPrice === true) {
                  let p =
                    item.perPerson === true
                      ? (item.adults + item.children) * item.priceWeekend
                      : item.priceWeekend * item.experienceCount;
                  totalPrice = totalPrice + p;
                } else {
                  let p =
                    item.perPerson === true
                      ? (item.adults + item.children) * item.priceRegular
                      : item.priceRegular * item.experienceCount;
                  totalPrice = totalPrice + p;
                }
              }
            }
          }
          if (rs.booking?.addonBooking) {
            if (rs.booking?.addonBooking.length > 0) {
              for (let item of rs.booking?.addonBooking) {
                totalPrice = totalPrice + item.price * item.addonCount
              }
            }
          }

          console.log("totallllll", totalPrice);
          setPrice((totalPrice * 20) / 100);
          let amountAtCheckIntime = totalPrice - (totalPrice * 20) / 100;
          setAmtPayAtCheckIn(amountAtCheckIntime);
          setStayTotalPrice(totalPrice);
        }
      })
      .catch((error) => {
        console.log("tempErr", error);
      });

  }, [clickBtn]);
  return (
    <>
      <div className="booking-summary-breadcrumb">

      </div>

      <div className="booking-summary-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mb-4">
              <div className="booking-summary-left">
                <div className="bsl-top">
                  <div className="d-flex justify-content-between align-items-center flex-lg-nowrap flex-wrap">
                    <button className="btn btn-back text-primary fw-bold" onClick={callBack}>
                      {" "}
                      <ArrowBackIosNewOutlinedIcon /> Request to book
                    </button>
                  </div>
                </div>

                <div className="booking_details_title">
                  guest details {amountPayble}
                </div>

                <div className="booking-summary-head">
                  <div className="guest_booking_details">
                    <div className="guest_booking_details_inner d-flex align-items-md-center align-items-start">
                      <div className="guest_booking_details_inner_left">
                        {/* <img src={guestImg} className="img-fluid" /> */}
                        <img src={profilePhotUrl ? `${BASE_URL}/${profilePhotUrl}` :guestImg} className="img-fluid" />
                      </div>
                      <div className="guest_booking_details_inner_right d-md-flex d-block justify-content-between w-100">
                        <div className="align-self-end">
                          <h5>{primaryguestName || loggedUser?.user?.name}</h5>
                          <h6>
                            {" "}
                            <a className="emil-id" href="#">
                              {primaryguestEmail || loggedUser?.user?.email}
                            </a>{" "}
                            |{" "}
                            <a className="phone-num" href="#">
                              {primaryguestPhone || loggedUser?.user?.mobileNo}
                            </a>{" "}
                          </h6>
                        </div>
                        <div className="user-badge text-md-end text-start">
                          <div><small><WarningAmberOutlinedIcon /> notifications will be sent to this person.</small></div>
                          <button className="btn signIn text-uppercase mt-1" onClick={() => toggle()}>
                            add guest details
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* <div className="guest_booking_details01 d-flex align-items-center justify-content-between">

                      <h6>
                        {" "}
                        <span className="totalPerson">
                          {Number(bookingDetail?.adults) +
                            Number(bookingDetail?.children) +
                            Number(bookingDetail?.pet)}{" "}
                          Persons
                        </span>{" "}
                        {bookingDetail?.adults} adults +{" "}
                        {bookingDetail?.children} children +{" "}
                        {bookingDetail?.pet}pet
                      </h6>

                      <button className="btn signIn" onClick={() => toggle()}>
                        Edit
                      </button>
                    </div> */}
                  </div>
                </div>

                <div className="booking-summary-footer">
                  <div className="booking_details_title">booking details</div>

                  <div className="booking-summary-footer-inner booking-summary-box">

             
                    <div className="fun-type">
                      <div className="d-flex justify-content-between align-content-center mb-4 mt-4">
                        <div className="bsf_title">{bookingDetail?.experienceBooking && bookingDetail?.experienceBooking.length > 0 ? `${bookingDetail?.experienceBooking.length} experience selected` : '0 experience'}</div>
                        <button className="btn btn-outline-primary btn-sm" onClick={callBack}>ADD</button>
                      </div>

                      {bookingDetail?.experienceBooking ? (
                        bookingDetail?.experienceBooking.map((item, index) => {
                          return (
                            <>
                              <div className="stay-list">
                                <div className="stay-list-item mb-2">
                                  <div className="d-flex">
                                    {/* <img className="stay-list-item-img" src="https://picsum.photos/1024" /> */}
                                    <img className="stay-list-item-img"src={`${BASE_URL}/${item?.experienceImg}`} />
                                    <div className="stay-list-item-details">
                                      <small>  {item?.experienceName}</small>
                                      <h6 className="mt-1 mb-1">   {item?.experienceName}</h6>
                                      <small>max {item?.maxPerson} person | {item?.weekendDays > 0 ? item?.isWeekendPrice : item?.priceRegular}/- per person | </small>
                                    </div>
                                  </div>
                                  <div className="chck-in">
                                    <small>&nbsp;</small>
                                    <h6 className="mt-1 mb-1">&nbsp;</h6>
                                    <small>{formattedDateDDMMMYYYY(item?.checkInDate)}</small>
                                  </div>
                                  <div className="d-flex align-items-end pt-2 me-2">
                                    <div className="number-input ">
                                      <label htmlFor="floatingInputGroup1">no of {item?.perPerson === true ? 'person' : 'experience'}</label>
                                      <div className="input-group ">
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          id="button-remove"
                                          onClick={() => updateAdultsCountByExperienceId(item.experienceId, false)}
                                        >
                                          <span className="material-symbols-outlined"><RemoveIcon /></span>
                                        </button>
                                        <input
                                          type="text"
                                          className="form-control"
                                          id="floatingInputGroup1"
                                          value={item?.perPerson === true ? Number(item?.adults) + Number(item?.children) : item?.experienceCount}

                                        />
                                        <button
                                          className="btn btn-outline-secondary"
                                          type="button"
                                          id="button-add"
                                          onClick={() => updateAdultsCountByExperienceId(item.experienceId, true)}
                                        >
                                          <span className="material-symbols-outlined"><AddIcon /></span>
                                        </button>
                                      </div>
                                    </div>
                                    <btn className="btn btn-outline-danger btn-sm mt-2 ms-2" onClick={() => removeExperience(item.experienceId)}>
                                      <span className="material-symbols-outlined"><DeleteForeverOutlinedIcon /></span>
                                    </btn>
                                  </div>
                                </div>
                              </div>

                            </>
                          );
                        })
                      ) : (
                        <>
                          {/* <div className="suggestion_box d-flex justify-content-center align-items-center text-center">
                            <div
                              className="suggestion_box_title text-uppercase"
                              onClick={callBack}
                            >
                              Suggest
                            </div>
                          </div> */}
                        </>
                      )}
                 

                 
                    </div>


                
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="booking-summary-right">
                <div className="booking_details_title">Price details</div>

                <div className="price_detils_wrapper booking-summary-box">
                  <div className="price_head d-flex justify-content-between align-items-end">
                    <h6>
                      TOTAL PRICE <br></br>
                      <span className="totalPrice">
                        <svg
                          width="9"
                          height="d14"
                          viewBox="0 0 9 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="M0.0276316 0V1.89583C1.07763 1.95417 3.75789 1.72083 4.17237 3.09167H0.0276316V4.78333H4.36579C4.36579 4.78333 3.78553 6.2125 2.32105 6.35833L0.0276316 6.41667L0 8.10833L4.80789 14H7.29474L2.76316 8.1375C2.76316 8.1375 5.63684 8.225 6.60395 4.69583L8.34474 4.75417V3.15H6.46579C6.46579 3.15 6.46579 2.39167 5.94079 1.6625H8.4V0H0.0276316Z"
                            fill="#FF5733"
                          />
                        </svg>
                        {stayTotalPrice}/-
                      </span>
                    </h6>

                    <img src={customerSupport} />
                  </div>

                  {bookingDetail?.stayBooking
                    ? bookingDetail?.stayBooking.map((item, index) => {
                      return (
                        <>
                          {item.regularDays > 0 && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {item.perPerson === true
                                      ? item.adults
                                      : item.stayCount}
                                    {item.perPerson === true ? "P" : "S"} x{" "}
                                    {item?.stayType} @{item?.priceRegular}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Stay"}{" "}
                                    x {item.regularDays}d
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    -{" "}
                                    {formattedDateDDMMMYYYY(
                                      item?.checkOutDate
                                    )}{" "}
                                    | {item?.adults}P | {item.regularDays}{" "}
                                    Regular D
                                  </div>
                                </div>

                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? Number(item.adults) *
                                    item.priceRegular *
                                    item.regularDays
                                    : Number(item?.priceRegular) *
                                    Number(item?.stayCount) *
                                    item.regularDays}
                                </div>


                              </div>
                            </div>
                          )}
                          {item.weekendDays > 0 && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {item.perPerson === true
                                      ? item.adults
                                      : item.stayCount}
                                    {item.perPerson === true ? "P" : "S"} x{" "}
                                    {item?.stayType} @{item?.priceWeekend}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Stay"}{" "}
                                    x {item.weekendDays}d
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    -{" "}
                                    {formattedDateDDMMMYYYY(
                                      item?.checkOutDate
                                    )}{" "}
                                    | {item?.adults}P | {item.weekendDays}{" "}
                                    Weekend D
                                  </div>
                                </div>

                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? Number(item.adults) *
                                    item.priceWeekend *
                                    item.weekendDays
                                    : Number(item?.priceWeekend) *
                                    Number(item?.stayCount) *
                                    item.weekendDays}
                                </div>


                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                    : ""}
                  {bookingDetail?.experienceBooking
                    ? bookingDetail?.experienceBooking.map((item, index) => {
                      return (
                        <>
                          {item.isWeekendPrice === false && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {" "}
                                    {item.perPerson === true
                                      ? item?.adults + item.children
                                      : item.experienceCount}
                                    {item.perPerson === true ? "P" : "E"} x{" "}
                                    {item?.experienceName} @
                                    {item?.priceRegular}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Exp"}
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    | {item?.adults + item.children}P |
                                    Regular Price{ }
                                  </div>
                                </div>

                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? (item.adults + item.children) *
                                    item.priceRegular
                                    : Number(item?.priceRegular) *
                                    Number(item?.experienceCount)}
                                </div>


                              </div>
                            </div>
                          )}
                          {item.isWeekendPrice === true && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {item.perPerson === true
                                      ? item?.adults + item.children
                                      : item.experienceCount}
                                    {item.perPerson === true ? "P" : "E"} x{" "}
                                    {item?.experienceName} @
                                    {item?.priceWeekend}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Exp"}{" "}
                                    x {item.weekendDays}d
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    | {item?.adults + item.children}P |
                                    Weekend Price
                                  </div>
                                </div>

                                {/* <div className="ppl-center">{item.perPerson === true ? ((item.adults+item.children)*item.priceWeekend) * item.weekendDays : Number(item?.priceWeekend) * Number(item?.experienceCount) * item.weekendDays}</div> */}
                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? (item.adults + item.children) *
                                    item.priceWeekend
                                    : Number(item?.priceWeekend) *
                                    Number(item?.experienceCount)}
                                </div>


                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                    : ""}

                  {bookingDetail?.eventBooking
                    ? bookingDetail?.eventBooking.map((item, index) => {
                      return (
                        <>
                          {item.isWeekendPrice === false && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {" "}
                                    {item.perPerson === true
                                      ? item?.adults + item.children
                                      : item.experienceCount}
                                    {item.perPerson === true ? "P" : "E"} x{" "}
                                    {item?.experienceName} @
                                    {item?.priceRegular}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Event"}
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    | {item?.adults + item.children}P |
                                    Regular Price{ }
                                  </div>
                                </div>

                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? (item.adults + item.children) *
                                    item.priceRegular
                                    : Number(item?.priceRegular) *
                                    Number(item?.experienceCount)}
                                </div>


                              </div>
                            </div>
                          )}
                          {item.isWeekendPrice === true && (
                            <div className="ppl_box">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="ppl-left">
                                  <h6>
                                    {item.perPerson === true
                                      ? item?.adults + item.children
                                      : item.experienceCount}
                                    {item.perPerson === true ? "P" : "E"} x{" "}
                                    {item?.experienceName} @
                                    {item?.priceWeekend}/-{" "}
                                    {item.perPerson === true
                                      ? "Per Person"
                                      : "Per Event"}{" "}
                                    x {item.weekendDays}d
                                  </h6>
                                  <div className="ppl_info">
                                    {formattedDateDDMMMYYYY(
                                      item?.checkInDate
                                    )}{" "}
                                    | {item?.adults + item.children}P |
                                    Weekend Price
                                  </div>
                                </div>

                                {/* <div className="ppl-center">{item.perPerson === true ? ((item.adults+item.children)*item.priceWeekend) * item.weekendDays : Number(item?.priceWeekend) * Number(item?.experienceCount) * item.weekendDays}</div> */}
                                <div className="ppl-center">
                                  {item.perPerson === true
                                    ? (item.adults + item.children) *
                                    item.priceWeekend
                                    : Number(item?.priceWeekend) *
                                    Number(item?.experienceCount)}
                                </div>


                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                    : ""}

                  {bookingDetail?.addonBooking ? bookingDetail?.addonBooking.map((item, index) => {
                    return (
                      <>
                        <div className="ppl_box">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="ppl-left">
                              <h6>{item.addonCount} x Addon @{item.price}/-</h6>
                              <div className="ppl_info">{formattedDateDDMMMYYYY(item?.checkInDate)}  | {item.serviceName}</div>
                            </div>

                            <div className="ppl-center">{item.isWeekendPrice === true ? item.price * item.addonCount : item.price * item.addonCount}</div>


                          </div>
                        </div>
                      </>
                    )
                  }) : ''}
                  <div className="ppl_box">

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ppl-left">
                        <h6>fees & taxes</h6>
                        <div className="ppl_info">platform fees + govt taxes</div>
                      </div>

                      <div className="ppl-center">0/-</div>


                    </div>
                  </div>

                  <div className="ppl_box">

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="ppl-left">
                        <h6>Total</h6>

                      </div>

                      <div className="ppl-center">{stayTotalPrice}</div>


                    </div>
                  </div>

                  <div className="price-range-wrapper">
                    <div className="pay-now">
                      <small>pay now</small>
                      <h5><span class="icon-furut-iconsrupee ico-16"></span>{price}</h5>
                    </div>
                    <div className="price-range-slider my-3">
                      <input
                        type="range"
                        min={(stayTotalPrice * 20) / 100}
                        max={stayTotalPrice}
                        step="5"
                        value={price}
                        onChange={handleChange}
                      />
                      {/* <span>Price: ${price}</span> */}
                    </div>

                    <div className="pay-later">
                      <small>pay at checkout</small>
                      <h6 class="mt-1"><span class="icon-furut-iconsrupee ico-16"></span>{amtPayAtCheckIn}/-</h6>
                    </div>

                  </div>

                  <h6 className="promocode-link">
                    Have <a href="#">Referral or Promocode</a>{" "}
                  </h6>


                  <button
                    className="btn btn-success w-100 mb-4"
                    disabled={price===0 ? true : false}
                    onClick={bookingSubmit}>
                    Confirm & pay <span>{price}</span>/-
                  </button>

                  <p class="text-center text-gray small">By continuing, I agree to the  <a href="#">COVID-19 Safety Requirements</a>, <a href="#">terms</a>, <a href="#">camprules</a> and <a href="#">Cancellation policy</a> </p>

                  {/* <p>
                    By continuing, I agree to the COVID-19 Safety Requirements
                    and Terms and conditions
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-bottom-wrapper d-none">
          <div class="mobile-bottom">
            <a href="#" class="btn btn-success" disabled={price===0 ? true : false} onClick={bookingSubmit}>CONFIRM & PAY {price}/- </a>
            <a href="" class="text-decoration-none text-light"><MoreVertIcon /></a>
          </div>
        </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        size="md"
        centered={true}
        className="editModalPopup"
      >
        <AddGuestModal
          toggle={toggle}
          type={"add"}
          title={"add guest detail"}
          primaryguestId={loggedUser.user._id}
          tripBookingId={temporaryTripBookingId}

        />
      </Modal>

      <ConfirmationModal
        toggle={toggleConfirm}
        modal={confirmModal}
        onClick={() => confirmBookingAndyPay()}
        title="Confirm Payment"
        text="Are you sure you want to confirm booking and pay?"
        iconType="mdi-alert-circle"
      />
    </>
  );
};

export default ExperienceBookingConfirmation;
