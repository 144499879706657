import { useEffect, useState } from "react";
import cardImg from "../../assets/img/card-image.jpg";
import { BASE_URL } from "../../services/config";
import Thumbnails from "../../assets/img/Thumbnails.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Thumbnails01 from "../../assets/img/Thumbnails01.png";
import Thumbnails02 from "../../assets/img/Thumbnails02.png";
import Thumbnails03 from "../../assets/img/Thumbnails03.png";
import starRatings from "../../assets/img/star-ratings.png";
import ShareIcon from '@mui/icons-material/Share';
import FavoriteIcon from '@mui/icons-material/Favorite';
import datePng from "../../assets/img/date.png";
import reveiwBadge from '../../assets/img/elfsight-review-badge.png'
import clockImg from "../../assets/img/clock.png";
import hotTubImg from "../../assets/img/hot-tub.png";
import springIc from "../../assets/img/spring-ic.png";
import summerIc from "../../assets/img/summer-ic.png";
import flightIc from "../../assets/img/flight-ic.png";
import trainIc from "../../assets/img/train-ic.png";
import vehichalIc from "../../assets/img/vehichal-ic.png";
import forestImg from "../../assets/img/forest.png";
import treeIc from "../../assets/img/tree-ic.png";
import card01 from '../../assets/images/card01.jpg'
import ratingStar from '../../assets/images/Rating icon.png'
import khadaAadmi from '../../assets/img/khada-aadmi.png'

import badgeName from '../../../src/assets/img/badge-name.svg'
import hostPoster from "../../assets/img/hostPoster.jpg"

import userProfile from "../../assets/img/user-profile.png"

import checkIn from "../../../src/assets/img/check-in-ic.png";
import checkOut from "../../../src/assets/img/check-out-ic.png";
import LastPageOutlinedIcon from '@mui/icons-material/LastPageOutlined';
import FirstPageOutlinedIcon from '@mui/icons-material/FirstPageOutlined';
import InfoIcon from '@mui/icons-material/Info';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import tabImg from '../../assets/images/tab-btn-image.png'
import posterImg1 from '../../assets/images/camping01.jpg'
import posterImg2 from '../../assets/images/camping02.jpg'

import arrowWhite from '../../assets/img/arrow-light.png'
import campRules from '../../assets/img/campus-rules.png'
import campRules1 from '../../assets/img/campus-rules-restrict.png'
import USI_ICON from '../../assets/img/USI_ICON.png'

// MATERIAL ICON IMPORT 
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import DirectionsSubwayFilledIcon from '@mui/icons-material/DirectionsSubwayFilled';
import CommuteIcon from '@mui/icons-material/Commute';
import HotTubIcon from '@mui/icons-material/HotTub';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import ShowerIcon from '@mui/icons-material/Shower';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Modal } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import authServices from "../../services/auth.services";
import { calculateDayCount, calculateHoursStartTimeAndEndTime, formattedDateDDMMMYYYY, getLoggedData } from "../../helpers/Helper";

import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import Features from "../../components/Comman/Features";
import OffcanvasModal from "../../components/Comman/OffcanvasModal";
import NearBy from "../../components/Comman/NearBy";
import HostedBy from "../../components/Comman/HostedBy";

const PropertyDetail = ({

  setShowPropertyDetail,
}) => {
  let proData = localStorage.getItem('propertyDetail')
  const [showProperty, setShowProperty] = useState(true)
  // const [selectedPropertyDetail, setSelectedPropertyDetail] = useState(JSON.parse(proData))
  const [selectedPropertyDetail, setSelectedPropertyDetail] = useState()
  const [addonCount, setAddonCount] = useState(1)
  const [temporaryBooking, setTemporaryBooking] = useState('')

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  let loggedUser = getLoggedData()
  const navigate = useNavigate();
  const { id } = useParams();

  const onClickStay = (value) => {
    console.log("value", value);

    localStorage.setItem('stayDetail', JSON.stringify(value))
    navigate(`/stay-detail/${value._id}`)
  };

  const onClickExperience = (value) => {
    console.log("onClickExperience", value)
    localStorage.setItem('experienceDetail', JSON.stringify(value))
    navigate(`/experience-detail/${value._id}`)
  };

  const onClickEvent = (value) => {
    console.log("event", value)
    localStorage.setItem('event', JSON.stringify(value))
    navigate(`/event-detail/${value._id}`)
  };

  const onClickConveyence = (value) => {
    console.log("onClickConveyence", value);
    localStorage.setItem('conveyenceDetail', JSON.stringify(value))
    navigate('/conveyence-detail')
  };
  const callBackProperty = () => {
    setShowPropertyDetail(false);
    setShowProperty(true)
  };
  const onClickMinus = (value) => {
    if (value - 1 < 0) {
      setAddonCount(0)
    } else {

      setAddonCount(value - 1)
    }
  }
  const onClickPlus = (value) => {

    setAddonCount(value + 1)
  }
  const addAddons = (addonData) => {

    if (!loggedUser.isLoggedIn) {
      const button = document.getElementById('signInBtn');
      if (button) {
        button.click();
      }
      return
    }
    let existBooking = temporaryBooking
    let booking = existBooking
    console.log('fffdasfas', booking.addonBooking)
    if (existBooking) {
      if (booking?.addonBooking) {
        const findAddonBookingById = (array, id) => {
          return array.filter(booking => booking.addonId === id);
        };
        const addonBookingIdToFind = addonData?._id;
        const foundAddonBooking = findAddonBookingById(booking?.addonBooking, addonBookingIdToFind);
        if (foundAddonBooking.length > 0) {
          console.log('found addon booking', booking)
          for (let i of booking.addonBooking) {
            if (i.addonId === addonBookingIdToFind) {
              console.log('iiiiidddddss', i)
              // i.addonCount = foundAddonBooking.length + i.addonCount
              i.addonCount = addonCount
              if (addonCount === 0) {
                booking.addonBooking = []
              }
            }

          }
        } else {
          console.log('no found addon booking', foundAddonBooking)
          let bookingData = {
            addonId: addonData._id,
            propertyId: addonData.propertyId,
            addonTypeId: addonData.addonTypeId,
            addonTypeName: addonData.addonTypeName,
            serviceName: addonData.serviceName,
            serviceDescription: addonData.serviceDescription,
            perPersonDay: addonData.perPersonDay,
            price: addonData.price,
            addonCount: addonCount,
            hostId: selectedPropertyDetail.userId
          }
          // stayDetail.stayCount = 1
          // booking.addonsBooking.push(bookingData);
          // booking.addonBooking = [bookingData]
          booking.addonBooking.push(bookingData)
        }
        console.log('addonBooking', booking)
        addBookingInCart(booking)
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      } else {
        console.log('not exist booking', booking);
        // addonData.addonCount = 1
        let bookingData = {
          addonId: addonData._id,
          propertyId: addonData.propertyId,
          addonTypeId: addonData.addonTypeId,
          addonTypeName: addonData.addonTypeName,
          serviceName: addonData.serviceName,
          serviceDescription: addonData.serviceDescription,
          perPersonDay: addonData.perPersonDay,
          price: addonData.price,
          addonCount: addonCount,
          hostId: selectedPropertyDetail.userId

        }
        let obj = {
          addonBooking: [addonData],
          // stayCount: 1
        };
        booking.addonBooking = [bookingData]
        console.log('bbb', booking)
        addBookingInCart(booking)
        // localStorage.setItem('stayBooking', JSON.stringify(booking));
      }
    }else{
      alert('Please add first any stay, event or experience')
    }
  }

  const addBookingInCart = (data) => {
    console.log('dddda', data)
    let booking = data
    authServices.addTripBookingInCartApi({ "booking": booking }).then((response) => {
      console.log('booking added in cart', response.data.data)
      navigate(`/booking/${response.data.data._id}`)

    }).catch((error) => {
      console.log('error in cart', error)
    })

  }
  useEffect(() => {
    console.log('innnnnn', selectedPropertyDetail)

    authServices.propertyDetailByIdApi({ id: id }).then((result) => {
      console.log('resultdsss', result.data.data)
      setSelectedPropertyDetail(result.data.data)
      if (loggedUser?.isLoggedIn) {
        console.log('result.data.data.propertyId', result.data.data._id)
        authServices.temporaryTripBookingByUserIdAndPropertyId({ userId: loggedUser?.user?._id, propertyId: result.data.data._id }).then((response) => {
          if (response.status === 200) {
            console.log('temporaryBooking', response.data.data)
            let rs = response?.data?.data
            if (rs?.booking) {
              setTemporaryBooking(response.data.data.booking)
            }
          }
        }).catch((error) => {
          console.log('error', error)
        })
      }
    }).catch((error) => {
      console.log('error', error)
    })
  }, [])

  return (
    <>

      <main className="property-detaills-page">
        <section className="pd01">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col-12">
                <div className="pd01_top_wrapper">
                  <Swiper
                    className="mySwiper"
                    slidesPerView={4}
                    spaceBetween={1}
                    loop="true"
                    breakpoints={{
                      // when window width is >= 640px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 4,
                      },
                      // when window width is >= 768px
                      640: {
                        slidesPerView: 2,
                      },
                      800: {
                        slidesPerView: 3,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    {selectedPropertyDetail?.allImages.map((item, index) => {
                      return (
                        <>
                          <SwiperSlide className="pd01_top_thumbnail" key={index} onClick={() => toggle()}>
                            <img
                              src={`${BASE_URL}/${item.propImgName}`}
                              alt=""
                              className="img-fluid w-100"
                            />
                          </SwiperSlide>
                        </>
                      )
                    })}
                    {selectedPropertyDetail?.allImages.length === 0 && <>
                      <SwiperSlide className="pd01_top_thumbnail">
                        <img
                          src={Thumbnails}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide className="pd01_top_thumbnail">
                        <img
                          src={Thumbnails}
                          alt=""
                          className="img-fluid w-100"
                        />
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="pd01_top_thumbnail">
                          <img
                            src={Thumbnails}
                            alt=""
                            className="img-fluid w-100"
                          />
                        </div>
                      </SwiperSlide>
                    </>}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="pd01_bottom_wrapper d-flex justify-content-start align-items-start">
              <div className="pd01_bottom_thumbnails01">
                <div className="pd01_bottom_thumbnail01_inner">
                  <img
                    src={selectedPropertyDetail?.allImages[0]?.propImgName ? `${BASE_URL}/${selectedPropertyDetail?.allImages[0]?.propImgName}` : Thumbnails01}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="pd01_bottom_thumbnail01_inner">
                  <img
                    src={selectedPropertyDetail?.allImages[1]?.propImgName ? `${BASE_URL}/${selectedPropertyDetail?.allImages[1]?.propImgName}` : Thumbnails02}
                    alt=""
                    className="img-fluid"
                  />
                </div>

                <div className="pd01_bottom_thumbnail01_inner">
                  <img
                    src={selectedPropertyDetail?.allImages[2]?.propImgName ? `${BASE_URL}/${selectedPropertyDetail?.allImages[2]?.propImgName}` : Thumbnails03}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>

              <div className="pd-bottom_rtl flex-fill">
                <div className="pd01_bottom_content mt-4 pt-0">
                  <div className="breadcrumb_wrapper">
                    <nav
                      style={{ "--bs-breadcrumb-divider": "'>'" }}
                      ariaLabel="breadcrumb"
                    >
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="#" className="small">Camps in</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="small">{selectedPropertyDetail?.propertyLocated?.state}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="small">{selectedPropertyDetail?.propertyLocated?.city}</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#" className="small">{selectedPropertyDetail?.propertyLocated?.fullAddress}</a>
                        </li>
                      </ol>
                    </nav>
                  </div>

                  <div className="pd01_content">
                    <h4>{selectedPropertyDetail?.propertyName}</h4>

                    <div className="d-flex justify-content-start align-items-end mt-2 mt-lg-4">
                      <div className="pd01_content_ltr d-flex justify-content-between align-items-center">
                        <div className="prop-review me-5">
                        <img src={reveiwBadge} />
                        </div>
                        {/* <div className="rating_txt d-flex justify-content-center align-items-center">
                          4.8
                        </div>
                        <div className="ratings01">
                          <img
                            src={starRatings}
                            className="img-fluid"
                            alt=""
                          />
                          <br />
                          <span>36 reviews</span>
                        </div>
                        <img
                          src="img/Badges.png"
                          alt=""
                          className="img-fluid"
                        /> */}
                      </div>
                      {/* <div class="frt-badge-wrapper mt-4 mt-lg-0 d-md-flex d-none justify-content-between align-items-center">
                        <div class="frt-badge me-4 text-center">
                     
                          <img src={badgeName} />
                          <h5>badge name</h5>
                        </div>
                        <div class="frt-badge me-4 text-center">
                          <img src={badgeName} />
                          <h5>badge name</h5>
                        </div>
                        <div class="frt-badge me-4 text-center">
                          <img src={badgeName} />
                          <h5>badge name</h5>
                        </div>
                        <div class="frt-badge me-4 text-center">
                          <img src={badgeName} />
                          <h5>badge name</h5>
                        </div>
                        <div class="frt-badge me-4 text-center">
                          <img src={badgeName} />
                          <h5>badge name</h5>
                        </div>
                      </div> */}
                      <div className="pd01_content_rtl ms-lg-5 mt-4 mt-lg-0">
                        <button className="btn_cutom_atn border-0 bg-transparent text-success">
                          <ShareIcon />
                          share
                        </button>
                        <button className="btn_cutom_atn border-0 bg-transparent text-danger">
                          <FavoriteIcon />
                          save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </section>

        {selectedPropertyDetail?.events.length > 0 && <section className="pd02">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-5 text-light h5">happening experiences</div>
                <div className="subtitle">
                  book upcoming events in {selectedPropertyDetail?.propertyName}
                </div>
              </div>
              <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4">

                {selectedPropertyDetail?.events.map((item, index) => {
                  return (
                    <>
                      <div className="col mb-4" key={index} onClick={() => onClickEvent(item)}>

                        <div className="experience_card">
                          <div className="experience_card_image position-relative ">
                            <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || cardImg}`} className="img-fluid experienceImg01" alt="" />
                            <div class="experience_card_det d-flex justify-content-evenly">
                              <p class="ribbon flex-grow-1">
                                <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular}</span>
                              </p>
                              <small class="badge bg-dark align-self-end me-3 duration">{item?.durationName === 'Multi-day' ? `${calculateDayCount(item.startDate, item.endDate)}N${calculateDayCount(item.startDate, item.endDate) + 1}D` : `${calculateHoursStartTimeAndEndTime(item?.startTime, item?.endTime)} H`}</small>
                            </div>
                          </div>

                          <div className="experience_card_content">
                            <div className="d-flex justify-content-between flex-1">
                              <h4>{item?.experienceName}</h4>
                            </div>

                            <div class="d-lg-flex d-block flex-row event-bottom">
                              <div class="d-flex flex-row card_det">
                                <CalendarMonthIcon className='me-2' />
                                <small class="bold pe-lg-4 pe-0">{formattedDateDDMMMYYYY(item?.startDate)} To {formattedDateDDMMMYYYY(item?.endDate)}</small>
                              </div>
                              <button class="btn btn-outline-dark btn-sm align-self-baseline" onClick={() => onClickEvent(item)}><small>LIMITED SEATS</small></button>
                            </div>

                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        }
        {selectedPropertyDetail?.stayTypeDetail.length > 0 && <section className="pd03 mt-4">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="title">stays</div>
                <div className="subtitle">
                  book your preferred accommodation at {selectedPropertyDetail?.propertyName}
                </div>
              </div>
            </div>
            <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-1 mt-4">
              {selectedPropertyDetail?.stayTypeDetail ? selectedPropertyDetail?.stayTypeDetail.map((item, index) => {
                return (
                  <>
                    <div className="col">
                      <div
                        className="stays_card_wrapper"
                        onClick={() => onClickStay(item)}
                      >
                        <div className="stays_card_image">
                          <img
                            // src={forestImg}
                            src={item?.stayImg ? `${BASE_URL}/${item?.stayImg}` : forestImg}
                            alt=""
                            className="img-fluid w-100 br16"
                          />
                        </div>
                        <div className="stays_card_content">
                          <div className="scc_top d-flex justify-content-start align-items-start gap-lg-3">
                            <div className="scc_top_left">

                              <img src={treeIc} alt="" />
                            </div>
                            <div className="scc_top_right">
                              <h6>{item?.stayType}</h6>
                              <p> <CurrencyRupeeIcon />
                                {item?.priceRegular || 0} <span>/ night</span>
                              </p>
                            </div>
                          </div>
                          <small>HURRY ! Only 2 available</small>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }) : ''}
            </div>
          </div>
        </section>
        }
        <section className="pd04">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-8 col-md-7 col-12">
                <div className="pd04_left_content_wrapper">
                  <div className="pd04_left_content">
                    <h6 className="mb-3">
                      <span className="text-light">about </span>{selectedPropertyDetail?.propertyName}
                    </h6>
                    <p>
                      {selectedPropertyDetail?.aboutProperty}
                    </p>
                    <hr />
                    {selectedPropertyDetail?.uspOne &&  <div className="icwtxt">
                      <img src={USI_ICON} />
                      {selectedPropertyDetail?.uspOne}
                    </div>
                    }
                    {selectedPropertyDetail?.uspTwo && <div className="icwtxt">
                      <img src={USI_ICON} />
                      {selectedPropertyDetail?.uspTwo}
                    </div>
                    }
                   {selectedPropertyDetail?.uspThree && <div className="icwtxt">
                      <img src={USI_ICON} />
                      {selectedPropertyDetail?.uspThree}
                    </div>
                  }
                   {selectedPropertyDetail?.uspOne || selectedPropertyDetail?.uspTwo || selectedPropertyDetail?.uspThree &&  <hr />}
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-5 col-12">
                <div className="pd04_right_content_wrapper">
                  <div className="pd04_right_content">
                    <h5>best time to visit</h5>
                    {selectedPropertyDetail?.season && selectedPropertyDetail?.season.map((item, index) => {
                      return (
                        <>
                          <div className="icwtxt" key={index} style={{ color: `#${item.color}` }}>
                            <img src={item?.seasonImg ? `${BASE_URL}/${item.seasonImg}` : springIc} alt="" /> {item?.seasonName}
                          </div>
                        </>
                      )
                    })}


                  </div>
                  <div className="pd04_right_content">
                    <h5>distance from</h5>
                    <div className="icwtxt">
                      <LocalAirportIcon />
                      {selectedPropertyDetail?.distanceFrom?.airport} {selectedPropertyDetail?.distanceFrom?.distanceAirport} Km
                    </div>
                    <div className="icwtxt">
                      <DirectionsSubwayFilledIcon />
                      {selectedPropertyDetail?.distanceFrom?.railwayStation} {selectedPropertyDetail?.distanceFrom?.distanceRailwayStation} km
                    </div>
                    <div className="icwtxt">
                      <CommuteIcon />
                      {selectedPropertyDetail?.distanceFrom?.busTaxiStand} {selectedPropertyDetail?.distanceFrom?.distanceBusTaxiStand} km
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {selectedPropertyDetail?.amenities && selectedPropertyDetail?.amenities.length > 0 && <section className="pdp_new">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-9 col-12">
                <div className="sdp_amentities">
                  <div className="title">amenities</div>
                  <div className="subtitle">facilities available in {selectedPropertyDetail?.propertyName} </div>

                  <div className="highlights_sec d-flex flex-md-nowrap flex-wrap justify-content-md-start justify-content-start align-items-start bg-transparent px-0">
                    {selectedPropertyDetail?.amenities && selectedPropertyDetail?.amenities.map((item, index) => {
                      return (
                        <>
                          <div className="highlights_card" key={index}>
                            <div className="highlights_card_img d-flex justify-content-center align-items-center">
                              <img src={`${BASE_URL}/${item?.amenitiesImg}`} />
                            </div>
                            <div className="highlights_card_content">
                              <h6>{item.amenitiesName}</h6>
                            </div>
                          </div>
                        </>
                      )
                    })}

                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
        }
        {selectedPropertyDetail?.experience.length > 0 && <section className="pd02">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="mt-5 text-light h5">experiences</div>
                <div className="subtitle">
                  activities & experiences offered at {selectedPropertyDetail?.propertyName} or nearby
                </div>
              </div>
              <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1 mt-4">

                {selectedPropertyDetail?.experience.map((item, index) => {
                  return (
                    <>
                      <div className="col mb-3" key={index}>

                        <div className="experience_card card-shadow" onClick={() => onClickExperience(item)}>
                          <div className="experience_card_image position-relative ">
                            <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || card01}`} className="img-fluid experienceImg01" alt="" />

                            <div class="experience_card_det d-flex justify-content-evenly">
                              <p class="ribbon flex-grow-1">
                                <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular}</span>
                              </p>
                              <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                            </div>
                          </div>

                          <div className="experience_card_content">
                            <div className="d-flex justify-content-between flex-1">
                              <div>
                                <h4>{item?.experienceName}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              </div>
            </div>
          </div>
        </section>
        }
        {selectedPropertyDetail?.addons.length > 0 && <section className="pd05">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-md-3">
                <div className="mt-4 text-light h5">addon's</div>
                <div className="subtitle">
                  helpful extras offered at of {selectedPropertyDetail?.propertyName} 
                </div>
              </div>
            </div>
            <div className="row row-cols-lg-4 row-cols-md-2 row-cols-1">
              {selectedPropertyDetail?.addons.map((item, index) => {
                return (
                  <>

                    <div className="col mb-4">
                      <div className="serviceCardWrapper d-flex flex-column justify-content-start">
                        <div className="serviceHead">
                          <div className="serviceCardImg"><img src={khadaAadmi} /></div>
                          <h6 className="mt-3 mt-lg-4">{item?.serviceName} </h6>
                        <div className="serviceDesc">{item?.serviceDescription}</div>
                        </div>
                        {/* <div className="servicePrice">{item?.price}/- <span>(excl. tax)</span></div> */}
                        <div className="d-flex justify-content-between align-items-end">
                          {/* <div class="btnCstm d-flex align-items-center justify-content-between">
                            <div class="decrease" onClick={() => onClickMinus(addonCount)}>-</div>
                            <div class="numbers">{addonCount}</div>
                            <div class="decrease" onClick={() => onClickPlus(addonCount)}>+</div>
                          </div> */}
                          <div>
                            <p className="servicePrice m-0 mt-4"><CurrencyRupeeIcon /> {item?.price}/-</p>
                            <small>per person</small>
                          </div>
                          <button class="btn btn-done w-auto" onClick={() => addAddons(item)}>ADD</button>
                        </div>
                      </div>
                    </div>

                  </>
                )
              })}
            </div>

          </div>
        </section>
        }
        <section className="pdp07">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sdp_amentities">
                  <div className="mt-4 text-light h5">hosted by</div>
                  <div className="subtitle">hosts of {selectedPropertyDetail?.propertyName}  </div>
                  <HostedBy selectedPropertyDetail={selectedPropertyDetail} />

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pdp06">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div class="h5 mt-5 text-light">things to know</div>
                <div class="subtitle">important information about {selectedPropertyDetail?.propertyName} </div>
                <div className="bsfi-head mt-5">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-6">
                      <div className="checkin">
                        <LastPageOutlinedIcon />
                        <div>
                          <span className="text-muted fw-bold text-light">Check in</span>
                          <h6>after {selectedPropertyDetail?.checkInCheckOutData?.checkInTime}</h6>
                          <small className="text-light">Early check in might be available. Please message host for more
                            info.
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6">
                      <div className="checkin">
                        <FirstPageOutlinedIcon />
                        <div>
                          <span className="text-muted fw-bold text-light">Check Out</span>
                          <h6>before {selectedPropertyDetail?.checkInCheckOutData?.checkOutTime}</h6>
                          <small className="text-light">Early check out might be available. Please message host for
                            more info.
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-lg-5 mt-4">
              <div className="col-md-6 col-12">
                <div className="campus_rules_card p-4 card">
                  <h5 className="mb-3 text-info"> <InfoIcon /> Camp rules</h5>
                  <div className="d-flex justify-content-between align-items-center gap-2">

                    <p>{selectedPropertyDetail?.campRules && selectedPropertyDetail?.campRules?.rule.join(", ")}</p>
                    <button className="btn-campusRules btn-info"> <ChevronRightIcon /> </button>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12">
                <div className="campus_rules_card campus_rules_card1 p-4 card">
                  <h5 className="mb-3 text-danger"> <InfoIcon /> Strict cancellation policy</h5>
                  <div className="d-flex justify-content-between align-items-center gap-2">
                    <p>{selectedPropertyDetail?.cancellationPolicy && selectedPropertyDetail?.cancellationPolicy?.policy.join(", ")}</p>
                    <button className="btn-campusRules text-white"> <ChevronRightIcon /> </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section03">
          <div className="container">
            <NearBy title='near by' />



          </div>
        </section>


        <OffcanvasModal className='offCanvaskk' toggle={toggle} isOpen={isOpen} images={selectedPropertyDetail?.allImages} title={selectedPropertyDetail?.propertyName} />
      </main>


    </>
  );
};

export default PropertyDetail;
