import { useEffect, useState } from "react";
import card01 from '../../assets/images/card01.jpg'
import ratingStar from '../../assets/images/Rating icon.png'
// import filterIc from '../../assets/img/filter-ic.png'
// import searchIc from '../../assets/img/search-ic.png'
import filterIc from '../../assets/img/filter-ic.png'
import { Modal } from "reactstrap";
import SearchModal from "../../components/search/SearchModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL } from "../../services/config";
import authServices from "../../services/auth.services";

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import campingInIndianStates from '../../components/Comman/campingInIndianStates.json'
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Features from "../../components/Comman/Features";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { calculateDayCount, formattedDateDDMMMYYYY } from "../../helpers/Helper";
import experienceImg from '../../assets/images/card-image.jpg'
const SearchResult = () => {
  const [showPropertyDetail, setShowPropertyDetail] = useState(false);
  const [searchResult, setSearchResult] = useState([])
  const [modal, setModal] = useState(false);
  const [isSearch, setIsSearch] = useState(false)
  const [searchParams] = useSearchParams();

  const type = searchParams.get('type') || 0;
  const search = searchParams.get('search')
  const navigate = useNavigate();
  const toggle = () => setModal(!modal);

  const onClickProperty = (data) => {
    setShowPropertyDetail(true);
    console.log("data", data);
    localStorage.setItem('propertyDetail', JSON.stringify(data))
    navigate(`/property-detail/${data._id}`)
  };
  const onClickExperience = (value) => {
    console.log("onClickExperience", value)
    localStorage.setItem('experienceDetail', JSON.stringify(value))
    navigate(`/experience-detail/${value._id}`)
  };

  const onClickEvent = (value) => {
    console.log("event", value)
    localStorage.setItem('event', JSON.stringify(value))
    navigate(`/event-detail/${value._id}`)
  };

  useEffect(() => {
    // let searchData = localStorage.getItem('propertySearchResult')

    console.log('searchfff', type)
    const reqBody = {
      terrin: Number(type) === 2 ? search : null,
      property: Number(type) === 1 ? search : null,
      location: Number(type) === 3 ? search : null,
    }
    console.log("reqBody", reqBody)
    if (search) {
      authServices.searchPropertyApi(reqBody).then((res) => {
        console.log("res", res)
        setSearchResult(res.data.data)
        // localStorage.setItem('propertySearchResult', JSON.stringify(res.data.data))

      }).catch((error) => {
        console.log("error", error)
      })
    }
    // if (searchData) {
    //   let res = JSON.parse(searchData)
    //   setSearchResult(res)
    // } else {
    //   setSearchResult([])
    // }
  }, [])


  return (
    <>
      {showPropertyDetail === false ? (
        <>

          <div className="search-bar mt-5 searchResult">

            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <button className="filter-btn" onClick={toggle}>
                    <div className="d-flex justify-content-between align-items-center gap-2">
                      <div className="search_sec d-flex align-items-center gap-2">
                        <ArrowBackIosNewIcon />
                        <div className="text-start">
                          <h5>Let’s explore</h5>
                          <p>destinations all over INDIA</p>
                        </div>
                      </div>
                      <div className="filter_box">
                        <img src={filterIc} alt="" />
                      </div>
                    </div>
                  </button>

                  <ul class="nav fw-semibold text-primary mb-2">
                    <li class="nav-item">
                      <a class="nav-link active" aria-current="page" href="#all">All</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#events">Events</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#exp">Experiences</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" href="#prop">Properties</a>
                    </li>
                  </ul>

                </div>
              </div>
            </div>

          </div>


          <section className="pd02 mt-5" id="events">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title">happening experiences</div>
                  <div className="subtitle">
                    book upcoming events in Great Himalayan Outdoors - four season camps
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                  {searchResult?.eventList && searchResult?.eventList.map((item, index) => {
                    return (
                      <>
                     
                        <div className='col' onClick={() => onClickEvent(item)}>

                          <div className="experience_card">
                            <div className="experience_card_image position-relative ">
                              <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || experienceImg}`} className="img-fluid experienceImg01" alt="" />
                              <div class="experience_card_det d-flex justify-content-evenly">
                                <p class="ribbon flex-grow-1">
                                  <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular || 'Free'}</span>
                                </p>
                                <small class="badge bg-dark align-self-end me-3 duration">{calculateDayCount(item.startDate, item.endDate)}N{calculateDayCount(item.startDate, item.endDate)+1}D</small>
                              </div>
                            </div>

                            <div className="experience_card_content">
                              <div className="d-flex justify-content-between flex-1">
                                <h4>{item?.experienceName}</h4>
                              </div>

                              {/* <div className="dashedBorder"></div> */}

                              <div class="d-lg-flex d-block flex-row event-bottom">
                                <div class="d-flex flex-row card_det">
                                  <CalendarMonthIcon className='me-2' />
                                  <small class="bold pe-lg-4 pe-0">{formattedDateDDMMMYYYY(item?.startDate)} To {formattedDateDDMMMYYYY(item?.endDate)}</small>
                                </div>
                                <button class="btn btn-outline-dark btn-sm align-self-baseline" onClick={() => onClickProperty(item)}><small>LIMITED SEATS</small></button>
                              </div>

                            </div>
                          </div>

                        </div>
                      </>
                    );
                  })}
                     {searchResult?.eventList ? searchResult?.eventList.length === 0 && <p className="text-danger h4 fw-bold my-3">Data Not Found</p> :<p className="text-danger h4 fw-bold my-3">Data Not Found</p>}
                </div>
              </div>
            </div>

            <section className="pd02 mt-5" id="exp">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="title">experiences</div>
                    <div className="subtitle">
                      activities & experiences in & around
                    </div>
                  </div>
                  <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                    {searchResult?.experienceList && searchResult?.experienceList.map((item, index) => {
                      return (
                        <>
                          <div className="col mb-3" key={index}>
                            <div className="experience_card card-shadow" onClick={() => onClickExperience(item)}>
                              <div className="experience_card_image position-relative ">
                                <img src={`${BASE_URL}/${item.expImg[0]?.expImgName || card01}`} className="img-fluid experienceImg01" alt="" />

                                <div class="experience_card_det d-flex justify-content-evenly">
                                  <p class="ribbon flex-grow-1">
                                    <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.priceRegular} - {item?.priceWeekend}</span>
                                  </p>
                                  <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                                </div>

                              </div>

                              <div className="experience_card_content">
                                <div className="d-flex justify-content-between flex-1">
                                  <div>
                                    <h4>{item?.experienceName}</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                      {searchResult?.experienceList ? searchResult?.experienceList.length === 0 && <p className="text-danger h4 fw-bold my-3">Data Not Found</p> : <p className="text-danger h4 fw-bold my-3">Data Not Found</p>}
                  </div>
                </div>
              </div>
            </section>


            <section id="prop">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="title">explore properties
                    </div>
                    <div className="subtitle">
                      unique properties around
                    </div>
                  </div>
                  <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
                    {searchResult?.allSearchProperty && searchResult?.allSearchProperty.map((item, index) => {
                      return (
                        <>
                          <div className="col mb-3" key={index} onClick={() => onClickProperty(item)}>
                            <div className="experience_card card-shadow">
                              <div className="experience_card_image position-relative ">
                                <img src={item?.propertyImage ? `${BASE_URL}/${item?.propertyImage}` : card01} className="img-fluid experienceImg01" alt="" />

                                <div class="experience_card_det d-flex justify-content-evenly">
                                  <p class="ribbon flex-grow-1">
                                    <span class="text"> <strong><CurrencyRupeeIcon /></strong> {item?.lowestPrice} - {item?.highestPrice}</span>
                                  </p>
                                  <small class="badge bg-dark align-self-end me-3 duration">3N4D</small>
                                </div>
                              </div>

                              <div className="experience_card_content">
                                <div className="d-flex align-middle property-details">
                                  <div>
                                    <h4 className="p-0">{item.propertyName}</h4>
                                    <p className="m-0">{item?.propertyLocated?.city}, {item?.propertyLocated?.state}</p>
                                  </div>
                                  <h5 className="ms-auto"> <img src={ratingStar} alt="rating star" />  4.8</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                       {searchResult?.allSearchProperty ? searchResult?.allSearchProperty.length === 0 && <p className="text-danger h4 fw-bold my-3">Data Not Found</p> : <p className="text-danger h4 fw-bold my-3">Data Not Found</p> }
                  </div>
                </div>
              </div>
            </section>
          </section>
          {/* <Features /> */}
       
     
        </>
      ) : (
        ''
        // <PropertyDetailPage selectedPropertyDetail={selectedPropertyDetail} setShowPropertyDetail={setShowPropertyDetail}/>
      )}

      <Modal isOpen={modal} toggle={toggle} size="lg" centered={true}>
        {/* <Logi toggle={toggle} isAddMode={isAddMode} selectedRow={selectedRow} language={language} getUsers={getUsers} /> */}
        <SearchModal toggle={toggle} setSearchResult={setSearchResult} setIsSearch={setIsSearch} />
      </Modal>
    </>
  );
};

export default SearchResult;
