import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import authServices from '../../services/auth.services';
import ReactInputMask from 'react-input-mask';
const UpdateProfileModal = ({
    title,
    toggle,
    profileDetail,
    userProfileDetailById
}) => {
    const [fullName, setFullName] = useState(profileDetail?.name)
    const [aboutMe, setAboutMe] = useState(profileDetail?.userDesc)
    const [alternativePhoneNumber, setAlternativePhoneNumber] = useState(profileDetail?.alternativePhoneNumber)
    const [streetAddress, setStreetAddress] = useState(profileDetail?.streetAddress)

    const onClickUpdate = () => {
        const reqBody = {
            id: profileDetail?._id,
            name: fullName,
            userDesc: aboutMe,
            alternativePhoneNumber: alternativePhoneNumber,
            streetAddress: streetAddress
        }
        authServices.updateProfileDetailById(reqBody).then((res) => {
            console.log('res', res)
            userProfileDetailById()
            toggle()
        }).catch((error) => {
            console.log('err', error)
        })

    }

    return (
        <>
            <ModalHeader className='pb-2' toggle={toggle}>{title} </ModalHeader>
            <ModalBody className='editModal'>
                <div className="col-12 mb-3">
                    <label htmlFor="fullName" className="form-label">
                        full name
                    </label>
                    <input type='text' id='fullName' className='form-control mb-0' onChange={(e) => setFullName(e.target.value)} value={fullName} />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="aboutMe" className="form-label">
                        about me
                    </label>
                    <input type='email' id='aboutMe' className='form-control mb-0' onChange={(e) => setAboutMe(e.target.value)} value={aboutMe} />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="alternativePhoneNumber" className="form-label">
                        alternative phone number
                    </label>
                    {/* <input type='text' id='alternativePhoneNumber' className='form-control mb-0' onChange={(e) => setAlternativePhoneNumber(e.target.value)} value={alternativePhoneNumber} /> */}
                    <ReactInputMask
                        className="form-control floatingIc"
                        onChange={e => {
                            setAlternativePhoneNumber(e.target.value)
                            // ...
                        }}
                        value={alternativePhoneNumber}
                        // mask="(999) 999-9999"
                        mask="9999999999"
                        maskChar=""
                        name="mobileNo"
                    // placeholder="9999999999"
                    />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="streetAddress" className="form-label">
                        street address
                    </label>
                    <input type='text' id='streetAddress' className='form-control mb-0' onChange={(e) => setStreetAddress(e.target.value)} value={streetAddress} />
                </div>
            </ModalBody>
            <ModalFooter className='justify-content-between'>
                <Button className='btn btn-confirm w-auto d-inline-block border-0' onClick={onClickUpdate}>
                    Update
                </Button>{' '}
                <Button className='btn signIn' onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </>
    )
}

export default UpdateProfileModal