import { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import authServices from '../../services/auth.services';

import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const AddGuestModal = ({
    title,
    toggle,
    primaryguestId,
    tripBookingId
}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [guestType, setGuestType] = useState(1);
    const [guestDetail, setGuestDetail] = useState('')
    const [guestList, setGuestLis] = useState([])

    const userProfileDetailById = async () => {
        const result = await authServices.profileDetailById({ id: primaryguestId })
        if (result.status === 200) {
            setGuestDetail(result.data.data)
        }
    }

    const guestListByTrip = async () => {
        const result = await authServices.guestDetailByTripId({ tripBookingId: tripBookingId })
        if (result.status === 200) {
            setGuestLis(result.data.data)
            console.log('guestList', result.data.data)
        }
    }

    const onClickUpdate = () => {
        const reqBody = {
            name: name,
            email: email,
            mobileNo: phone,
            guestType: guestType,   //1 - Adults, 2 - Children
            primaryguestId: primaryguestId,
            tripBookingId: tripBookingId
        }
        console.log('guest', reqBody)
        authServices.addGuestUser(reqBody).then((res) => {
            console.log('res', res)
            setName('')
            setEmail('')
            setPhone('')
            guestListByTrip()
            // toggle()
        }).catch((error) => {
            console.log('error', error)
        })
    }
    const deleteGuest = async(id)=>{
        const result = await authServices.deleteGuestByID({id:id})
        if(result.status === 200){
            guestListByTrip()
        }
    }
    useEffect(() => {
        userProfileDetailById()
        guestListByTrip()
        setName('')
            setEmail('')
            setPhone('')
    }, [])
    return (
        <>
            <ModalHeader className='pb-2' toggle={toggle}>Edit guest details 
            </ModalHeader>
            <ModalBody className='editModal'>
                {/* <div className="col-12 mb-3">
                    <label htmlFor="exampleSelect" className="form-label">Guest Type</label>
                    <select
                        className="form-select"
                        id="exampleSelect"
                        value={guestType} 
                        onChange={(event)=>setGuestType(event.target.value)} 
                    >
                        <option value={1}>Adult</option>
                        <option value={2}>Child</option>
                    </select>
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="date" className="form-label">
                        guest name
                    </label>
                    <input type='text' id='primaryguestname' className='form-control mb-0' onChange={(e) => setName(e.target.value)} value={name} />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="date" className="form-label">
                        guest email
                    </label>
                    <input type='email' id='primaryguestemail' className='form-control mb-0' onChange={(e) => setEmail(e.target.value)} value={email} />
                </div>
                <div className="col-12 mb-3">
                    <label htmlFor="date" className="form-label">
                        guest phone
                    </label>
                    <input type='text' id='primaryguestmobile' className='form-control mb-0' onChange={(e) => setPhone(e.target.value)} value={phone} />
                </div> */}
                <>
                    <p>Primary Guest</p>
                    <div className="">
                        <div className="justify-content-center">
                            <div className="card p-3">
                                <h6>
                                    {guestDetail?.name} <br />
                                    <small className="text-light">{guestDetail?.mobileNo}</small> |
                                    <small className="text-light">{guestDetail?.email}</small>
                                </h6>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <small className="text-danger">
                                        change primary guest only if you are booking for someone else
                                    </small>
                                    <button className="btn btn-primary btn-sm">change</button>
                                </div>
                            </div>
                            <hr />
                            {guestList.map((i, index) => {
                                console.log('innn', index)
                                return (
                                    <>

                                        <p>Guest { guestList.length > 9 ? index + 2 : `0${index + 2}`}</p>
                                        <div className="card d-flex p-3 flex-lg-row justify-content-between">
                                            <h6>
                                                {i?.name} <br />
                                                <small className="text-light">{i?.mobileNo}</small> |
                                                <small className="text-light">{i?.email}</small>
                                            </h6>
                                            <button className="btn btn-outline-danger btn-sm" onClick={()=>deleteGuest(i?._id)}>
                                                <CloseOutlinedIcon />
                                            </button>
                                        </div>
                                        <hr />
                                    </>
                                )
                            })}
                            {/* <hr /> */}
                            <p>Guest {guestList.length > 9 ? guestList.length + 2 : `0${guestList.length + 2}`}</p>
                            <div className="row gy-2">
                                <div className="col-lg-2">
                                    <div className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            id="adultorchild"
                                            checked={guestType}
                                            onChange={(event) => setGuestType(event.target.checked)}
                                        />

                                        <label className="form-check-label" htmlFor="adultorchild">
                                            Adult
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Full name"
                                        aria-label="First name"
                                        required=""
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}

                                    />
                                </div>
                                <div className="col-lg-3">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        placeholder="Phone number"
                                        aria-label="Last name"
                                        onChange={(e) => setPhone(e.target.value)}
                                        value={phone}
                                    />
                                </div>
                                <div className="col-lg-3">
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="email"
                                        aria-label="Last name"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </div>
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end mt-2">
                                <button className="btn btn-primary btn-sm addGuest" onClick={onClickUpdate}>Add Guest</button>
                            </div>
                        </div>
                    </div>
                </>

            </ModalBody>
            <ModalFooter>
            <Button className='btn btn-secondary' onClick={toggle}>
                    Close
                </Button>
                <Button className='btn btn-primary' onClick={onClickUpdate}>
                    Save Changes
                </Button>{' '}
            </ModalFooter>
        </>
    )
}

export default AddGuestModal