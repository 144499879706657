
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import ReportIcon from '@mui/icons-material/Report';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useEffect, useState } from 'react';
import authServices from '../../services/auth.services';
import { formattedDateDDMMMYYYY, getLoggedData } from '../../helpers/Helper';
import { useNavigate } from 'react-router-dom';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { Modal } from 'reactstrap';
import ShareModal from './ShareModal';

const HeroSec = () => {
    const [upComingTripBooking, setUpComingTripBooking] = useState([])
    const [randomItem, setRandomItem] = useState('');
    const [profileDetail, setProfileDetail] = useState('')
    const [modal, setModal] = useState(false);
    const [title, setTitle] = useState('title')
    const [shareUrl, setShareUrl] = useState('https://example.com')

    const toggle = () => setModal(!modal);
    let loggedUser = getLoggedData()
    const navigate = useNavigate()

    const onClickBooking = (id) => {
        navigate(`/booking-confirmation/${id}`)

    }

    const originalArray = [
        "नमस्ते<i>-Namaste</i>", "ਸਤਿ ਸ਼੍ਰੀ ਅਕਾਲ<i>-Sata Sri Akal</i>", "নমস্কার<i>-Nomoshkar</i>",
        "आदाब<i>-Aadab</i>", "प्रणाम<i>-Parnam</i>", "नमस्कार<i>-Namaskar</i>",
        "வணக்கம்<i>-Vanakkam</i>", "నమస్కరం<i>-Namaskaram</i>", "ನಮಸ್ತೆ<i>-Namaskar</i>",
        "नमस्कार<i>-Namaskar</i>", "राधे राधे<i>-Radhe Radhe</i>", "नमस्कार<i>-Namaskarah</i>",
        "ٱلسَّلَامُ عَلَيْكُمْ<i>-Assālam ‘alaykum</i>", "నమస్కారం<i>-Namaskaram</i>",
        "ନମସ୍କାର<i>-Namaskara</i>", "કેમ છો<i>-Kem Chho</i>",
    ];

    const getRandomElement = (array) => {
        const randomIndex = Math.floor(Math.random() * array.length);
        return array[randomIndex];
    };

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Check this out!',
                    text: 'Here is something interesting I found.',
                    url: 'https://example.com',  // Your link here
                });
                console.log('Link shared successfully!');
            } catch (error) {
                console.error('Error sharing the link:', error);
            }
        } else {
            alert('Sharing is not supported in your browser.');
        }
    };
    const userProfileDetailById = async () => {
        const result = await authServices.profileDetailById({ id: loggedUser.user._id })
        console.log('resultProfile', result)
        if (result.status === 200) {
            setProfileDetail(result.data.data)
            localStorage.setItem('profilePhotoUrl', result.data.data?.profilePhoto)
        }
    }
    useEffect(() => {
        const item = getRandomElement(originalArray);
        setRandomItem(item);
        userProfileDetailById()
        authServices.upComingBookingByUserID({ userId: loggedUser?.user?._id }).then((result) => {
            if (result.status === 200) {
                setUpComingTripBooking(result.data.data)
                const today = new Date();
                today.setUTCHours(0, 0, 0, 0);
                if (result.data.data.length > 0) {

                    const filteredData = result.data.data.filter(item => new Date(item.earliestDate) >= today);
                    // console.log('filteredData', filteredData)
                    setUpComingTripBooking(filteredData)
                }
                // console.log('upComingTripBooking', result.data.data)
            }
        })
    }, [])
    return (
        <>
            <section className="hero_section" >
                <div className="container py-5">

                    <div className="row">
                        <div className="col-12">
                            <h4 class="banner-title text-light d-flex text-white flex-wrap"><span className="d-block"><ins id="slt" dangerouslySetInnerHTML={{ __html: randomItem }}></ins></span><span>! {profileDetail?.name}, all set for your next trip?</span></h4>
                        </div>

                        <hr />
                    </div>

                    <Swiper
                        spaceBetween={10}
                        slidesPerView={'auto'}
                        className="heroBanner"
                        loop={'true'}
                    >
                        {upComingTripBooking.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>

                                    <div class="trip-card" onClick={() => onClickBooking(item?._id)}>
                                    {/* <div class="trip-card" > */}
                                        <small class="countown text-white"><HourglassTopIcon />{item?.remainingDays} days to go</small>
                                        <div class="trip-card-body card mt-2">
                                            <div class="trip-card-prop-img"><img src="https://picsum.photos/1024" /></div>
                                            <div class="trip-card-head d-flex justify-content-between">
                                                <small
                                                ><DateRangeIcon />{formattedDateDDMMMYYYY(item?.earliestDate)}</small>
                                                {/* <a class="link small text-white" onClick={toggle}><ShareOutlinedIcon /> Share</a> */}
                                            </div>
                                            <h5 class="trip-card-title my-lg-5 my-4 text-truncut">
                                                {/* 5N/6D at Great Himalayan Four Season Outdoor Camp */}
                                                {item?.propertyId === null ? item?.eventBooking?.length > 0 ? item?.eventBooking[0].experienceName : item?.experienceBooking[0].experienceName : item?.propertyName}
                                            </h5>
                                            <div class="trip-card-foot d-flex justify-content-between">
                                                {item.status === 0 &&
                                                    <small class="align-self-center text-warning"><ReportIcon />
                                                        Pending with host!
                                                    </small>}
                                                {item.status === 2 || item.status === 4 || item.status === 5 || item.status === 6 ? <small class="align-self-center text-success"><VerifiedIcon />
                                                    Confirmed
                                                </small> : ''
                                                }

                                                {item.status === 1 && <small class="align-self-center text-info"><VerifiedIcon />
                                                    Partially confirmed
                                                </small>
                                                }
                                                {item.status === 3 && <small class="align-self-center text-danger"><DoNotDisturbIcon />
                                                    Rejected
                                                </small>
                                                }

                                                <div class="btn-group btn-group-sm" role="group" aria-label="tripcardactions">
                                                    <button type="button" class="btn btn-danger">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        })}

                    </Swiper>
                </div>
                <Modal className='popupModal' isOpen={modal} toggle={toggle} size="lg" centered={true}>
                    <ShareModal  toggle={toggle} title={title} shareUrl={shareUrl}/>
                </Modal>
            </section>
        </>
    )
}

export default HeroSec;