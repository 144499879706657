import { useLocation, useNavigate } from 'react-router-dom'
import logo from '../../../src/assets/img/furutcamp-logo.svg'
import { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import Login from '../Authentication/Login';
import { getLoggedData } from '../../helpers/Helper';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../../firebase';
import { signOut } from 'firebase/auth';
import userImg from '../../assets/img/user-profile.png'
import aiIcon from '../../assets/images/App icon.png'

import journalIc from '../../assets/img/furut-journal.svg'

import { HashLink as Link } from 'react-router-hash-link';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { BASE_URL } from '../../services/config';
import CartOffcanvas from '../Comman/CartOffcanvas';


const Header = () => {
    const [modal, setModal] = useState(false);
    const [isActive, setIsActive] = useState("");
    const { pathname } = useLocation();
    const toggle = () => setModal(!modal);
    const loggedUser = getLoggedData()
    const [loggUser] = useAuthState(auth);
    const [toggleBtn, setToggle] = useState(false);
    const [profilePhotUrl, setProfilePhotoUrl] = useState(localStorage.getItem('profilePhotoUrl'))
    const [isOpenCanvas, setIsOpenCanvas] = useState(false);
    const onClickToggleBtn = () => setToggle(!toggleBtn);
    const toggleCanvas = () => setIsOpenCanvas(!isOpenCanvas);
    const navigate = useNavigate();
    const logOut = () => {
        localStorage.removeItem('data')
        localStorage.removeItem('mobileNo')
        localStorage.removeItem('stayBooking')
        localStorage.removeItem('propertyDetail')
        localStorage.removeItem('experienceDetail')
        localStorage.removeItem('conveyenceDetail')
        localStorage.removeItem('stayBooking')
        localStorage.removeItem('stayDetail')

        handleSignOut()
        navigate('/')
    }
    const handleSignOut = async () => {
        try {
            await signOut(auth);
            console.log('User signed out');
            //   window.location.reload()
        } catch (error) {
            console.error('Error during sign-out:', error);
        }
    };
    const onClick = (value) => {
        setIsActive(value);
    };
    useEffect(() => {
        setIsActive(window.location.pathname);

    }, [isActive]);

    useEffect(() => {
        window.scrollTo(0, 0);

    }, [pathname]);
    return (
        <>

            <header className='header'>
                <div className="container-fluid bg-dark">
                    <small className="text-center p-2" style={{ 'display': 'block' }}>announcement banner</small>
                </div>
                <nav className="navbar navbar-expand-lg ">
                    <div className="container">
                        <Link to='/' className="navbar-brand mb-2"><img src={logo} /></Link>
                        <button className="navbar-toggler" onClick={onClickToggleBtn} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>


                        <div className={`collapse d-lg-block d-none navbar-collapse ${toggleBtn === true && 'show'}`} id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto my-4 my-lg-0 ms-lg-3">
                                <li className="nav-item">
                                    <Link to='/' className={`mx-2 ${isActive === "/" ? "active" : ""}`} onClick={onClick}>Explore</Link>
                                </li>
                                <li className="nav-item">
                                
                                    <Link to='/experiences' className={`mx-2 ${isActive === "/experiences" ? "active" : ""}`} onClick={onClick}>Experiences</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/events' className={`mx-2 ${isActive === "/events" ? "active" : ""}`} onClick={onClick}>Events</Link>
                                </li>
                            </ul>

                            <div className='d-lg-flex align-items-center gap-2'>
                                <button className="btn newTrip mx-1"><img src={aiIcon} className="me-2" /> New Trip</button>
                              
                                {loggedUser?.isLoggedIn === true ? '' : <button className="btn signIn mx-1" onClick={toggle} id="signInBtn">Sign In</button>}
                              
                                {loggedUser.isLoggedIn === true ? <button className="btn userProfile mb-2 p-2" onClick={() => navigate('/my-account')}><img src={profilePhotUrl != 'null' ? `${BASE_URL}/${profilePhotUrl}` : userImg} className='img-fluid' /> </button> : ''}
                            </div>
                            <button id='profileBtnf' style={{ display: 'none' }} onClick={() => setProfilePhotoUrl(localStorage.getItem('profilePhotoUrl'))}></button>

                            <button className="btn mx-1 px-1 journal" onClick={toggleCanvas}> <img src={journalIc} /> </button>
                        </div>

                        <div className={`mobile-menu ${toggleBtn===true && 'show'}`}>
                            <div className='offcanvas-header d-flex justify-content-between align-items-center p-3'>
                                <h5 className='offcanvas-title'>
                                    <div className='furutcamps-logo'>
                                        <img src={logo} />
                                    </div></h5>

                                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={onClickToggleBtn}></button>
                            </div>

                            <div className='offcanvas-body p-3 w-100'>
                                <ul className="navbar-nav me-auto ms-lg-3">
                                    <li className="nav-item">
                                        <Link to='/' className={`mx-2 ${isActive === "/" ? "active" : ""}`} onClick={onClick}>Explore</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/experiences' className={`mx-2 ${isActive === "/experiences" ? "active" : ""}`} onClick={onClick}>Experiences</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to='/events' className={`mx-2 ${isActive === "/events" ? "active" : ""}`} onClick={onClick}>Events</Link>
                                    </li>
                                </ul>

                                <div className='d-lg-flex align-items-center gap-2'>
                                    <button className="btn newTrip mx-1 d-block"><img src={aiIcon} className="me-2" /> New Trip</button>
                                    {loggedUser?.isLoggedIn === true ? '' : <button className="btn signIn mx-1" onClick={toggle} id="signInBtn">Sign In</button>}
                                 
                                    {loggedUser.isLoggedIn === true ? <button className="btn userProfile mb-2 p-2" onClick={() => navigate('/my-account')}><img src={profilePhotUrl != 'null' ? `${BASE_URL}/${profilePhotUrl}` : userImg} className='img-fluid' /> </button> : ''}
                                </div>
                                <button id='profileBtnf' style={{ display: 'none' }} onClick={() => setProfilePhotoUrl(localStorage.getItem('profilePhotoUrl'))}></button>

                                <button className="btn mx-1 px-1 journal" onClick={toggleCanvas}> <img src={journalIc} /> </button>
                            </div>

                        </div>

                    </div>
                </nav>
            </header>

         
            <Modal className='popupModal' isOpen={modal} toggle={toggle} centered={true}>
                <Login toggle={toggle} />
            </Modal>
            <CartOffcanvas toggle={toggleCanvas} isOpen={isOpenCanvas} />
        </>
    )
}

export default Header