import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import WebRoutes from './WebRoute';
import Home from '../pages/Home';
import Experiences from '../pages/Experiences';
import Login from '../pages/Authentication/Login';
import BookingConfirmation from '../components/Booking/BookingConfirmation';
import SearchResult from '../pages/Booking/SearchResult';
import StayDetail from '../pages/Booking/StayDetail';
import PropertyDetail from '../pages/Booking/PropertyDetail';
import Booking from '../pages/Booking/Booking';
import ExperienceDetail from '../pages/Booking/ExperienceDetail';
import ConveyenceDetail from '../pages/Booking/ConveyenceDetail';
import ExperienceBooking from '../pages/ExperienceBooking/ExperienceBooking';
import ExperienceBookingConfirmation from '../pages/ExperienceBooking/ExperienceBookingConfirmation';
import Event from '../pages/Event';
import EventsBooking from '../pages/EventsBooking/EventsBooking';
import EventBookingConfirmation from '../pages/EventsBooking/EventsBookingConfirmation';
import EventDetail from '../pages/Booking/EventDetail';
import MyAccount from '../pages/MyAccount';
import Messages from '../pages/Messages';
import PolicyPage from '../pages/PolicyPage';
import AboutUs from '../pages/AboutUs';
import SingUp from '../pages/SignUp';
import ScrollToTop from '../components/Comman/ScrollToTop';
import BookingPdf from '../components/Booking/BookingPdf';

const AppRoute = () => {

    return (
        <Router>
          <ScrollToTop />
            <Routes>
              <Route element={<WebRoutes />}>
                  <Route path='/' element={<Home />} />
                  <Route path='experiences' element={<Experiences />} />
                  <Route path='experiences/booking/:id' element={<ExperienceBooking />} />
                  <Route path='experiences/booking/confirmation/:id' element={<ExperienceBookingConfirmation />} />
                  <Route path='property-search' element={<SearchResult />} />
                  <Route path='property-detail/:id' element={<PropertyDetail />} />
                  <Route path='stay-detail/:id' element={<StayDetail />} />
                  <Route path='experience-detail/:id' element={<ExperienceDetail />} />
                  <Route path='event-detail/:id' element={<EventDetail />} />
                  <Route path='events' element={<Event />} />
                  <Route path='events/:id' element={<EventsBooking />} />
                  <Route path='events/booking/confirmation/:id' element={<EventBookingConfirmation />} />
                  
                  {/* <Route path='conveyence-detail' element={<ConveyenceDetail />} /> */}
                  <Route path='booking/:id' element={<Booking />} />
                  <Route path='booking-confirmation/:id' element={<BookingConfirmation />} />
                  <Route path='my-account' element={<MyAccount />} />

                  <Route path='messages/:id' element={<Messages />} />
                  <Route path='policy' element={<PolicyPage />} />
                  <Route path='about' element={<AboutUs />} />

                  <Route path='test/:id' element={<BookingPdf />} />
         
              </Route>
              <Route path='/signup' element={<SingUp />}/>
            </Routes>
        </Router>
      );
    }
  export default AppRoute;
