import { Policy } from "@mui/icons-material"

const PolicyPage = () => {
    return (
        <>
            <section className="privacy-page">
                <div className="container">
                    <div className="row mt-5">
                        <div className="col-auto col-lg-8 col-md-8">
                            <h1 className="">policy name</h1>
                            <h5 className="">
                                policy details text most probably a brief description on how it
                                helps the user
                            </h5>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <p className="text-lg-end">Last updated on 4th April, 2023</p>
                        </div>
                    </div>
                    <div className="row">
                        <div
                            className="col-auto col-lg-8 col-md-8 order-lg-1 order-2">
                            <div className="card mt-5 p-4 br16" id="toc_head1">
                                <h4>section heading</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
                                    placerat, tortor finibus sodales rutrum, sem magna sagittis lorem,
                                    at semper orci lectus non ligula. Fusce ligula est, tristique nec
                                    dui vitae, luctus malesuada diam. Maecenas non placerat est. Morbi
                                    eget elementum libero, vel interdum justo. Nam a tempus nulla, ut
                                    feugiat elit. Ut et sem at est aliquet porttitor. Mauris blandit
                                    ornare ante, vel blandit urna sodales ac. Aliquam a ex non diam
                                    tempor imperdiet.
                                </p>
                                <p>
                                    Donec ullamcorper purus vulputate leo tempus suscipit. Duis tellus
                                    neque, pellentesque at ullamcorper non, commodo eu mauris.
                                    Phasellus et egestas enim. Phasellus magna ante, varius nec
                                    molestie ut, iaculis sit amet quam. Curabitur eget est dolor.
                                    Aliquam convallis lacus vel enim cursus malesuada. Mauris
                                    ultricies sit amet odio ac dapibus. Praesent lacinia leo ante,
                                    quis pretium justo auctor a. Praesent scelerisque massa nibh.
                                    Etiam tempus lectus in vulputate semper. Proin eros felis, luctus
                                    nec tristique ac, varius ut tellus. Quisque nibh libero, mollis
                                    dictum dolor non, congue malesuada tortor.
                                </p>
                                <p>
                                    Nulla metus felis, iaculis et pharetra fermentum, pulvinar
                                    fermentum elit. Nam ut tincidunt elit. Etiam elementum tempor
                                    metus et tempor. Donec congue dui dui, et malesuada massa
                                    malesuada at. Donec urna mi, ornare in iaculis at, accumsan sed
                                    erat. Pellentesque vitae ultricies orci. Suspendisse interdum
                                    pretium mauris vel pharetra. Quisque id metus neque. Mauris
                                    ultrices maximus velit vel viverra. Integer molestie sed risus id
                                    aliquam.
                                </p>
                            </div>
                            <div className="card mt-5 p-4 br16" id="toc_head2">
                                <h4>section heading</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
                                    placerat, tortor finibus sodales rutrum, sem magna sagittis lorem,
                                    at semper orci lectus non ligula. Fusce ligula est, tristique nec
                                    dui vitae, luctus malesuada diam. Maecenas non placerat est. Morbi
                                    eget elementum libero, vel interdum justo. Nam a tempus nulla, ut
                                    feugiat elit. Ut et sem at est aliquet porttitor. Mauris blandit
                                    ornare ante, vel blandit urna sodales ac. Aliquam a ex non diam
                                    tempor imperdiet.
                                </p>
                                <p>
                                    Donec ullamcorper purus vulputate leo tempus suscipit. Duis tellus
                                    neque, pellentesque at ullamcorper non, commodo eu mauris.
                                    Phasellus et egestas enim. Phasellus magna ante, varius nec
                                    molestie ut, iaculis sit amet quam. Curabitur eget est dolor.
                                    Aliquam convallis lacus vel enim cursus malesuada. Mauris
                                    ultricies sit amet odio ac dapibus. Praesent lacinia leo ante,
                                    quis pretium justo auctor a. Praesent scelerisque massa nibh.
                                    Etiam tempus lectus in vulputate semper. Proin eros felis, luctus
                                    nec tristique ac, varius ut tellus. Quisque nibh libero, mollis
                                    dictum dolor non, congue malesuada tortor.
                                </p>
                                <p>
                                    Nulla metus felis, iaculis et pharetra fermentum, pulvinar
                                    fermentum elit. Nam ut tincidunt elit. Etiam elementum tempor
                                    metus et tempor. Donec congue dui dui, et malesuada massa
                                    malesuada at. Donec urna mi, ornare in iaculis at, accumsan sed
                                    erat. Pellentesque vitae ultricies orci. Suspendisse interdum
                                    pretium mauris vel pharetra. Quisque id metus neque. Mauris
                                    ultrices maximus velit vel viverra. Integer molestie sed risus id
                                    aliquam.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 order-lg-2 order-1 sticky-top">
                            <div className="sticky-top d-none d-lg-block d-md-block">
                                <h6 className="mt-4">Table of contents</h6>
                                <div className="card mt-3 p-4" id="toc">
                                    <div className="list-group list-group-flush">
                                        <a
                                            href="#toc_head1"
                                            className="list-group-item list-group-item-action active"
                                            aria-current="true"
                                        >
                                            The current link item
                                        </a>
                                        <a
                                            href="#toc_head2"
                                            className="list-group-item list-group-item-action"
                                        >A second link item</a
                                        >
                                        <a href="#" className="list-group-item list-group-item-action"
                                        >A third link item</a
                                        >
                                        <a href="#" className="list-group-item list-group-item-action"
                                        >A fourth link item</a
                                        >
                                        <a className="list-group-item list-group-item-action disabled"
                                        >A disabled link item</a
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PolicyPage;