import { useState } from "react";
import PropertyDetailPage from "./PropertyDetailPage";
import clockImg from '../../assets/img/clock.png'
import datePng from '../../assets/img/date.png'
import starRatings from '../../assets/img/star-ratings.png'
import cardImg from "../../assets/img/card-image.jpg";
// import filterIc from '../../assets/img/filter-ic.png'
// import searchIc from '../../assets/img/search-ic.png'
import filterIc from '../../assets/img/filter-ic.png'
import searchIc from '../../assets/img/search-ic.png'

const SearchResult = ({ searchResult, toggle }) => {
  const [showPropertyDetail, setShowPropertyDetail] = useState(false);
  const [selectedPropertyDetail, setSelectedPropertyDetail] = useState("");

  console.log("searchRessss", searchResult);

  const onClickProperty = (data) => {
    setSelectedPropertyDetail(data);
    setShowPropertyDetail(true);
    console.log("data", data);
  };

  return (
    <>
      {showPropertyDetail === false ? (
        <>
          {/* <div className="search-bar d-flex justify-content-center p-4">
            <button className="form-control w-50" onClick={toggle}>
              Let's explore destinations all over India
            </button>
          </div> */}
           <div className="search-bar mt-5">
          {/* <input type="text" className="form-control w-50" placeholder="Let's explore destinations all over India" /> */}
          <div className="row justify-content-center">
            <div className="col-md-8 col-12">
            <button className="filter-btn w-100" onClick={toggle}>
            <div className="d-flex justify-content-between align-items-center gap-2">
              <div className="search_sec d-flex align-items-center gap-2">
                <img src={searchIc} alt="" />
                <div className="text-start">
                  <h5>Let’s explore</h5>
                  <p>destinations all over INDIA</p>
                </div>
              </div>
              <div className="filter_box">
                <img src={filterIc} alt="" />
              </div>
            </div>
          </button>
            </div>
          </div>
        </div>

          {/* <div className="experiences p-4">
            <h3 className="mb-4">Happening Experiences</h3>
            <div className="experience-cards d-flex">
              {searchResult.map((item, index) => {
                return (
                  <>
                    <div
                      className="experience-card card shadow-sm"
                      key={index}
                      onClick={() => onClickProperty(item)}
                    >
                      <img
                        src={cardImg}
                        className="card-img-top"
                        alt="Experience"
                      />
                      <div className="card-body">
                        <h4 className="card-title">{item.propertyName}</h4>
                        <p className="card-text">₹ 2000 - 4000</p>
                        <p className="card-text">3 Nights 4 Days</p>
                        <p className="card-text">4.8 ★</p>
                        <button className="btn btn-warning">
                          Limited Seats
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div> */}
           <section className="pd02">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="title">happening experinces</div>
                  <div className="subtitle">
                    book upcoming events in Great Himalayan Outdoors - four season camps
                  </div>
                </div>
                <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1">
           
                 

                  {searchResult.map((item, index) => {
                return (
                  <>
                    <div className="col">
                    <div className="experience_card card-shadow">
                      <div className="experience_card_image position-relative ">
                        <img
                          src={cardImg}
                          className="img-fluid experienceImg01"
                          alt=""
                          key={index}
                          onClick={() => onClickProperty(item)}
                        />
                        <div className="experience_card_image_overlay">
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="price_tag">₹ 2000 - 4000</div>
                            <div className="stay_duration">
                              <img src={clockImg} /> 3 Nights 4 Days
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="experience_card_content">
                        <div className="d-flex justify-content-between flex-1">
                          <h4>{item.propertyName}</h4>
                          <h5>
                            <img src={starRatings} /> 4.8
                          </h5>
                        </div>
                        <div className="dashedBorder" />
                        <div className="d-flex justify-content-between flex-1">
                          <div className="list-date">
                            <h6>
                              <img src={datePng} />
                              <span>Fri 8th Apr to Sun 10th Apr</span>
                            </h6>
                          </div>
                          <button className="btn btn-transperent">limited seats</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  </>
                );
              })}
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <PropertyDetailPage selectedPropertyDetail={selectedPropertyDetail} setShowPropertyDetail={setShowPropertyDetail}/>
      )}
    </>
  );
};

export default SearchResult;
