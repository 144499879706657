import { BASE_URL } from "../../services/config"
import hostPoster from "../../assets/img/hostPoster.jpg"
const HostedBy =({selectedPropertyDetail})=>{
    return (
        <>
         <div className="row mt-4 align-items-start">
                      <div className="col-lg-6">
                        <div className="message_box_card card p-5">
                          <div className="message_box_card_header d-flex justify-content-between align-items-start">
                            <div className="message_box_owner d-flex justify-content-between align-items-center">
                              <div className="message_box_owner_img">
                                <img src={selectedPropertyDetail?.hostDetail?.profilePhoto ? `${BASE_URL}/${selectedPropertyDetail?.hostDetail?.profilePhoto}` : hostPoster} className="img-fluid" />
                                {/* <h6>{selectedPropertyDetail?.hostDetail?.replyRate}, can speak {selectedPropertyDetail?.hostDetail?.language ? selectedPropertyDetail?.hostDetail?.language.join(", ") : 'Hindi, English, Bengali'}</h6> */}
                              </div>
                              <div className="message_box_owner_content d-flex flex-column">
                                <h5>{selectedPropertyDetail?.hostDetail?.name}</h5>
                                <p>{selectedPropertyDetail?.hostDetail?.memberSince}</p>
                              </div>
                            </div>

                            {/* <button className="btn btn-success">Message</button> */}
                          </div>

                          <div className="message_box_card_footer d-flex flex-column">
                            <h6>{selectedPropertyDetail?.hostDetail?.replyRate ? selectedPropertyDetail?.hostDetail?.replyRate : 'Moderate reply rate' }, can speak {selectedPropertyDetail?.hostDetail?.language > 0 ? selectedPropertyDetail?.hostDetail?.language.join(", ") : 'Hindi, English, Bengali'}</h6>
                            <p>{selectedPropertyDetail?.hostDetail?.userDesc ? selectedPropertyDetail?.hostDetail?.userDesc : 'lorem ipsum dollar smit, lorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smit lorem ipsum dollar smit '}</p>
                          </div>

                        </div>
                      </div>


                      {/* <div className="col-lg-6 gx-lg-5 col-md-6 d-flex flex-column justify-content-end">
                        <div className="title mb-3">co host</div>

                        <div className="cohost_card d-flex align-items-center mb-2">
                          <div className="cohost_card_img">
                            <img src={hostPoster} className="img-fluid" />
                          </div>
                          <h5>Surajit Das</h5>
                        </div>

                        <div className="cohost_card d-flex align-items-center mb-2">
                          <div className="cohost_card_img">
                            <img src={hostPoster} className="img-fluid" />
                          </div>
                          <h5>Surajit Das</h5>
                        </div>
                      </div> */}
                    </div>
        </>
    )
}

export default HostedBy