import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, Navigate } from 'react-router-dom';

import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import { getLoggedData } from '../helpers/Helper';
import Layout from '../components/Layout/Layout';


const WebRoutes = () => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const getUserData = getLoggedData();
  const navigate = useNavigate();
  console.log('getUserData', getUserData)
  // useEffect(() => {
  //   if (window.location.pathname === '/testigo') {
  //     navigate("home");
  //   }
  // }, []);
  return (
    getUserData?.isLoggedIn === true ? <><Layout /></> : <Navigate to='/signup' />
   
  )
}
export default WebRoutes;