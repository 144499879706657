
import React, { useEffect, useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Thumbnails from "../../assets/img/Thumbnails.png";
import { Navigation, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Thumbnails01 from "../../assets/img/Thumbnails01.png";
import Thumbnails02 from "../../assets/img/Thumbnails02.png";
import Thumbnails03 from "../../assets/img/Thumbnails03.png";
import starRatings from "../../assets/img/star-ratings.png";
import shareIc from "../../assets/img/share-ic.png";
import likeIc from "../../assets/img/like-ic.png";
import highlights01 from "../../assets/img/highlights01.png"
import highlights02 from "../../assets/img/highlights02.png"
import highlights03 from "../../assets/img/highlights03.png"
import highlights04 from "../../assets/img/highlights04.png"
import highlights05 from "../../assets/img/highlights05.png"
import pointsIc from "../../assets/img/iCONS.png"


import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Modal } from "reactstrap";
import BookingModal from "./BookingModal";
import StayBooking from '../Stay/StayBooking';

const ConveyenceDetails = ({ selectedPropertyDetail, setShowProperty, conveyenceData, direction, ...args }) => {
    console.log('cccccc', conveyenceData)
    const [noOfGuest, setNoOfGuest] = useState(1)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showStayBooking, setShowStayBooking] = useState(false)
    const [endDate, setEndDate] = useState('');
    const [stayBookingData, setStayBookingData] = useState()
    const [startDate, setStartDate] = useState('');
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    const callBack = () => {
        setShowStayBooking(false)
        setShowProperty(true)
        // callBackProperty(false)
    }

    const addConveyence = (conveyance) => {
        let existBooking = localStorage.getItem('stayBooking');
        let booking = JSON.parse(existBooking);
        console.log('fffdasfas', booking.conveyenceBooking)
        if (existBooking) {
            if (booking?.conveyenceBooking) {
                const findAddonBookingById = (array, id) => {
                    return array.filter(booking => booking._id === id);
                };
                const expBookingIdToFind = conveyance?._id;
                const foundExpBooking = findAddonBookingById(booking?.conveyenceBooking, expBookingIdToFind);
                if (foundExpBooking.length > 0) {
                    console.log('found expericence booking', booking)
                    for (let i of booking.conveyenceBooking) {
                        if (i._id === expBookingIdToFind) {
                            console.log('iiiiidddddss', i)
                            i.conveyenceCount = foundExpBooking.length + i.conveyenceCount
                        }
                    }
                } else {
                    console.log('no found expericence booking', foundExpBooking)
                    let bookingData = {
                        _id: conveyance._id,
                        conveyanceId: conveyenceData._id,
                        conveyenceCount: 1,
                        conveyanceTypeName: conveyance.conveyanceTypeName,
                        maxPerson: conveyance.maxPerson,
                        vehicleNumber: conveyenceData.vehicleNumber,
                        price: conveyance.priceRegular,
                        dropLoc: conveyance.dropLoc,
                        pickupLoc: conveyance.pickupLoc,
                        pickupDropDate: startDate
                    }
                    booking.conveyenceBooking = [bookingData]
                }
                localStorage.setItem('stayBooking', JSON.stringify(booking));
            } else {
                console.log('not exist booking', booking);
                let bookingData = {
                    _id: conveyance._id,
                    conveyanceId: conveyenceData._id,
                    conveyenceCount: 1,
                    conveyanceTypeName: conveyance.conveyanceTypeName,
                    maxPerson: conveyance.maxPerson,
                    vehicleNumber: conveyenceData.vehicleNumber,
                    price: conveyance.priceRegular,
                    dropLoc: conveyance.dropLoc,
                    pickupLoc: conveyance.pickupLoc,
                    pickupDropDate: startDate

                }
                let obj = {
                    conveyenceBooking: [bookingData],
                    // stayCount: 1
                };
                booking.conveyenceBooking = [bookingData]
                localStorage.setItem('stayBooking', JSON.stringify(booking));
            }
        }
        setShowStayBooking(true)
    }

    const handleDateChange = (event) => {
        console.log('dateee', event.target.value)
        setStartDate(event.target.value); // Update start date state
      };
      const today = new Date().toISOString().split('T')[0];
    useEffect(() => {

    }, [conveyenceData])
    return (

        <>  {showStayBooking === false ? <>
            <main className="experience-detaills-page">
                <section className="pd01">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="pd01_top_wrapper d-flex justify-content-start align-items-center gap-1">
                                    <Swiper
                                        className="mySwiper"
                                        slidesPerView={3}
                                        spaceBetween={10}
                                    >
                                        <SwiperSlide className="pd01_top_thumbnail">
                                            <img
                                                src={Thumbnails}
                                                alt=""
                                                className="img-fluid w-100"
                                            />
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="pd01_top_thumbnail">
                                                <img
                                                    src={Thumbnails}
                                                    alt=""
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="pd01_top_thumbnail">
                                                <img
                                                    src={Thumbnails}
                                                    alt=""
                                                    className="img-fluid w-100"
                                                />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>

                                <div className="pd01_bottom_wrapper d-flex justify-content-start align-items-end gap-md-4 gap-2">
                                    <div className="pd01_bottom_thumbnails01">
                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails01}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>

                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails02}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>

                                        <div className="pd01_bottom_thumbnail01_inner">
                                            <img
                                                src={Thumbnails03}
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>

                                    <div className="pd-bottom_rtl flex-fill">
                                        <div className="pd01_bottom_content">
                                            <div className="breadcrumb_wrapper">
                                                <nav
                                                    style={{ "--bs-breadcrumb-divider": "'>'" }}
                                                    ariaLabel="breadcrumb"
                                                >
                                                    <ol className="breadcrumb">
                                                        <li className="breadcrumb-item">
                                                            <a href="#">Camps in</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">State</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">District</a>
                                                        </li>
                                                        <li className="breadcrumb-item">
                                                            <a href="#">Locality</a>
                                                        </li>
                                                    </ol>
                                                </nav>
                                            </div>

                                            <div className="pd01_content">
                                                <h4>{conveyenceData?.conveyanceTypeName}  </h4>

                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="pd01_content_ltr d-flex justify-content-between align-items-center">
                                                        <div className="rating_txt d-flex justify-content-center align-items-center">
                                                            4.8ss
                                                        </div>
                                                        <div className="ratings01">
                                                            <img
                                                                src={starRatings}
                                                                className="img-fluid"
                                                                alt=""
                                                            />
                                                            <br />
                                                            <span>36 reviews</span>
                                                        </div>
                                                        <img
                                                            src="img/Badges.png"
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="pd01_content_rtl">
                                                        <button className="btn_cutom_atn border-0 bg-transparent">
                                                            <img src={shareIc} alt="" />
                                                            Share
                                                        </button>
                                                        <button className="btn_cutom_atn border-0 bg-transparent">
                                                            <img src={likeIc} alt="" />
                                                            Save
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="edp01">
                    <div className="container-fluid">
                        <div className="row">
                            {conveyenceData?.conveyancePackage.length>0 &&
                            conveyenceData?.conveyancePackage.map((item, index) => {
                                return(
                                    <>
                             
                            <div className='col-lg-2' key={index}>
                                <div className="card bfi_card_content p-3">
                                <h6>Pick Up Location : <span id='value'>{item?.packageName}</span> </h6>
                                    <h6>Pick Up Location : <span id='value'>{item?.pickupLoc}</span> </h6>
                                    <h6>Drop Location : <span id='value'>{item?.dropLoc}</span> </h6>
                                    <div className="guest_input my-2">
                                    <input
                                            type="date"
                                            className="form-control"
                                            value={startDate}
                                            onChange={handleDateChange}
                                            min={today}
                                            />
                                    </div>
                                    <button className='btn btn-done' onClick={()=>addConveyence(item)}>Book Now</button>
                                </div>
                            </div>
                            </>
                                )
                            })
                            }

                            

                        </div>
                    </div>
                </section>

            </main>
        </> : <>
            <StayBooking callBack={callBack} />
        </>}
        </>

    )

}


ConveyenceDetails.propTypes = {
    direction: PropTypes.string,
};

export default ConveyenceDetails;